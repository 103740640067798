/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useComponent, useInput } from 'hooks';
import NeoButtonMain from './neo/button/NeoButtonMain.base';
import NeoInputPassword from './neo/form/NeoInputPassword.base';


const FormularioRestablecerContrasena = (props) => {
  const componente = useComponent();
  const [contrasena, setContrasena] = useInput("");
  const [errores, setErrores] = componente.useCustomState({})
  const [validar, setValidar] = componente.useCustomState(false);

  useEffect(() => {
    return () => { componente.desmontar(); };
  }, []);

  useEffect(() => {
    if (validar)
      validaFormulario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrasena]);


  const validaContrasena = () => {
    let esValido = true
    let mensaje = null

    if (contrasena.length < 6) {
      mensaje = "La contraseña debe ser mayor a 6 caracteres";
      esValido = false;
    }

    setErrores(prevState => ({ ...prevState, contrasena: mensaje }));
    return esValido;
  };

  const validaFormulario = () => {
    let esValido = true;
    setValidar(true);

    if (validaContrasena() === false)
      esValido = false;

    return esValido;
  }

  const manejaEnvio = () => {
    if (!validaFormulario()) {
      return false;
    }

    props && props.onSubmit && props.onSubmit({ "contrasena": contrasena });
  };


  return (<>
    <NeoInputPassword id="contrasena" value={contrasena}
      onChange={setContrasena} label="Nueva contraseña"
      error={errores.contrasena} />
    <NeoButtonMain label="Cambiar contraseña" onClick={manejaEnvio} />
  </>);
}

export default FormularioRestablecerContrasena;