/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useComponent, useInput } from 'hooks';
import NeoInputText from "./neo/form/NeoInputText.base";
import NeoInputPassword from "./neo/form/NeoInputPassword.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoButtonSection from "./neo/layout/NeoButtonSection.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import { validaCorreo } from "consts/validacionDatosUsuario";


const FormularioInicioSesion = (props) => {
    const componente = useComponent();
    const [correo, setCorreo] = useInput("");
    const [contrasena, setContrasena] = useInput("");
    const [errores, setErrores] = componente.useCustomState({})
    const [validar, setValidar] = componente.useCustomState(false);
    const [cargando, setCargando] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (validar)
            validaFormulario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correo, contrasena]);

    const validaContrasena = () => {
        let esValido = true;
        let mensaje = null;

        if (contrasena.length === 0) {
            mensaje = "Debes introducir una contraseña";
            esValido = false
        }

        setErrores(prevState => ({ ...prevState, contrasena: mensaje }));
        return esValido;
    };

    const validaFormulario = () => {
        let esValido = true;
        let resultadoCorreo = {}
        setValidar(true);

        resultadoCorreo = validaCorreo(correo);
        setErrores(prev => ({ ...prev, correo: resultadoCorreo.mensaje }));
        if (resultadoCorreo.esValido === false) {
            esValido = false
        }

        if (validaContrasena() === false)
            esValido = false;

        return esValido;
    }

    const manejaEnvio = async () => {
        if (!validaFormulario()) {
            return false;
        }

        if(typeof props.onSubmit === 'function') {
            setCargando(true);

            const resultado = await props.onSubmit({
                "correo": correo,
                "contrasena": contrasena
            });

            if(!resultado) {
                setCargando(false);
            }
        }
    };

    const inputOnKeyDown = (event) => {
        if (event.key === "Enter")
            manejaEnvio()
    }

    return (<><NeoColumn extra="px-0">
        <NeoInnerTitle extra="primero">Inicio de sesión</NeoInnerTitle>

        <NeoInputText id="correo" value={correo} onChange={setCorreo}
            label="Correo electrónico" error={errores.correo}
            onKeyDown={inputOnKeyDown} />

        <NeoInputPassword id="contrasena"
            value={contrasena} onChange={setContrasena}
            label="Contraseña" error={errores.contrasena} noFeedback
            onKeyDown={inputOnKeyDown} />
        <NeoButtonSection>
            <NeoButtonMain label="Iniciar sesión" onClick={manejaEnvio} loading={cargando}/>
        </NeoButtonSection>
    </NeoColumn></>);
};

export default FormularioInicioSesion;