import React, { useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import 'firebase/auth';
import FormularioRegistro from "components/common/FormularioRegistro";
import NeoColumn from '../common/neo/layout/NeoColumn.base';
import NeoSingleContainer from "../common/neo/layout/NeoSingleContainer.base";
import NeoGridContainer from "../common/neo/layout/NeoGridContainer.base";
import NeoCard from "../common/neo/panel/NeoCard.base";
import LogoWhite from 'design/assets/img/mnv/logo-bw.svg';
import NeoToast from "../common/neo/messages/NeoToast.base";
import { useComponent } from 'hooks';
import useUsuario from 'hooks/useUsuario';

const Registro = () => {
    const usuario = useUsuario();
    const historial = useHistory();
    const componente = useComponent();
    const [registrando, setRegistrando] = componente.useCustomState(false);
    const mensaje = useRef(null);
    const { 
        REACT_APP_CAPTERRA_VKEY,
        REACT_APP_CAPTERRA_VID
    } = process.env;

    //Registro deshabilitado temporalmente
    // return (<>
    //     <Redirect to={"/"}/>
    // </>);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const registraUsuario = async (datosUsuario) => {
        if (!datosUsuario?.correo || !datosUsuario?.contrasena
            || !datosUsuario?.telefono || !datosUsuario?.nombre
            || !datosUsuario?.empresa || registrando) {
            return;
        };

        try {
            setRegistrando(true);

            await usuario.registrar(datosUsuario);
        } catch (error) {
            console.log(error);
            mensaje.current.show({
                severity: "error", summary: "",
                detail: `Error al iniciar sesión, verifica tu usuario y contraseña`, life: 4000
            });
        } finally {
            setRegistrando(false);
        }
    }

    return (<>
        <NeoSingleContainer >
            {(REACT_APP_CAPTERRA_VKEY && REACT_APP_CAPTERRA_VID) &&
                <img 
                    src={`https://ct.capterra.com/capterra_tracker.gif?vid=${REACT_APP_CAPTERRA_VID}&vkey=${REACT_APP_CAPTERRA_VKEY}`}
                    alt="capterra" />
            }

            <NeoGridContainer col="12" >
                <NeoColumn col="12" extra="px-0">
                    <a href="https://neovalidador.com">
                        <img className="logo-intro" src={LogoWhite} alt="NeoValidador" height="60" />
                    </a>
                </NeoColumn>

                <NeoColumn col="12" md="6" extra="p-md-offset-3">
                    <NeoCard extra="text-align-center">
                        <FormularioRegistro
                            titulo="Crea una cuenta"
                            registrando={registrando}
                            onSubmit={registraUsuario}
                        />
                    </NeoCard>
                </NeoColumn>

            </NeoGridContainer>

            <NeoToast ref={mensaje} />
        </NeoSingleContainer>
    </>);
};


export default Registro;