import NeoDialog from "components/common/neo/overlay/NeoDialog.base";
import NeoInputText from "./neo/form/NeoInputText.base";
import NeoInputMask from "./neo/form/NeoInputMask.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import { useComponent } from "hooks";

const ModalActualizarDatosCliente = (props) => {
    const componente = useComponent({
        data: {
            input: {
                nombre: {
                    value: props?.datos?.nombre || "",
                    errors: {
                        empty: "* Se requiere que ingreses tu nombre"
                    },
                    error: ""
                },
                telefono: {
                    value: props?.datos?.telefono || "",
                    errors: {
                        empty: "* Se requiere que ingreses tu teléfono"
                    },
                    error: ""
                },
                empresa: {
                    value: props?.datos?.empresa || "",
                    errors: {
                        empty: "* Se requiere que ingreses la empresa a la que perteneces"
                    },
                    error: ""
                },
            }
        }
    });

    const setInputValue = (property, value) => {
        const input = componente.data.input;

        if(!input.hasOwnProperty(property)) {
            console.error(`La propiedad ${property} no existe`);
            return;
        }

        input[property].value = value;
        input[property].error = "";

        componente.data.input = input;
    }

    const validateInputValue = (property) => {
        const input = componente.data.input;
        let isValid = true;

        if(!input.hasOwnProperty(property)) {
            console.error(`La propiedad ${property} no existe`);
            return;
        }

        if(String(input[property].value).trim() === "") {
            input[property].error = input[property].errors.empty;
            isValid = false;
        }

        componente.data.input = input;
        return isValid;
    }

    const isFormValid = () => {
        return Object.entries(componente.data.input).reduce((isValid, [property]) => {
            return validateInputValue(property) 
                ? isValid 
                : false;
        }, true)
    }

    const guardarDatos = () => {
        if(!isFormValid()) {
            return;
        }

        if(typeof props.onActualizar === "function") {
            const values = {};

            for (var [property, content] of Object.entries(componente.data.input)) {
                values[property] = String(content.value).trim();
            }

            props.onActualizar(values);
        }
    }

    return (
        <NeoDialog 
            header={props.header 
                ? props.header
                : "Actualiza tus datos"
            }
            visible={props.visible}
            draggable={false}
            closable={false}
            footer={<NeoButtonMain
                label="Guardar"
                autoFocus
                onClick={guardarDatos}
                loading={props.loading}
            />}
        >
            <NeoInputText
                md="12"
                label="Nombre"
                value={componente.data.input.nombre.value}
                onChange={(event) => setInputValue("nombre", event.target.value)}
                onBlur={() => validateInputValue("nombre")}
                error={componente.data.input.nombre.error}
            />

            <NeoInputText
                md="12"
                label="Empresa"
                value={componente.data.input.empresa.value}
                onChange={(event) => setInputValue("empresa", event.target.value)}
                onBlur={() => validateInputValue("empresa")}
                error={componente.data.input.empresa.error}
            />

            <NeoInputMask
                md="12"
                label="Teléfono"
                mask="(99) 9999-9999"
                value={componente.data.input.telefono.value}
                onChange={(event) => setInputValue("telefono", event.target.value)}
                onBlur={() => validateInputValue("telefono")}
                error={componente.data.input.telefono.error}
            />
        </NeoDialog>
    )
}

export default ModalActualizarDatosCliente;