/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useComponent, useInput } from 'hooks';
import NeoInputText from "./neo/form/NeoInputText.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import NeoCheckbox from "./neo/form/NeoCheckbox.base";
import NeoInputPassword from "./neo/form/NeoInputPassword.base";
import { validaTelefono, validaCorreo, validaContrasena, validaExistencia, regexContrasena } from "consts/validacionDatosUsuario";
import NeoInputMask from "./neo/form/NeoInputMask.base";
import NeoButtonSection from "./neo/layout/NeoButtonSection.base";


const FormularioRegistro = (props) => {
    const componente = useComponent();
    const [nombre, setNombre] = useInput("");
    const [telefono, setTelefono] = useInput("");
    const [empresa, setEmpresa] = useInput("");
    const [correo, setCorreo] = useInput("");
    const [contrasena, setContrasena] = useInput("");
    const [repetirContrasena, setRepetirContrasena] = useInput("");
    const [aceptaCondiciones, setAceptaCondiciones] = componente.useCustomState(false);
    const [errores, setErrores] = componente.useCustomState({});
    const [validar, setValidar] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (validar)
            validaFormulario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, telefono, empresa, correo, contrasena, repetirContrasena, aceptaCondiciones]);

    const validaFormulario = () => {
        let esValido = true;
        setValidar(true);

        const resultadoNombre = validaExistencia(nombre);
        const resultadoTelefono = validaTelefono(telefono);
        const resultadoEmpresa = validaExistencia(empresa);
        const resultadoCorreo = validaCorreo(correo);
        const resultadoContrasena = validaContrasena(contrasena);
        const resultadoRepetirContrasena = validaContrasena(repetirContrasena);
        const resultadoAceptaCondiciones = validaExistencia(aceptaCondiciones);

        if (contrasena !== repetirContrasena) {
            resultadoRepetirContrasena.esValido = false;
            resultadoRepetirContrasena.mensaje = "Las contraseñas no coinciden";
        }

        setErrores({
            nombre: resultadoNombre.mensaje,
            telefono: resultadoTelefono.mensaje,
            empresa: resultadoEmpresa.mensaje,
            correo: resultadoCorreo.mensaje,
            contrasena: resultadoContrasena.mensaje,
            repetirContrasena: resultadoRepetirContrasena.mensaje,
            aceptaCondiciones: resultadoAceptaCondiciones.mensaje
        });

        if (resultadoNombre.esValido === false
            || resultadoTelefono.esValido === false
            || resultadoEmpresa.esValido === false
            || resultadoCorreo.esValido === false
            || resultadoContrasena.esValido === false
            || resultadoRepetirContrasena.esValido === false
            || resultadoAceptaCondiciones.esValido === false) {
            esValido = false;
        }

        return esValido;
    }

    const manejaEnvio = (event) => {
        if (!validaFormulario())
            return false;


        props
            && props.onSubmit
            && props.onSubmit({
                correo: correo,
                contrasena: contrasena,
                telefono: telefono,
                nombre: nombre,
                empresa: empresa
            });
    };

    return (<>
        <NeoColumn >
            <NeoInnerTitle extra="primero" >{props.titulo}</NeoInnerTitle>

            <NeoInputText col="12" id="nombre" value={nombre}
                onChange={setNombre} label="Nombre"
                error={errores.nombre} autoComplete="off" />
            <NeoInputMask col="12" id="telefono" mask="(99) 9999-9999"
                value={telefono} onChange={setTelefono} label="Teléfono" error={errores.telefono} autoComplete="off" />
            <NeoInputText col="12" id="empresa" value={empresa}
                onChange={setEmpresa} label="Empresa"
                error={errores.empresa} autoComplete="off" />
            <NeoInputText col="12" type="email" id="correo" value={correo}
                onChange={setCorreo} label="Correo electrónico"
                error={errores.correo} autoComplete="off" />
            <NeoInputPassword col="12" type="password" id="contrasena"
                value={contrasena} onChange={setContrasena}
                label="Contraseña" error={errores.contrasena}
                autoComplete="off" onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()} 
                strongRegex={regexContrasena} />
            <NeoInputPassword col="12" type="password" id="repetirContrasena"
                value={repetirContrasena} onChange={setRepetirContrasena}
                label="Repetir contraseña" error={errores.repetirContrasena}
                noFeedback autoComplete="off" onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                strongRegex={regexContrasena} />
            <NeoCheckbox col="12" extra="p-mt-0" id="aceptaCondiciones"
                value={aceptaCondiciones} onChange={e => setAceptaCondiciones(e.checked)} label="Acepto términos y condiciones de uso"
                error={errores.aceptaCondiciones} autoComplete="off" />
            <NeoButtonSection flex={false}>
                <NeoButtonMain 
                    label="Iniciar gratis" 
                    subtitle="No es necesario contar con tarjeta de crédito para crear tu cuenta" 
                    onClick={manejaEnvio} 
                    loading={props.registrando}
                />
            </NeoButtonSection>
        </NeoColumn>
    </>)
};

export default FormularioRegistro;