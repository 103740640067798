import { TabPanel } from 'primereact/tabview';

const BaseTabPanel = (props) => {
    return (
        <TabPanel {...props} >
            {props.children}
        </TabPanel>
    );
}

export default BaseTabPanel;