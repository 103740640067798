import NeoSingleContainer from "components/common/neo/layout/NeoSingleContainer.base";
import NeoGridContainer from "components/common/neo/layout/NeoGridContainer.base";
import NeoColumn from "components/common/neo/layout/NeoColumn.base";
import LogoWhite from 'design/assets/img/mnv/logo-bw.svg';
import NeoSpinner from "components/common/neo/overlay/NeoSpinner.base";

const Loading = () => {
    return (
    <NeoSingleContainer>
        <NeoGridContainer col="12" subExtra="h-100">
            <NeoColumn col="12" extra="px-0">
                <a href="https://neovalidador.com">
                    <img className="logo-intro" src={LogoWhite} alt="NeoValidador" />
                </a>
            </NeoColumn>

            <NeoSpinner />
        </NeoGridContainer>
    </NeoSingleContainer>);
}

export default Loading;