import React from 'react';
import BaseInputFile from '../../sisu/form/BaseInputFile.sisu';

const NeoInputFile = (props) => {
  return (
    <BaseInputFile {...props} />
  );
};

export default NeoInputFile;
