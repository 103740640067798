import { useEffect } from "react";
import { functions } from "config/firebase";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import TablaDetalleOrden from "components/common/TablaDetalleOrden";
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import NeoButtonSecondary from "../../common/neo/button/NeoButtonSecondary.base";
import DetalleOrden from "components/common/DetalleOrden";
import ModalPagoManual from "components/common/ModalPagoManual";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import { useComponent } from "hooks";
import useCliente from "hooks/useCliente";

const ClientesOrdenDetalle = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const marcarComoPagadaManual = componente.useAsync(functions.httpsCallable('ordenes-marcarComoPagadaManual'));
    const obtenerDetallesOrden = componente.useAsync(functions.httpsCallable('ordenes-listarDetalles'));
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [ordenId, setOrdenId] = componente.useCustomState();
    const [orden, setOrden] = componente.useCustomState({});
    const [mostrarModalPagoManual, setMostrarModalPagoManual] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props?.location?.state
            && setOrdenId(props.location.state.id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ordenId && obtieneDatos()
    }, [ordenId]) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = async () => {
        obtenerDetallesOrden({ clienteId: cliente.id, ordenId })
            .then((respuesta) => {
                setOrden(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    const marcarComoPagada = async (data) => {
        setMostrarModalPagoManual(false);
        marcarComoPagadaManual({
            ordenId: ordenId,
            referencia: data.referencia,
            nota: data.nota
        }).then((respuesta) => {
            obtieneDatos();

            cliente.recargar();
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond icon={Icono} title="Detalle de orden" />
            <NeoButtonSection
                align="right"
                col="12"
                md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">

                {orden?.orden_pago_estado_nombre === 'Pendiente de pago' &&
                    <NeoButtonSecondary
                        label="Marcar como pagada"
                        extra="warning"
                        onClick={() => setMostrarModalPagoManual(true)} />
                }
            </NeoButtonSection>
        </NeoGridContainer>

        <ModalPagoManual
            visible={mostrarModalPagoManual}
            onClickBtnCerrar={() => setMostrarModalPagoManual(false)}
            onEnviarFormulario={marcarComoPagada}
        />

        {orden &&
            <>
                <DetalleOrden
                    orden={orden} />

                <TablaDetalleOrden orden={orden} />
            </>
        }

        {orden?.orden_estado_id === 4 &&
            <NeoButtonSection align="right">
                <NeoButtonMain
                    label="Cancelar orden"
                    onClick={() => { }}
                />
            </NeoButtonSection>
        }
    </>);
};


export default ClientesOrdenDetalle;