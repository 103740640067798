/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent, useInput } from 'hooks';
import { capitalizar, agrupaPor, abrirCorreo, abrirWhatsApp } from "consts/generales";
import NeoCard from "./neo/panel/NeoCard.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import NeoInputText from "./neo/form/NeoInputText.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import NeoInputChip from "./neo/form/NeoInputChip.base";
import NeoDataSecond from "./neo/data/NeoDataSecond.base";
import NeoDialog from "./neo/overlay/NeoDialog.base";
import NeoButtonSecondary from "./neo/button/NeoButtonSecondary.base";
import NeoButtonOutlined from "./neo/button/NeoButtonOutlined.base";
import { validaNombre, validaCorreo, validaDominio, validaTelefono, validaRFC }
    from "consts/validacionDatosUsuario"
import NeoInputMask from "./neo/form/NeoInputMask.base";
import NeoButtonSection from './neo/layout/NeoButtonSection.base';
import NeoDropdown from "./neo/form/NeoDropdown.base";
import NeoTooltip_icon from "./neo/overlay/NeoTooltip_icon.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import TablaPreciosCliente from "components/common/TablaPreciosCliente";
import NeoMessageLoading from "components/common/neo/messages/NeoMessageLoading.base";
import NeoInputNumber from "./neo/form/NeoInputNumber.base";
import NeoInnerSubtitle from "./neo/title/NeoInnerSubtitle.base"

const opcionesHabilitado = [
    { opcion: "Modo privado", valor: true },
    { opcion: "Modo normal", valor: false }
]

const opcionesEstadoCliente = [
    { opcion: "Activo", valor: 'true' },
    { opcion: "Inactivo", valor: 'false' }
]


const FormularioCuentaUsuario = (props) => {
    const componente = useComponent();
    const [nombre, setNombre] = useInput("");
    const [telefono, setTelefono] = useInput("");
    const [correo, setCorreo] = useInput("");
    const [empresa, setEmpresa] = useInput("");
    const [rfc, setRFC] = useInput("");
    const [activo, setActivo] = componente.useCustomState();
    const [listaNotificaciones, setListaNotificaciones] = useInput([]);
    const [planPago, setPlanPago] = componente.useCustomState("");
    const [dnAsignado, setDnAsignado] = componente.useCustomState("");
    const [correoDnAsignado, setCorreoDnAsignado] = componente.useCustomState("");
    const [whatsAppDnAsignado, setWhatsAppDnAsignado] = componente.useCustomState("");
    const [sobregiro, setSobregiro] = componente.useCustomState();
    const [modoPrivado, setModoPrivado] = componente.useCustomState();
    const [vigenciaDatos, setVigenciaDatos] = componente.useCustomState('');
    const [errores, setErrores] = componente.useCustomState({})
    const [validar, setValidar] = componente.useCustomState(false);
    const [, setTipoPlan] = componente.useCustomState({});
    const mensajeForm = useRef(null);
    const [precios, setPrecios] = componente.useCustomState();
    const [mostrarSobregiro, setMostrarSobregiro] = componente.useCustomState(false);
    const [listaNegraCorreo, setListaNegraCorreo] = useInput([]);
    const [listaNegraTelefono, setListaNegraTelefono] = useInput([]);
    // const [contrasenaSFTP, setContrasenaSFTP] = useInput("");
    const [modalPrecio, setModalPrecio] = componente.useCustomState([]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        estableceDatosIniciales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.datos]);

    useEffect(() => {
        if (!props.precios) {
            return;
        }

        let tablas = Object.keys(props.precios).map((tipo, index) => {
            return <>
                <NeoColumn col="1">
                    <NeoButtonOutlined label={capitalizar(tipo)} onClick={() => {
                        setModalPrecio(tipo);
                    }}/>
                </NeoColumn>

                <NeoDialog
                        header={`Validaciones de ${capitalizar(tipo)}`}
                        visible={modalPrecio === tipo}
                        onHide={() => {
                            setModalPrecio();
                        }}
                    >
                    <TablaPreciosCliente
                        key={index}
                        titulo={capitalizar(tipo)}
                        datos={props.precios[tipo]}
                        vistaCliente={props.vistaCliente}
                    />
                </NeoDialog>
            </>
        });

        setPrecios(tablas);
    }, [props.precios, modalPrecio]);

    useEffect(() => {
        if (validar)
            validaFormulario();

        mensajeForm.current && mensajeForm.current.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, correo, telefono, rfc, listaNotificaciones]);

    useEffect(() => {
        if (planPago?.nombre === 'postpago') {
            setMostrarSobregiro(true);

            if (Array.isArray(props.sobregiroValidaciones)) {
                const sobregiroPermitidoDefecto = 500000;
                const sobregiro = props.sobregiroValidaciones.map((elemento, index) => {
                    const nodo = { ...elemento };
                    // No tiene registrado ningun sobregiro permitido
                    if (!elemento.clienteSobregiroId) {
                        nodo.permitido = sobregiroPermitidoDefecto;
                    }
                    // El sobregiro por defecto es 0
                    else if(nodo.permitido === 0) {
                        nodo.permitido = sobregiroPermitidoDefecto;
                    }
                    return nodo;
                });
                setSobregiro(sobregiro);
            }
        } else {
            setMostrarSobregiro(false);

            if (Array.isArray(props.sobregiroValidaciones)) {
                const sobregiro = props.sobregiroValidaciones.map((elemento, index) => {
                    const nodo = { ...elemento };
                    if (elemento.clienteSobregiroId) {
                        nodo.permitido = 0;
                    }
                    return nodo;
                });
                setSobregiro(sobregiro);
            }
        }
    }, [planPago, props.sobregiroValidaciones])

    const estableceDatosIniciales = () => {
        if (!props.hasOwnProperty("datos")) {
            console.log("No hay datos del cliente");
            return
        }

        setNombre(props.datos?.nombre)
        setTelefono(props.datos?.telefono)
        setCorreo(props.datos?.correo)
        setEmpresa(props.datos?.empresa);
        setRFC(props.datos?.rfc)
        setListaNotificaciones(props.datos?.listaNotificaciones)
        // console.log(props.datos.listaNegra);
        const listaNegra = agrupaPor(props.datos.listaNegra, 'tipo_validacion_nombre');

        if(typeof listaNegra === 'object') {
            if(listaNegra?.correo) {
                const listaNegraCorreo = listaNegra?.correo.map(e => e.valor);
                setListaNegraCorreo(listaNegraCorreo);
            }
            if(listaNegra?.telefono) {
                const listaNegraTelefono = listaNegra?.telefono.map(e => e.valor);
                setListaNegraTelefono(listaNegraTelefono);
            }
        }

        setActivo(String(Boolean(props.datos.activo)))
        setSobregiro(props.sobregiroValidaciones);

        props.asignacionDnEditable
            ? props.datos.listaPlanes && setPlanPago(props.datos.plan)
            : props.datos.plan
            && props.datos.plan.nombre
            && setPlanPago(props.datos.plan.nombre)

        if (props.asignacionDnEditable) {
            props.datos.listaDns && setDnAsignado({
                id: props.datos?.dn?.id,
                correo: props.datos?.dn?.correo,
                nombre: props.datos?.dn?.nombre,
            })
        } else {
            props.datos.dn && setDnAsignado(props.datos.dn.nombre)
            props.datos.dn && setCorreoDnAsignado(props.datos.dn.correo)
            props.datos.dn && setWhatsAppDnAsignado(props.datos.dn.telefono)
        }

        if (props.datos.plan)
            setTipoPlan(props.datos.plan);

        if (props.datos.modoPrivado !== undefined) {
            const modoPrivadoIncial = opcionesHabilitado.find(o => o.valor === props.datos.modoPrivado);
            setModoPrivado(modoPrivadoIncial)
        }

        if (props.datos.vigecia_validaciones) {
            setVigenciaDatos(`${props.datos.vigecia_validaciones}`);
        }
    };

    const validaListNotifs = () => {
        let esValido = true;
        let mensaje = null;

        listaNotificaciones.forEach((item) => {
            esValido = validaCorreo(item).esValido && esValido;
        });

        if (!esValido)
            mensaje = "Existe un correo no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaListNegraCorreo = () => {
        let esValido = true;
        let mensaje = null;

        listaNegraCorreo.forEach((item) => {
            if(!validaDominio(item).esValido) {
                if(!validaCorreo(item).esValido) {
                    const validaCoincidencia = /^[a-z0-9_\-\.]{3,}$/;
                    if(!validaCoincidencia.test(item)) {
                        esValido = false;
                    }
                }
            }
        });

        if (!esValido)
            mensaje = "Existe un dato no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaListNegraTelefono = () => {
        let esValido = true;
        let mensaje = null;

        listaNegraTelefono.forEach((item) => {
            if (esValido) {
                esValido = validaTelefono(item).esValido && esValido;
            }
        });

        if (!esValido)
            mensaje = "Existe un telefono no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaFormulario = () => {
        let esValido = true;
        setValidar(true);

        const resultadoNombre = validaNombre(nombre);
        const resultadoCorreo = validaCorreo(correo);
        const resultadoTelefono = validaTelefono(telefono);
        const resultadoRFC = validaRFC(rfc);
        const resultadoListNotifs = validaListNotifs();
        const resultadoListNegraCorreo = validaListNegraCorreo();
        const resultadoListNegraTelefono = validaListNegraTelefono();
        const resultadoValidaVigencia = validaVigenciaDatos();

        setErrores({
            nombre: resultadoNombre.mensaje,
            correo: resultadoCorreo.mensaje,
            telefono: resultadoTelefono.mensaje,
            rfc: resultadoRFC.mensaje,
            listaNotificaciones: resultadoListNotifs.mensaje,
            listaNegraCorreo: resultadoListNegraCorreo.mensaje,
            listaNegraTelefono: resultadoListNegraTelefono.mensaje,
            vigenciaDatos: resultadoValidaVigencia.mensaje
        });

        if (resultadoNombre.esValido === false
            || resultadoCorreo.esValido === false
            || resultadoTelefono.esValido === false
            || resultadoRFC.esValido === false
            || resultadoListNotifs.esValido === false
            || resultadoListNegraCorreo.esValido === false
            || resultadoListNegraTelefono.esValido === false
            || resultadoValidaVigencia.esValido === false) {
            esValido = false;
        }

        return esValido;
    };

    const manejaGuardar = () => {
        setValidar(true);
        if (!validaFormulario())
            return false;

        //Formulario válido, listo para enviar datos

        props?.onSubmit && props.onSubmit({
            nombre: nombre,
            telefono: telefono,
            correo: correo,
            empresa: empresa,
            rfc: rfc,
            listaNotificaciones: listaNotificaciones,
            listaNegra: {
                correo: listaNegraCorreo,
                telefono: listaNegraTelefono,
            },
            plan: planPago,
            dnAsignado: dnAsignado,
            modoPrivado: modoPrivado.valor,
            vigenciaDatos: vigenciaDatos,
            activo: activo === 'true' ? 1 : 0,
            sobregiroValidaciones: sobregiro
        });
        window.scrollTo(0, 0);
    };

    const validaVigenciaDatos = () => {
        if (vigenciaDatos.trim() === '') {
            return {
                esValido: false,
                mensaje: 'Este campo es necesario'
            };
        } else if (isNaN(vigenciaDatos)) {
            return {
                esValido: false,
                mensaje: 'Este valor no es un valor valido'
            };
        } else if (parseInt(vigenciaDatos) <= 0) {
            return {
                esValido: false,
                mensaje: 'Este valor no es un valor valido'
            };
        }

        return {
            esValido: true
        }
    }

    // const permiteEditarPrecios = () => {
    //     if (props.preciosEditables === true) {
    //         return (rowData, datos) => {
    //             const index = precios.findIndex(
    //                 (precio) => precio.id === rowData.id
    //             );

    //             if (index !== undefined) {
    //                 precios[index].precio = rowData.precio;
    //                 setPrecios(precios);
    //             }
    //         }
    //     }
    //     return false;
    // }

    const cambiaSobregiro = (valor, indice) => {
        let nuevoSobregiro = sobregiro;
        nuevoSobregiro[indice].permitido = valor;
        setSobregiro(nuevoSobregiro);
    }

    const templateListaNegraCorreo = (item) => {
        let clase = '';

        const validaCoincidencia = /^[a-z0-9_\-\.]{3,}$/;

        if(validaCorreo(item).esValido) {
            clase = 'blacklist-data';
        } else if(validaDominio(item).esValido) {
            clase = 'blacklist-domain';
        } else if(validaCoincidencia.test(item)) {
            clase = 'blacklist-coincidence';
        } else {
            clase = 'blacklist-not-allowed';
        }

        return (
            <div className={clase}>
                <span>{item}</span>
            </div>
        );
    }

    const templateListaNegraTelefono = (item) => {
        let clase = '';

        if(validaTelefono(item).esValido) {
            clase = 'blacklist-data';
        } else {
            clase = 'blacklist-not-allowed';
        }

        return (
            <div className={clase}>
                <span>{item}</span>
            </div>
        );
    }

    return (<>
        {
            props.asignacionDnEditable
                ? null
                : <NeoCard >
                    <NeoInnerTitle extra="primero">Detalle del plan</NeoInnerTitle>
                    <NeoColumn sm="3" col="12">
                        <NeoDataSecond id="planPago"
                            fact={capitalizar(planPago)}
                            label="Plan de pago" />
                    </NeoColumn>
                    <NeoColumn sm="4" col="12">
                        <NeoDataSecond
                            id="dnAsignado"
                            fact={dnAsignado}
                            label="Desarrollador de Negocio asignado"
                        />
                    </NeoColumn>
                    <NeoColumn extra="p-text-center" sm="5" col="12">
                        Contacta a {dnAsignado}
                        <NeoButtonSection>
                            <NeoButtonSecondary
                                label={correoDnAsignado}
                                icon="pi pi-envelope"
                                className="p-button-rounded"
                                onClick={(event) => {
                                    abrirCorreo(correoDnAsignado);
                                    event.preventDefault();
                                }}
                            />
                            <NeoButtonSecondary
                                label={`+${whatsAppDnAsignado}`}
                                icon="pi pi-whatsapp"
                                className="p-button-rounded"
                                onClick={(event) => {
                                    abrirWhatsApp(whatsAppDnAsignado, `¡Hola ${dnAsignado}!`);
                                    event.preventDefault();
                                }}
                            />
                        </NeoButtonSection>
                    </NeoColumn>
                </NeoCard>
        }

        <NeoCard>
            <NeoInnerTitle extra="primero">
                Información general
            </NeoInnerTitle>

            <NeoInputText
                md="6"
                id="nombre"
                value={nombre}
                onChange={setNombre}
                label="Nombre"
                error={errores.nombre}
            />

            <NeoInputText
                md="3"
                id="rfc"
                value={rfc}
                onChange={setRFC}
                label="R.F.C."
                error={errores.rfc}
            />

            {
                props.asignacionDnEditable
                    ? <NeoDropdown
                        value={activo}
                        options={opcionesEstadoCliente}
                        onChange={(e) => setActivo(e.value)}
                        placeholder=""
                        optionLabel="opcion"
                        optionValue="valor"
                        label="Estado" />
                    : <NeoColumn sm="3" col="12">
                        <NeoDataSecond
                            id="dnAsignado"
                            fact={
                                activo === 'true'
                                    ? "Activa"
                                    : "Inactiva"
                            }
                            label="Estatus de cuenta"
                        />

                    </NeoColumn>

            }


            <NeoInputText
                md="4"
                id="correo"
                value={correo}
                onChange={setCorreo}
                label="Correo electrónico"
                error={errores.correo}
            />

            <NeoInputText
                md="4"
                id="empresa"
                value={empresa}
                onChange={setEmpresa}
                label="Empresa"
                error={errores.empresa}
            />

            <NeoInputMask
                md="4"
                id="telefono"
                mask="(99) 9999-9999"
                value={telefono}
                onChange={setTelefono}
                label="Teléfono"
                error={errores.telefono}
            />

        </NeoCard>
        <NeoCard>
            <NeoInnerTitle extra="primero">Notificaciones por correo</NeoInnerTitle>
            <NeoInputChip
                placeholder="Lista de correos que recibirán notificaciones"
                value={listaNotificaciones}
                onChange={setListaNotificaciones}
                separator="," allowDuplicate={false}
                error={errores.listaNotificaciones} md="11" />

            <NeoTooltip_icon text="Escribe un correo y agregalo a la lista usando «Enter» ó «,» (coma)" />

        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">Lista negra de correos</NeoInnerTitle>
            <NeoInnerSubtitle>
                <span className="blacklist-domain">Por dominio</span>: Marca como en lista negra todos los correos que corresponden con el dominio.
                Ej. <strong>@temporal.com</strong>, <strong>@prueba.com</strong>
            </NeoInnerSubtitle>
            <NeoInnerSubtitle>
                <span className="blacklist-coincidence">Por coincidencia</span>: Marca como en lista negra los correos que contengan la palabra establecida.
                Ej. <strong>notengo</strong>, <strong>temporal</strong>
            </NeoInnerSubtitle>
            <NeoInnerSubtitle>
                <span className="blacklist-data">Por dato</span>: Marca como en lista negra los datos que corresponda con la lista negra.
                Ej. <strong>no.existe@gmail.com</strong>, <strong>temporal@hotmail.com</strong>
            </NeoInnerSubtitle>
            <NeoInputChip
                placeholder="Correos en la lista negra"
                value={listaNegraCorreo}
                onChange={setListaNegraCorreo}
                separator=","
                allowDuplicate={false}
                error={errores.listaNegraCorreo}
                md="11" 
                itemTemplate={templateListaNegraCorreo} />

            <NeoTooltip_icon text="Escribe un dominio y agregalo a la lista usando «Enter» ó «,» (coma)" />
        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">Lista negra de teléfonos</NeoInnerTitle>
            <NeoInnerSubtitle>
                <span className="blacklist-data">Por dato</span>: Marca como en lista negra los datos que corresponda con la lista negra.
                Ej. <strong>5511223344</strong>, <strong>5511112222</strong>
            </NeoInnerSubtitle>
            <NeoInputChip
                placeholder="Teléfonos en la lista negra"
                value={listaNegraTelefono}
                onChange={setListaNegraTelefono}
                separator=","
                allowDuplicate={false}
                error={errores.listaNegraTelefono}
                md="11" 
                itemTemplate={templateListaNegraTelefono} />

            <NeoTooltip_icon text="Escribe un teléfono y agregalo a la lista usando «Enter» ó «,» (coma)" />
        </NeoCard>

        {
            props.asignacionDnEditable
                ? <NeoCard >
                    <NeoInnerTitle extra="primero">Plan de pago</NeoInnerTitle>
                    <NeoDropdown
                        md="6"
                        value={planPago}
                        options={props.datos.listaPlanes}
                        onChange={(e) => setPlanPago(e.value)}
                        placeholder=""
                        optionLabel="nombre"
                        label="Plan de pago" />


                    <NeoDropdown
                        md="6"
                        value={dnAsignado}
                        options={props.datos.listaDns}
                        onChange={(e) => setDnAsignado(e.value)}
                        placeholder=""
                        optionLabel="nombre"
                        label="Desarrollador de Negocio asignado" />
                </NeoCard>
                : null
        }

        {
            props.asignacionDnEditable && mostrarSobregiro
                ? <NeoCard >
                    <NeoInnerTitle extra="primero">
                        Sobregiro de validaciones permitido
                    </NeoInnerTitle>
                    {
                        sobregiro && sobregiro.map((nodo, indice) => {
                            return <NeoInputNumber
                                md="4"
                                key={indice}
                                value={nodo.permitido}
                                onChange={(e) => cambiaSobregiro(e.value, indice)}
                                placeholder=""
                                optionLabel="nombre"
                                label={capitalizar(nodo.nombre)}
                            />
                        })

                    }
                </NeoCard>
                : null
        }

        <NeoCard>
            <NeoInnerTitle extra="primero">Privacidad de datos</NeoInnerTitle>
            <NeoDropdown
                md="7"
                value={modoPrivado}
                options={opcionesHabilitado}
                onChange={(e) => setModoPrivado(e.value)}
                placeholder=""
                optionLabel="opcion"
                optionValue=""
                label="Política de almacenamiento de datos" />

            <NeoTooltip_icon
                text="El modo privado no guardará el dato validado (ej. correo o teléfono), pero aún podrás consultar las estadísticas"
            />

            <NeoInputText
                md="3"
                value={vigenciaDatos}
                disabled={modoPrivado?.valor === true}
                onChange={(event) => setVigenciaDatos(event.target.value)}
                label="Días que se guardaran los datos validados"
                error={errores.vigenciaDatos} />
            <NeoTooltip_icon
                text="Cantidad de días que se guardaran los datos en el modo normal"
            />
        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">
                Precios de validaciones
            </NeoInnerTitle>

            {
                precios
                    ? precios
                    : <NeoMessageLoading />
            }
        </NeoCard>

        <NeoButtonSection align="right" >
            <NeoButtonMain label="Guardar" onClick={manejaGuardar} />
        </NeoButtonSection>
    </>);
};


export default FormularioCuentaUsuario;