import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useUsuario from "hooks/useUsuario";
import Inicio from "components/views/Inicio";
import Loading from "components/views/Loading";

const Acceso = () => {
    const history = useHistory();
    const usuario = useUsuario();

    useEffect(() => {
        if(usuario.estaLogueado()) {
            // Valida si es la primera vez que estan iniciando sesión en el sistema
            if(usuario.data.loggins === 1) {
                const from = history?.location?.state?.from;
                const url = `${from?.pathname}${from?.search}`;

                if (url.startsWith('/clientes') && usuario.esCliente()) {
                    history.push(url);
                } else if (url.startsWith('/administracion') && usuario.esEmpleado()) {
                    history.push(url);
                } else {
                    history.push(usuario.obtieneRutaDashboard());
                }
            } else {
                history.push(usuario.obtieneRutaDashboard());
            }
        }

    }, [usuario.persona]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        usuario.data.loading ?
            <Loading />
        :
            <Inicio />
    );
};

export default Acceso;