/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { storage } from "config/firebase";
import { capitalizar, aDivisaLocal } from "consts/generales";
import NeoCard from './neo/panel/NeoCard.base';
import NeoColumn from './neo/layout/NeoColumn.base';
import NeoDataMain from "./neo/data/NeoDataMain.base";
import NeoDataSecond from "./neo/data/NeoDataSecond.base";
import Async from "./Async";
import customStorage from "consts/customStorage";
import { useComponent } from 'hooks';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';

const storageRef = storage.ref();

const DetalleOrden = (props) => {
    const componente = useComponent();
    const [orden, setOrden] = componente.useCustomState();
    const [dirFacturasRef, setDirFacturasRef] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.orden) {
            setOrden(props.orden);
            if(props.orden.pago_id) {
                setDirFacturasRef(
                    storageRef.child(
                    `clientes/${props.orden.cliente_id}/pagos/${props.orden.pago_id}/facturas/`
                ));
            }
        }
    }, [props.orden])

    const checarSiHayFacturas = async () => {
        const storageInstance = customStorage(dirFacturasRef);
        return storageInstance.obtenerDetalles();
    }

    const mostrarArchivosFacturas = (storage) => {
        if (storage.totalArchivos > 0) {
            return (
                <NeoColumn md={3}>
                    <NeoDataMain
                        fact={
                            storage.archivos.map((archivoStorage) => {
                                const renderButton = (archivo) => {
                                    const extension = archivo.extension;
                                    let icon;
                
                                    switch (extension) {
                                        case 'pdf':
                                            icon = 'pi pi-file-pdf';
                                            break;
                                        case 'xml':
                                            icon = 'pi pi-file-o';
                                            break;
                                        default:
                                            break;
                                    }
                
                                    return (
                                        <NeoButtonOutlined 
                                            label={extension?.toUpperCase()} 
                                            icon={icon}
                                            onClick={() => {
                                                archivoStorage.descargar()
                                            }}
                                        />
                                    )
                                }
                
                                return (
                                    <Async
                                        promise={archivoStorage.obtenerDetalles()}
                                        then={renderButton} />
                
                                )
                            })
                        }
                        label="Factura asociada" />
                </NeoColumn>
            );
        }
        return null;
    }

    const tamañoColumnas = props.verDetalles && orden?.orden_pago_estado_nombre !== 'Pendiente de pago' ? 4 : 3;

    return (
        <NeoCard>
            <NeoColumn md={tamañoColumnas}>
                <NeoDataMain fact={orden && `$${aDivisaLocal(orden.precio_total)}`}
                    label="Total" />
            </NeoColumn>
            <NeoColumn md={tamañoColumnas}>
                <NeoDataSecond fact={orden && orden.orden_pago_estado_nombre
                    && capitalizar(orden.orden_pago_estado_nombre)}
                    label="Estado" />
            </NeoColumn>

            {orden?.orden_pago_estado_nombre !== 'Pendiente de pago' &&
                <>
                    <Async
                        promise={checarSiHayFacturas()}
                        then={mostrarArchivosFacturas} />

                    <NeoColumn md={tamañoColumnas}>
                        <NeoDataSecond fact={orden && orden.fecha_inicio}
                            label="Vigentes desde" />
                    </NeoColumn>
                </>
            }

            {props.verDetalles &&
                <>
                    {orden?.orden_pago_estado_nombre !== 'Pendiente de pago' &&
                        <NeoColumn md={tamañoColumnas}>
                            <NeoDataSecond fact={orden && orden.meses_vigencia}
                                label="Meses de duración" />
                        </NeoColumn>
                    }
                    <NeoColumn md={tamañoColumnas}>
                        <NeoDataSecond fact={orden && orden.fecha_creacion}
                            label="Fecha de creación de la orden" />
                    </NeoColumn>
                </>
            }
        </NeoCard>)
}

export default DetalleOrden;