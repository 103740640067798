/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from "react";
import { DateTime } from "luxon";
import { storage } from "config/firebase";
import NeoTable from "./neo/table/NeoTable.base";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import NeoTooltip from "./neo/overlay/NeoTooltip.base";
import { capitalizar, aCadenaFechaConFormatoLocal }
    from "../../consts/generales";
import { Column } from 'primereact/column';
import ArchivosStorage from "components/common/ArchivosStorage";
import NeoMultiSelect from "./neo/form/NeoMultiSelect.base";
import NeoInputText from "./neo/form/NeoInputText.base";
import ProgresoValidacionArchivo from "./ProgresoValidacionArchivo";
import { useComponent } from 'hooks';
import NeoFlexContainer from "./neo/layout/NeoFlexContainer.base";
import NeoButtonSecondary from "./neo/button/NeoButtonSecondary.base";
import NeoCalendar from "./neo/form/NeoCalendar.base";


const TablaHistoricoValidacionesAdmin = (props) => {
    const componente = useComponent();
    const [, setEstadoGlobal] = useContext(EstadoGlobal);
    const lazyParams = props.lazyParams;
    const setLazyParams = props.setLazyParams;
    const [timer, setTimer] = componente.useCustomState(null);
    const [filaSeleccionada, setFilaSeleccionada] = useState();

    const hoy = DateTime.now().setLocale('es-MX');
    const [fechaFiltroInicio, setFechaFiltroInicio] = componente.useCustomState(
        new Date(hoy.minus({ month: 1 }))
    );
    const [fechaFiltroFin, setFechaFiltroFin] = componente.useCustomState(
        new Date()
    );
    const [filtroFechas, setFiltroFechas] = componente.useCustomState();

    const [idSeleccionado, setIdSeleccionado] = componente.useCustomState("");
    const [clienteSeleccionado, setClienteSeleccionado] = componente.useCustomState("");
    const [empresaSeleccionada, setEmpresaSeleccionada] = componente.useCustomState("");
    const [metodoSeleccionado, setMetodoSeleccionado] = componente.useCustomState("");
    const [referenciaSeleccionada, setReferenciaSeleccionada] = componente.useCustomState("");
    const [cantidadSeleccionada, setCantidadSeleccionada] = componente.useCustomState("");
    const [estadoSeleccionado, setEstadoSeleccionado] = componente.useCustomState("");
    const [listaMetodos,] = componente.useCustomState([
        {
            nombre: 'Api',
            valor: {
                metodo: 'Api',
                tipo_volumen: 'individual'
            }
        },
        {
            nombre: 'Web',
            valor: {
                metodo: 'Web',
                tipo_volumen: 'individual'
            }
        },
        {
            nombre: 'Web - Archivo',
            valor: {
                metodo: 'Web',
                tipo_volumen: 'archivo'
            }
        },
        {
            nombre: 'SFTP',
            valor: {
                metodo: 'SFTP',
                tipo_volumen: 'archivo'
            }
        }
    ]);
    const [, setListaApis] = componente.useCustomState([]);
    const [listaEstados,] = componente.useCustomState([
        {
            nombre: 'Procesando'
        },
        {
            nombre: 'Finalizado'
        },
        {
            nombre: 'Cancelado'
        },
        {
            nombre: 'Expirado'
        }
    ]);
    const dt = useRef(null);


    useEffect(() => {
        if (typeof lazyParams?.filters === 'object') {
            const filters = lazyParams.filters;

            if (typeof filters?.id === 'object') {
                setIdSeleccionado(filters.id.value);
            }

            if (typeof filters?.cliente === 'object') {
                setClienteSeleccionado(filters.cliente.value);
            }

            if (typeof filters?.empresa === 'object') {
                setEmpresaSeleccionada(filters.empresa.value);
            }

            if (typeof filters?.metodo === 'object'
                && typeof filters?.tipo_volumen === 'object') {
                const metodo = filters.metodo.value.map((elemento, index) => {
                    return {
                        metodo: elemento,
                        tipo_volumen: filters.tipo_volumen?.value[index]
                    }
                });
                setMetodoSeleccionado(metodo);
            }

            if (typeof filters?.referencia === 'object') {
                setReferenciaSeleccionada(filters.referencia.value);
            }

            if (typeof filters?.cantidad === 'object') {
                setCantidadSeleccionada(filters.cantidad.value);
            }

            if (typeof filters?.estado === 'object') {
                setEstadoSeleccionado(filters.estado.value);
            }

        }

        return () => { componente.desmontar(); };
    }, []);


    useEffect(() => {
        if (Array.isArray(props.apis)) {
            setListaApis(props.apis);
        }
    }, [props.apis]);


    useEffect(() => {
        if (filtroFechas && filtroFechas.inicio !== null && filtroFechas.fin !== null) {

            if (filtroFechas.inicio !== lazyParams?.filters?.rangoFechas?.value?.inicio
                || filtroFechas.fin !== lazyParams?.filters?.rangoFechas?.value?.fin
            ) {
                dt?.current?.filter(filtroFechas, 'rangoFechas', 'custom');
            }
        }
    }, [filtroFechas])


    useEffect(() => {
        if (lazyParams) {
            if (lazyParams?.filters?.rangoFechas?.value?.inicio
                && lazyParams?.filters?.rangoFechas?.value?.fin
                && (
                    filtroFechas?.inicio !== lazyParams?.filters?.rangoFechas?.value?.inicio
                    || filtroFechas?.fin !== lazyParams?.filters?.rangoFechas?.value?.fin
                )
            ) {

                let inicio = DateTime.fromISO(lazyParams?.filters?.rangoFechas.value.inicio)
                let fin = DateTime.fromISO(lazyParams?.filters?.rangoFechas.value.fin)

                setFechaFiltroInicio(new Date(inicio.toISO()));
                setFechaFiltroFin(new Date(fin.toISO()));
            }
        }
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps


    const formatDate = (date) => {
        return DateTime.fromJSDate(date).toISODate();
    }

    const recargaTabla = () => {
        if (fechaFiltroInicio && fechaFiltroFin) {
            let fechas = {
                inicio: formatDate(fechaFiltroInicio),
                fin: formatDate(fechaFiltroFin)
            };

            if (fechas.inicio !== lazyParams?.filters?.rangoFechas?.value?.inicio
                || fechas.fin !== lazyParams?.filters?.rangoFechas?.value?.fin
            ) {
                setFiltroFechas(fechas);
            }
        }
    }


    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }


    const idBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Origen</span>
            {rowData.id}
        </>);
    }

    const clienteBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cliente</span>
            {rowData.nombre}
        </>);
    }

    const empresaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Empresa</span>
            {rowData.empresa}
        </>);
    }

    const metodoBodyTemplate = (rowData) => {
        const descripcion = () => {
            if (rowData.metodo === 'sftp') {
                return 'SFTP';
            }
            else if (rowData.tipo_volumen === 'archivo') {
                return 'Archivo'
            } else if (rowData.metodo === 'api') {
                return 'API';
            } else if (rowData.metodo === 'web') {
                return 'Web';
            }
            return rowData.metodo;
        }

        return (<>
            <span className="p-column-title">Metodo</span>
            <span className="p-d-block">{descripcion()}</span>
        </>);
    }

    const referenciaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Referencia</span>
            {rowData.api ?
                <span className="p-d-block">{rowData.api}</span>
                :
                <span className="p-d-block nombre-archivo" data-pr-tooltip={rowData.referencia}>{rowData.referencia}
                    <NeoTooltip
                        target=".nombre-archivo"
                        mouseTrack
                        mouseTrackLeft={10}
                    />
                </span>

            }

        </>);
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {
                rowData.cantidad
                    ? rowData.cantidad.toLocaleString('es-MX')
                    : 0
            }
        </>);
    }

    const fechaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha</span>
            {aCadenaFechaConFormatoLocal(rowData.fecha)}
        </>);
    }

    const estadoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Estado</span>
            {
                // No se crea uno nuevo, solo se reutiliza
                rowData.tipo_volumen === 'archivo'
                    && rowData.estado === 'procesando'
                    ? <ProgresoValidacionArchivo
                        key={rowData.id}
                        dato={rowData}
                        onFinalize={() => {
                            if(typeof props.alFinalizarValidacionArchivo === 'function') {
                                props.alFinalizarValidacionArchivo();
                            }
                        }}
                    />
                    : capitalizar(rowData.estado)
            }
        </>);
    }

    const obtenerArchivoOriginalCsv = async (rowData) => {
        let file;
        let url;
        let path =
            "clientes/"
            + rowData.clienteId
            + "/validaciones/"
            + rowData.id;

        try {
            file = await storage.ref(path + "/archivoOriginal.csv")
            url = file.getDownloadURL();


        } catch (error) {
            return null
        }

        if (url) {
            return <NeoButtonSecondary
                    type="button"
                    icon="pi pi-file"
                    onClick={exporta}
                    className="p-mr-2"
                    data-pr-tooltip="CSV"
                />
        } else {
            return null
        }

    }

    const obtenerArchivoResultados = async (props) => {
        let file;
        let url;
        let path =
            "clientes/"
            + props.clienteId
            + "/validaciones/"
            + props.id;
        try {
            file = storage.ref(path + "/Resultado.csv")
            url = await file.getDownloadURL();
        } catch (error) {
        }
        return url;
    }

    // const obtenerArchivos = async (rowData) => {
    //     // obtenerArchivoOriginalCsv

    // }

    const archivosBodyTemplate = (rowData) => {
        const storageRef = storage.ref(`clientes/${rowData.clienteId}/validaciones/${rowData.id}/`);

        return (<>
            <span className="p-column-title">Archivos</span>
            {
                <ArchivosStorage
                    storageRef={storageRef}
                    // permitirEliminarTodo={props.permitirEliminarTodo}
                    // setEliminandoTodo={setEliminandoTodo}
                />
            }
        </>);
    }


    const onCambiaId = (value) => {
        if (value === '') {
            value = "";
        }
        setIdSeleccionado(value);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dt.current.filter(value, 'id', 'equals');
            }, 500)
        );
    }
    const filtroId = <NeoInputText
        value={idSeleccionado}
        onChange={(e) => onCambiaId(e.target.value)} />;

    const onCambiaCliente = (value) => {
        if (value === '') {
            value = "";
        }
        setClienteSeleccionado(value);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dt.current.filter(value, 'nombre', 'contains');
            }, 500)
        );
    }
    const filtroCliente = <NeoInputText
        value={clienteSeleccionado}
        onChange={(e) => onCambiaCliente(e.target.value)} />;

    const onCambiaEmpresa = (value) => {
        if (value === '') {
            value = "";
        }
        setEmpresaSeleccionada(value);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dt.current.filter(value, 'empresa', 'contains');
            }, 500)
        );
    }
    const filtroEmpresa = <NeoInputText
        value={empresaSeleccionada}
        onChange={(e) => onCambiaEmpresa(e.target.value)} />;

    const onCambiaMetodo = (e) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams['first'] = 0;

        _lazyParams.filters = _lazyParams.filters || {};

        if (e.value.length === 0) {
            if (_lazyParams.filters?.metodo !== undefined) {
                delete _lazyParams.filters.metodo;
            }
            if (_lazyParams.filters?.tipo_volumen !== undefined) {
                delete _lazyParams.filters?.tipo_volumen;
            }
            setMetodoSeleccionado(null);
        } else {
            const metodos = e.value.map((valor) => valor.metodo);
            _lazyParams.filters.metodo = {
                value: metodos,
                matchMode: "in"
            }

            const tipos_volumen = e.value.map((valor) => valor.tipo_volumen);
            _lazyParams.filters.tipo_volumen = {
                value: tipos_volumen,
                matchMode: "in"
            }

            setMetodoSeleccionado(e.value);
        }

        setLazyParams(_lazyParams);
    }

    const metodoItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.nombre}</span>
            </div>
        );
    };

    const filtroMetodo = <NeoMultiSelect
        value={metodoSeleccionado}
        options={listaMetodos}
        itemTemplate={metodoItemTemplate}
        onChange={onCambiaMetodo}
        optionLabel="nombre"
        optionValue="valor"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaReferencia = (value) => {
        if (value === '') {
            value = null;
        }
        setReferenciaSeleccionada(value);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dt.current.filter(value, 'referencia', 'contains');
            }, 500)
        );
    }

    const filtroReferencia = <NeoInputText
        value={referenciaSeleccionada}
        onChange={(e) => onCambiaReferencia(e.target.value)} />;

    const onCambiaCantidad = (value) => {
        if (value === '') {
            value = null;
        }
        setCantidadSeleccionada(value);

        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setTimer(
            setTimeout(() => {
                dt.current.filter(value, 'cantidad', 'contains');
            }, 500)
        );
    }

    const filtroCantidad = <NeoInputText
        value={cantidadSeleccionada}
        onChange={(e) => onCambiaCantidad(e.target.value)} />;

    const estadoItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.nombre}</span>
            </div>
        );
    };


    const onCambiaEstado = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'estado', 'in');
        setEstadoSeleccionado(e.value);
    }

    const filtroEstado = <NeoMultiSelect
        value={estadoSeleccionado}
        options={listaEstados}
        itemTemplate={estadoItemTemplate}
        onChange={onCambiaEstado}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const exporta = () => {
        console.log(filaSeleccionada);
    }


    const header = <NeoFlexContainer extra=" p-ai-center p-py-2">
            <NeoCalendar col="3"
                label="Fecha de inicio"
                value={fechaFiltroInicio}
                onChange={(e) => setFechaFiltroInicio(e.value)}
                locale="es-MX"
                dateFormat="dd/mm/yy"
                showButtonBar
                minDate={new Date(hoy.minus({ year: 1 }))}
                maxDate={new Date()}
            />
            <NeoCalendar col="3"
                label="Fecha de término"
                value={fechaFiltroFin}
                onChange={(e) => setFechaFiltroFin(e.value)}
                locale="es-MX"
                dateFormat="dd/mm/yy"
                showButtonBar
                minDate={new Date(hoy.minus({ year: 1 }))}
                maxDate={new Date()}
            />

            <NeoButtonSecondary
                type="button" icon="pi pi-filter" onClick={() => recargaTabla()} className="p-button-info p-mr-2" data-pr-tooltip="Selection Only" />

            {/* <div className="p-button-info p-ml-auto p-mr-0" >
                <NeoButtonSecondary
                    type="button" icon="pi pi-file" onClick={exporta} className="p-mr-2" data-pr-tooltip="CSV" />
                <NeoButtonSecondary
                    type="button" icon="pi pi-file-excel" onClick={exporta} className="p-button-success p-mr-2" data-pr-tooltip="XLS" />
                <NeoButtonSecondary
                    type="button" icon="pi pi-file-pdf" onClick={exporta} className="p-button-warning p-ml-auto" data-pr-tooltip="PDF" />
            </div> */}

        </NeoFlexContainer>;

    return (
        <NeoTable
            header={header}
            stripedRows
            selection={filaSeleccionada}
            onSelectionChange={e => setFilaSeleccionada(e.value)}
            dataKey="id"
            value={props.datos}
            paginator
            rows={8}
            removableSort
            ref={dt}
            totalRecords={props.totalRegistros}
            loading={props.loading}
            emptyMessage="No existen datos"
            lazy
            first={lazyParams.first}
            onPage={onPage}
            onSort={onSort}
            onFilter={onFilter}
            filters={lazyParams.filters}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
        >

            {/* <Column selectionMode="single" headerStyle={{width:'3em'}} /> */}

            <Column
                field="id"
                header="ID"
                filter
                sortable
                body={idBodyTemplate}
                filterElement={filtroId} />

            <Column
                field="cliente"
                header="Cliente"
                filter
                sortable
                body={clienteBodyTemplate}
                filterElement={filtroCliente} />

            <Column
                field="empresa"
                header="Empresa"
                filter
                sortable
                body={empresaBodyTemplate}
                filterElement={filtroEmpresa} />

            <Column
                field="metodo"
                header="Origen"
                sortable
                filter
                body={metodoBodyTemplate}
                filterElement={filtroMetodo} />

            <Column
                field="referencia"
                header="Referencia"
                filter
                sortable
                body={referenciaBodyTemplate}
                filterElement={filtroReferencia} />

            <Column
                field="cantidad"
                header="Cantidad"
                filter
                sortable
                body={cantidadBodyTemplate}
                filterElement={filtroCantidad} />

            <Column
                field="fecha"
                header="Fecha"
                body={fechaBodyTemplate}
                sortable
            />

            <Column
                field="estado"
                header="Estado"
                sortable
                body={estadoBodyTemplate}
                filter
                filterElement={filtroEstado} />

            {/* <Column
                field="archivos"
                header="Archivos"
                sortable
                body={archivosBodyTemplate}
                // filter
                // filterElement={filtroEstado}
            /> */}

    </NeoTable>)
}


export default TablaHistoricoValidacionesAdmin;