import React from "react";
import { convierteAMoneda, formateaANumeroLocal } from "consts/generales";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoTable from "./neo/table/NeoTable.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';


const TablaPreciosCliente = (props) => {

    const limInferiorBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Desde</span>
            {formateaANumeroLocal(rowData.limite_inferior)}
        </>);
    }

    const limSuperiorBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Hasta</span>
            {formateaANumeroLocal(rowData.limite_superior)}
        </>);
    }

    const precioOmisionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio base</span>
            {convierteAMoneda(rowData.precio_omision)}
        </>);
    }

    const precioClienteBodyTemplate = (rowData) => {
        const onPrecioCambia = (nuevo_precio) => {
            rowData.precio_cliente = Number(nuevo_precio);
        }

        return (<>
            <span className="p-column-title">Precio cliente</span>
            <InputNumber
                value={Number(rowData['precio_cliente'])}
                onValueChange={(e) => onPrecioCambia(e.target.value)}
                mode="currency"
                currency="MXN"
                locale="es-MX"
                minFractionDigits={1}
                maxFractionDigits={4}
                min={0} max={1000}
                size={7}
            />
        </>);
    }


    const precioBodyTemplate = (rowData) => {
        let precioCliente = Number(rowData.precio_cliente || rowData.precio);
        let precio = Number(rowData.precio);

        return (<>
            <span className="p-column-title">Precio cliente</span>
            {convierteAMoneda(precioCliente || precio)}
        </>);
    }


    return (
        <NeoColumn col="auto">
            {
                props.titulo &&
                <NeoInnerTitle extra="primero">
                    {props.titulo}
                </NeoInnerTitle>
            }
            <NeoTable value={props.datos} editMode="cell" extra="tabla-precios">
                <Column header="Desde" body={limInferiorBodyTemplate} />
                <Column header="Hasta" body={limSuperiorBodyTemplate} />

                {
                    props && props.vistaCliente
                        ? <Column
                            header="Precio"
                            body={precioBodyTemplate} />
                        : <Column
                            header="Precio base"
                            body={precioOmisionBodyTemplate} />
                }

                {
                    props && props.vistaCliente
                        ? null
                        : <Column
                            header="Precio cliente"
                            body={precioClienteBodyTemplate} />

                }


            </NeoTable>
        </NeoColumn>
    );
}

export default TablaPreciosCliente;