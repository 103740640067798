import React, { useEffect, useContext } from "react";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import ExcelJs from "exceljs";
import Papaparse from "papaparse";
import { functions, storage } from "config/firebase";
import NeoButtonImage from "./neo/button/NeoButtonImage.base";
import NeoInnerSubtitle from "./neo/title/NeoInnerSubtitle.base"
import NeoDataSecond from "./neo/data/NeoDataSecond.base"
import NeoColumn from "./neo/layout/NeoColumn.base"
import NeoList from "./neo/data/NeoList.base"
import NeoListItem from "./neo/data/NeoListItem.base"
import NeoDialog from "./neo/overlay/NeoDialog.base"
import NeoGridContainer from "./neo/layout/NeoGridContainer.base";
import NeoFlexContainer from "./neo/layout/NeoFlexContainer.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "./neo/button/NeoButtonOutlined.base";
import NeoTooltip_icon from "./neo/overlay/NeoTooltip_icon.base";
import NeoButtonSection from "components/common/neo/layout/NeoButtonSection.base";

import correo from 'design/assets/img/mnv/icons/mail.svg';
import telefono from 'design/assets/img/mnv/icons/phones.svg';

import NeoInputText from "./neo/form/NeoInputText.base";
import NeoInputFile from "./neo/form/NeoInputFile.base";
import NeoCheckbox from "./neo/form/NeoCheckbox.base";
import NeoRadioButton from "./neo/form/NeoRadioButton.base";
import NeoSpinner from "./neo/overlay/NeoSpinner.base";
import NeoCard from "./neo/panel/NeoCard.base";
import NeoButtonSelect from "./neo/button/NeoButtonSelect.base";

import excelJsExtras from "consts/excelJsExtras.js"

import { useComponent, useTrigger } from "hooks";
import NeoAutoGrid from "./neo/layout/NeoAutoGrid.base";
import NeoNote from "./neo/data/NeoNote.base";
import { ProgressBar } from 'primereact/progressbar';
import { confirmDialog } from 'primereact/confirmdialog';

import { capitalizar, generarID } from "consts/generales";

const ModalValidacionArchivo = (props) => {
    const componente = useComponent();
    const trigger = useTrigger();
    const [estadoGlobal, setEstadoGlobal] = useContext(EstadoGlobal);

    const MODAL_SELECCION_TIPO = 1;//"SELECT TIPO"
    const MODAL_CONFIGURACION = 2;
    const MODAL_CARGA_ARCHIVO = 3;
    const MODAL_VISTA_PREVIA = 4;
    const MODAL_CARGANDO_ARCHIVO = 5;
    const MODAL_VALIDACION_CREADA = 6;
    const MODAL_EN_CONSTRUCCION = 7;
    const MODAL_PROCESO_FALLIDO = -1;
    const TIPO_VALIDACION_ARCHIVO = 1;
    const TIPO_VALIDACION_CORREO = {id: 1, nombre: "correo"};
    const TIPO_VALIDACION_TELEFONO = {id: 2, nombre: "teléfono"};
    const VALIDACIONES_ARCHIVO_PREVALIDAR_HISTORICO = true;
    const CABECERAS_CORREO = [
        "EMAIL",
        "CORREO"
    ]
    const CABECERAS_TELEFONO = [
        "TELEFONO",
        "TELÉFONO",
        "CELULAR",
        "TEL",
        "CEL",
        "MOVIL",
        "telefono_celular"
    ]
    const COLOR_SUBIDA_ARCHIVO_INICIADO = '#2196F3';
    const COLOR_SUBIDA_ARCHIVO_FINALIZADO = '#2f9c0a';
    const COLOR_SUBIDA_ARCHIVO_ERROR = '#2f9c0a';

    const [modalActual, setModalActual] = componente.useCustomState(MODAL_CONFIGURACION);
    const [tipoValidacion, setTipoValidacion] = componente.useCustomState(TIPO_VALIDACION_CORREO);
    // const [modalActual, setModalActual] = componente.useCustomState(MODAL_CONFIGURACION);
    // const tipoValidacion = TIPO_VALIDACION_ARCHIVO;
    const [totalRegistros, setTotalRegistros] = componente.useCustomState(0);
    const [nombreValidacion, setNombreValidacion] = componente.useCustomState("");
    const [nombreOriginal, setNombreOriginal] = componente.useCustomState(false);
    const [tipoArchivo, setTipoArchivo] = componente.useCustomState(false);
    const [archivo, setArchivo] = componente.useCustomState(false);
    const [eliminarDuplicados, setEliminarDuplicados] = componente.useCustomState(false);
    const [prevalidarHistorico, setPrevalidarHistorico] = componente.useCustomState(true);
    const [razonProcesoFallido, setRazonProcesoFallido] = componente.useCustomState("");
    const [detalleProcesoFallido, setDetalleProcesoFallido] = componente.useCustomState("");
    const [columnaDatoValidar, setcolumnaDatoValidarValidar] = componente.useCustomState(-1);
    const [progresoSubidaArchivo, setProgresoSubidaArchivo] = componente.useCustomState(0);
    const [colorProgressBar, setColorProgressBar] = componente.useCustomState(COLOR_SUBIDA_ARCHIVO_INICIADO);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);


    const obtenerVistaPrevia = async (evento) => {
        let archivo = evento.files[0];
        setArchivo(archivo);
        setNombreOriginal(archivo.name);
        const nombreSplit = archivo.name.split('.');
        const tipoArchivo = nombreSplit[nombreSplit.length - 1];
        setTipoArchivo(tipoArchivo);

        if (!nombreValidacion) {
            setNombreValidacion(archivo.name)
        }
        setModalActual(MODAL_CARGANDO_ARCHIVO);

        let { noRegistros, colDatoValidar } = await leerArchivo(tipoArchivo, archivo);

        console.log("Registros y columna detectada: ", noRegistros, colDatoValidar);

        if (colDatoValidar < 1) {
            const columnaCabecera = tipoValidacion.id === TIPO_VALIDACION_CORREO.id
            ? 'CORREO'
            : 'TELÉFONO';
                
            mostrarProcesoFallido(
                "Formato no valido.",
                `Recuerde que el archivo debe contener una cabecera, debe existir una columna a validar con el nombre ${columnaCabecera} o bien, que alguna columna contenga un correo/teléfono con formato valido.`
            );

            setArchivo(false);
            setNombreValidacion("");
        } else {
            setcolumnaDatoValidarValidar(colDatoValidar);
            setTotalRegistros(noRegistros - 1);
            setModalActual(MODAL_VISTA_PREVIA);
        }
    }

    const leerArchivo = async (tipoArchivo, archivo) => {
        console.log("Tipo de archivo:", tipoArchivo);
        if (tipoArchivo === "csv") {
            return await leerArchivoCsv(archivo);
        } else {
            return await leerArchivoXlsx(archivo);
        }
    }

    const volverCargarArchivo = () => {
        setTotalRegistros(0);
        setcolumnaDatoValidarValidar(-1);
        setArchivo(null);
        setModalActual(MODAL_CARGA_ARCHIVO);
    };

    const leerArchivoCsv = (archivo) => {
        return new Promise((resolve, reject) => {
            let noRegistros = 0;
            let colDatoValidar = columnaDatoValidar;
            const papaparseConfig = {
                worker: true,
                skipEmptyLines: true,
                step: (results, parser) => {
                    if (noRegistros === 0) {
                        for (let columnIndex = 0; columnIndex < results.data.length; columnIndex++) {
                            let cellValue = results.data[columnIndex];
                            if (cellValue && cellValue !== "") {
                                cellValue = cellValue.trim
                                    ? cellValue.trim()
                                    : cellValue;
                                cellValue = cellValue.toUpperCase
                                    ? cellValue.toUpperCase()
                                    : cellValue;
                                if (tipoValidacion.id == TIPO_VALIDACION_CORREO.id) {
                                    if (CABECERAS_CORREO.indexOf(cellValue) >= 0) {
                                        colDatoValidar = columnIndex + 1;
                                    }
                                } else if (tipoValidacion.id == TIPO_VALIDACION_TELEFONO.id) {
                                    if (CABECERAS_TELEFONO.indexOf(cellValue) >= 0) {
                                        colDatoValidar = columnIndex + 1;
                                    }
                                }
                            }
                        }
                    }

                    if (colDatoValidar < 0) {
                        for (let columnIndex = 0; columnIndex <= results.data.length; columnIndex++) {
                            let cellValue = results.data[columnIndex];
                            if (cellValue && cellValue !== "") {
                                cellValue = cellValue.trim
                                    ? cellValue.trim()
                                    : cellValue;
                                if (tipoValidacion.id == TIPO_VALIDACION_CORREO.id) {
                                    if (esDatoCorreo(cellValue)) {
                                        colDatoValidar = columnIndex + 1;
                                    }
                                } else if (tipoValidacion.id == TIPO_VALIDACION_TELEFONO.id) {
                                    if (esDatoTelefono(cellValue)) {
                                        colDatoValidar = columnIndex + 1;
                                    }
                                }
                            }
                        }
                    }
                    noRegistros++;
                },
                complete: (results) => {
                    resolve({ noRegistros, colDatoValidar });
                },
                error: (err, file) => {
                    reject(err);
                },
            }
            Papaparse.parse(archivo, papaparseConfig);

        })
    }

    const leerArchivoXlsx = (archivo) => {
        return new Promise((resolve, reject) => {
            try {
                const wb = new ExcelJs.Workbook();
                const reader = new FileReader();
                reader.readAsArrayBuffer(archivo)
                reader.onload = () => {
                    const buffer = reader.result;
                    wb.xlsx.load(buffer).then(workbook => {
                        const worksheet = workbook.getWorksheet(1);
                        const headerRow = worksheet.getRow(1);
                        let colDatoValidar = -1;

                        headerRow.eachCell((cell, colNumber) => {
                            let cellValue = cell.value;
                            if (cellValue && cellValue !== "") {
                                cellValue = cellValue.trim
                                    ? cellValue.trim()
                                    : cellValue;
                                cellValue = cellValue.toUpperCase
                                    ? cellValue.toUpperCase()
                                    : cellValue;
                                if (tipoValidacion.id == TIPO_VALIDACION_CORREO.id) {
                                    if (CABECERAS_CORREO.indexOf(cellValue) >= 0) {
                                        colDatoValidar = colNumber;
                                    }
                                } else if (tipoValidacion.id == TIPO_VALIDACION_TELEFONO.id) {
                                    if (CABECERAS_TELEFONO.indexOf(cellValue) >= 0) {
                                        colDatoValidar = colNumber;
                                    }
                                }
                            }
                        });

                        if (colDatoValidar < 0) {
                            let cellData;
                            worksheet.eachRow((row, rowNumber) => {
                                if (colDatoValidar > 0) {
                                    return;
                                }
                                row.eachCell((cell, colNumber) => {
                                    excelJsExtras.hasTextProperty(cell.value)
                                        ? cellData = cell.value.text
                                        : cellData = cell.value

                                    if (tipoValidacion.id == TIPO_VALIDACION_CORREO.id) {
                                        if (esDatoCorreo(cellData)) {
                                            colDatoValidar = colNumber;
                                        }
                                    } else if (tipoValidacion.id == TIPO_VALIDACION_TELEFONO.id) {
                                        if (esDatoTelefono(cellData)) {
                                            colDatoValidar = colNumber;
                                        }
                                    }
                                });
                            });


                        }
                        resolve({ noRegistros: worksheet.actualRowCount, colDatoValidar });
                    });
                }
            } catch (e) { reject(e) }
        })
    }

    const esDatoCorreo = (dato) => {
        // eslint-disable-next-line no-useless-escape
        let formato = /^(?:(?:[\w`%&*\-=+:'?\/]+(?:(?:\.(?:"(?:\\?[\w`%&*\-=+:'?\/\.()<>\[\] @]|\\"|\\\\)*"|[\w`%&*\-=+:'?\/]+))*\.[\w`%&*\-=+:'?\/]+)?)|(?:"(?:\\?[\w`%&*\-=+:'?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/
        return formato.test(dato);
    }
    const esDatoTelefono = (dato) => {
        // eslint-disable-next-line no-useless-escape
        const formato = /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}|[(]?[0-9]{2}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/;

        if (formato.test(dato)) {
            return true;
        } else {
            return false;
        }
    }

    const iniciarCreacionValidacion = async () => {
        setModalActual(MODAL_CARGANDO_ARCHIVO);
        if (await subirArchivo()) {
            setModalActual(MODAL_VALIDACION_CREADA);
        };
    }

    const mostrarProcesoFallido = (razon, detalle) => {
        setRazonProcesoFallido(razon);
        setDetalleProcesoFallido(detalle);
        setModalActual(MODAL_PROCESO_FALLIDO);
        return false;
    }

    const subirArchivo = async () => {
        const validacionWebArchivo = functions.httpsCallable('validacionWebArchivo');
        const datos = {
            "tipoValidacion": tipoValidacion.id,
            nombreValidacion,
            totalRegistros,
            nombreOriginal,
            eliminarDuplicados,
            prevalidarHistorico,
            columnaDatoValidar
        }
        const respuestaValidacionWebArchivo = await validacionWebArchivo(datos);
        console.log(respuestaValidacionWebArchivo.data)
        if (!respuestaValidacionWebArchivo.data.exitoso) {
            switch (respuestaValidacionWebArchivo.data.razon) {
                case "Saldo insuficiente":
                    return mostrarProcesoFallido(
                        respuestaValidacionWebArchivo.data.razon,
                        "Solo dispone de "
                        + respuestaValidacionWebArchivo.data.saldo
                        + " validaciones, requiere de "
                        + totalRegistros +
                        " validaciones para continuar, por favor contacte a su DN para aumentar su cuota."
                    );
                    break;
                case "Cuenta inactiva":
                    return mostrarProcesoFallido(
                        respuestaValidacionWebArchivo.data.razon,
                        "Esta cuenta está descativada, contacte a su DN para reactivarla."
                    );
                    break;
            }

        }
        const { validacionArchivo } = respuestaValidacionWebArchivo.data;

        trigger.nuevo('nueva validacion archivo');
        const validacionStorageRef = storage.ref(validacionArchivo.ruta_archivos);
        let archivoRef;
        if (tipoValidacion.id === TIPO_VALIDACION_CORREO.id) {
            archivoRef = validacionStorageRef.child("archivoOriginal." + tipoArchivo);
        } else {
            archivoRef = validacionStorageRef.child("archivoOriginalTelefono." + tipoArchivo);
        }
        // const archivoRef = validacionStorageRef.child("archivoOriginal." + tipoArchivo);
        const cargarArchivoStorage = archivoRef.put(archivo);

        const idProcesoArchivo = generarID();
        const nuevoArrayProcesosArchivos = [...estadoGlobal.procesosArchivos];
        nuevoArrayProcesosArchivos.push({
            id: idProcesoArchivo,
            validacion_id: validacionArchivo.validacion_id,
            type: 'validacion_archivo',
            state: 'uploading'
        });
        setEstadoGlobal({procesosArchivos: nuevoArrayProcesosArchivos});
        
        setColorProgressBar(COLOR_SUBIDA_ARCHIVO_INICIADO);

        cargarArchivoStorage.on('state_changed', function (snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            setProgresoSubidaArchivo(Math.round(progress));
        }, function (error) {
            let nuevoArrayProcesosArchivos = [...estadoGlobal.procesosArchivos];
            nuevoArrayProcesosArchivos = nuevoArrayProcesosArchivos.map((procesoArchivo) => {
                if(procesoArchivo.id === idProcesoArchivo) {
                    procesoArchivo.state = 'error';
                }
                return procesoArchivo;
            })
            setEstadoGlobal({procesosArchivos: nuevoArrayProcesosArchivos});

            setColorProgressBar(COLOR_SUBIDA_ARCHIVO_ERROR);
            console.log('Error al subir archivo: ', validacionArchivo);
        }, function () {
            let nuevoArrayProcesosArchivos = [...estadoGlobal.procesosArchivos];
            nuevoArrayProcesosArchivos = nuevoArrayProcesosArchivos.map((procesoArchivo) => {
                if(procesoArchivo.id === idProcesoArchivo) {
                    procesoArchivo.state = 'uploaded';
                }
                return procesoArchivo;
            })
            setEstadoGlobal({procesosArchivos: nuevoArrayProcesosArchivos});

            console.log("Archivo subido correctamente", validacionArchivo.validacion_id);
            setColorProgressBar(COLOR_SUBIDA_ARCHIVO_FINALIZADO);
        });

        // console.log(respuestaValidacionWebArchivo);

        return true;
    }

    const cancelarCargaArchivo = () => {
        if(progresoSubidaArchivo !== 100 
            && modalActual === MODAL_VALIDACION_CREADA
        ) {
            confirmDialog({
                header: 'Algunos archivos aun se estan subiendo',
                message: 'Por favor espera a que este proceso termine',
                icon: 'pi pi-exclamation-triangle',
                rejectClassName: 'p-d-none',
                acceptLabel: 'Esperar',
            });
        } else {
            props.mostrarModalProceso(false);
            setModalActual(false);
            setNombreValidacion("");
            setArchivo(false);
            setEliminarDuplicados(false);
            setPrevalidarHistorico(false);
        }
    }

    const descargarArchivoEjemplo = () => {
        const datosCorreo = {
            "fields": ["Correo", "dato extra"],
            "data": [
                ["correo1@ejemplo.com", "dato extra"],
                ["correo2@ejemplo.com", "dato extra"],
                ["correo3@ejemplo.com", "dato extra"],
                ["correo4@ejemplo.com", "dato extra"],
                ["correo5@ejemplo.com", "dato extra"],
                ["correo6@ejemplo.com", "dato extra"],
            ]
        };

        const datosTelefono = {
            "fields": ["Teléfono", "dato extra"],
            "data": [
                ["2235455565", "dato extra"],
                ["2442399776", "dato extra"],
                ["5512345678", "dato extra"],
                ["5532101234", "dato extra"],
                ["5622335566", "dato extra"],
                ["6412345678", "dato extra"],
            ]
        };

        const csv = Papaparse.unparse(tipoValidacion.id === TIPO_VALIDACION_CORREO.id 
            ? datosCorreo
            : datosTelefono
        );

        const nombreArchivo = `ejemplo_validar_${tipoValidacion?.nombre}s.csv`;

        const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const csvURL =  navigator?.msSaveBlob
        ? navigator.msSaveBlob(csvData, nombreArchivo)
        : window.URL.createObjectURL(csvData);
    
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', nombreArchivo);
        tempLink.click();
    }

    /** Modales */

    const modalSeleccionTipo = (<>
        <NeoDialog
            visible={modalActual === MODAL_SELECCION_TIPO}
            onHide={() => cancelarCargaArchivo()}
            header="Tipo de validación a realizar"
        >
            <NeoFlexContainer>
                <NeoButtonImage
                    label="Correos electrónicos"
                    image={correo}
                    onClick={() => {
                        setTipoValidacion(TIPO_VALIDACION_CORREO);
                        setModalActual(MODAL_CONFIGURACION);
                    }}
                />
                
                <NeoButtonImage
                    label="Teléfonos celulares"
                    image={telefono}
                    onClick={() => {
                        setTipoValidacion(TIPO_VALIDACION_TELEFONO);
                        // setModalActual(MODAL_EN_CONSTRUCCION)
                        setModalActual(MODAL_CONFIGURACION);
                    }}
                />
            </NeoFlexContainer>
        </NeoDialog>
    </>)

    const pieEnConstruccion = (<>
        <NeoButtonMain label="Entendido" onClick={() => { cancelarCargaArchivo() }} />
    </>)

    const modalEnConstruccion = (<>
        <NeoDialog
            visible={modalActual === MODAL_EN_CONSTRUCCION}
            onHide={() => cancelarCargaArchivo()}
            header="Funcionalidad en construcción"
            footer={pieEnConstruccion}
            extra="dialog-s"
        >
            <p>Esta funcionalidad se encuentra en construcción, por favor espera una siguiente versión.</p>
        </NeoDialog>
    </>)

    const pieConfiguracionValidacion = (<>
        {/* <NeoButtonMain
            label="Regresar a la selección"
            onClick={() => {
                setTipoValidacion({});
                setArchivo();
                setModalActual(MODAL_SELECCION_TIPO);
            }}
        /> */}
        {
            archivo
                && <>
                    <NeoButtonMain
                        label="Volver a cargar Archivo"
                        onClick={() => { volverCargarArchivo() }}
                    />
                    <NeoButtonMain
                        label="Volver a resumen"
                        onClick={() => { setModalActual(MODAL_VISTA_PREVIA) }}
                    />
                </>
        }
        {
            tipoValidacion?.id && !archivo
                && <>
                    <NeoButtonMain
                        label="Cargar archivo"
                        onClick={() => { setModalActual(MODAL_CARGA_ARCHIVO) }}
                    />
                </>
        }
    </>);

    const modalConfiguracion = (<>
        <NeoDialog
            visible={modalActual === MODAL_CONFIGURACION}
            onHide={() => cancelarCargaArchivo()}
            header="Validacion por archivo"
            footer={pieConfiguracionValidacion}
        >

                <NeoButtonSelect
                    value={tipoValidacion}
                    options={[
                        {label: 'Correo', value: TIPO_VALIDACION_CORREO},
                        {label: 'Teléfono', value: TIPO_VALIDACION_TELEFONO}
                    ]}
                    onChange={(seleccionado) => {
                        if(seleccionado.value) {
                            setTipoValidacion(seleccionado.value);
                        }
                    }}
                    extra="seccion-botones p-d-flex"
                />

            <NeoInputText
                id="nombreCarga"
                label="Nombre de la carga"
                value={nombreValidacion}
                onChange={(e) => setNombreValidacion(e.target.value)}
            />

            {tipoValidacion?.id &&
                <NeoColumn col="12">
                    <NeoGridContainer>
                        <NeoInnerSubtitle>
                        {
                            tipoValidacion.id === TIPO_VALIDACION_CORREO.id
                                ? 'Correos duplicados en este archivo'
                                : 'Teléfonos duplicados en este archivo'
                        }
                        </NeoInnerSubtitle>
                    </NeoGridContainer>
                    <NeoGridContainer>
                        <NeoCheckbox
                            col="11"
                            id="EliminarDuplicados"
                            label="Eliminar los duplicados en los resultados"
                            value={eliminarDuplicados}
                            onChange={e => setEliminarDuplicados(e.checked)}
                        />
                        <NeoColumn col="1">
                            <NeoTooltip_icon
                                extra="p-p-0"
                                position="right"
                                text="Si está activo: sólo se muestra un resultado aunque aparezca varias veces. Si está inactivo: El resultado aparecerá donde el registro esté duplicado."
                            />
                        </NeoColumn>
                    </NeoGridContainer>

                    <NeoGridContainer>
                        {
                            tipoValidacion.id === TIPO_VALIDACION_CORREO.id
                                ? <NeoInnerSubtitle>Correos encontrados en validaciones previas</NeoInnerSubtitle>
                                : <NeoInnerSubtitle>Teléfonos encontrados en validaciones previas</NeoInnerSubtitle>
                        }
                    </NeoGridContainer>
                    <NeoGridContainer>
                        <NeoRadioButton
                            extra="p-mb-0"
                            inputId="revalidarDuplicadosHistoricos"
                            label="Prevalidar"
                            value={VALIDACIONES_ARCHIVO_PREVALIDAR_HISTORICO}
                            checked={prevalidarHistorico}
                            onChange={e => setPrevalidarHistorico(e.value)}
                        />
                        <NeoColumn col="1">
                            <NeoTooltip_icon
                                extra="p-p-0"
                                position="right"
                                text="Se usará el último resultado validado."
                            />
                        </NeoColumn>
                    </NeoGridContainer>

                    <NeoGridContainer>
                        <NeoRadioButton
                            extra="p-mb-0"
                            inputId="revalidarDuplicadosHistoricos"
                            label="Validar todo"
                            value={!VALIDACIONES_ARCHIVO_PREVALIDAR_HISTORICO}
                            checked={!prevalidarHistorico}
                            onChange={e => setPrevalidarHistorico(e.value)} />
                        <NeoColumn col="1">
                            <NeoTooltip_icon
                                extra="p-p-0"
                                position="right"
                                text="Se validarán todos los registros sin importar el histórico."
                            />
                        </NeoColumn>
                    </NeoGridContainer>
                </NeoColumn>
            }
        </NeoDialog>
    </>)

    const pieCargaArchivo = (<>
        <NeoButtonMain label="Regresar a la configuración" onClick={() => { setModalActual(MODAL_CONFIGURACION) }} />
        <NeoButtonOutlined label="Descargar ejemplo" onClick={descargarArchivoEjemplo} />
    </>);


    const modalCargarArchivo = (<>
        <NeoDialog
            visible={modalActual === MODAL_CARGA_ARCHIVO}
            onHide={() => cancelarCargaArchivo()}
            header={`Selecciona tu archivo de ${capitalizar(tipoValidacion?.nombre)}s`}
            extra="dialog-s"
            footer={pieCargaArchivo}
        >
            <NeoInputFile id="nombreArchivo"
                accept=".csv,.xlsx"
                maxFileSize={200000000}
                customUpload
                uploadHandler={obtenerVistaPrevia}
                name="nombreArchivo"
                multiple={false}
                emptyTemplate={<p>O arrastra aquí tu archivo.</p>}
                chooseLabel={"Seleccionar archivo"}
                auto

            />
            <NeoNote text="En caso de que el archivo tenga otra información, validaremos la primer columna." />
        </NeoDialog>
    </>)

    const pieVistaPrevia = (<>
        <NeoButtonMain label="Regresar a configuración" onClick={() => { setModalActual(MODAL_CONFIGURACION) }} />
        <NeoButtonMain label="Iniciar validación" onClick={() => { iniciarCreacionValidacion() }} />
    </>);

    const modalVistaPrevia = (<>
        <NeoDialog
            visible={modalActual === MODAL_VISTA_PREVIA}
            onHide={() => cancelarCargaArchivo()}
            header="Resumen de validación"
            footer={pieVistaPrevia}
        >

            <NeoGridContainer>
                <NeoColumn col="auto">
                    <NeoDataSecond fact={nombreValidacion} label="Nombre de proceso" />
                </NeoColumn>
                {/* <NeoColumn col="auto">
                    <NeoDataSecond fact={nombreOriginal} label="Archivo original" />
                </NeoColumn> */}

                <NeoColumn col="6">
                    <NeoDataSecond fact={String.fromCharCode(64 + columnaDatoValidar)} label="Columna a validar" />
                </NeoColumn>
                <NeoColumn col="6">
                    <NeoDataSecond fact={totalRegistros} label="Validaciones estimadas a usar" />
                </NeoColumn>
            </NeoGridContainer>

            <NeoInnerSubtitle>Configuración de trabajo</NeoInnerSubtitle>
        <NeoList>
            {eliminarDuplicados && <NeoListItem>Eliminar los duplicados en los resultados</NeoListItem>}
            {prevalidarHistorico && <NeoListItem>Prevalidar contra histórico</NeoListItem>}
            {!prevalidarHistorico && <NeoListItem>Validar todos los registros</NeoListItem>}
            {
                tipoValidacion.id === TIPO_VALIDACION_CORREO.id
                    ? <NeoListItem>Validar correos electrónicos</NeoListItem>
                    : <NeoListItem>Validar teléfonos celulares</NeoListItem>
            }
            {/*validarExistencia && <NeoListItem>Validación de existencia (1 validación/correo)</NeoListItem>*/}
        </NeoList>
    </NeoDialog></>)

    const pieValidacionIniciada = (<>
        {progresoSubidaArchivo === 100 &&
            <NeoButtonMain label="Cerrar" onClick={() => { cancelarCargaArchivo() }} />
        }
    </>);

    const modalValidacionIniciada = (<>
        <NeoDialog
            visible={modalActual === MODAL_VALIDACION_CREADA}
            // visible={true}
            onHide={() => cancelarCargaArchivo()}
            header="Subiendo archivo"
            footer={pieValidacionIniciada}
            extra="dialog-s"
        >
            <p>La solicitud de validaciones fue creada exitosamente y estamos procesándola en este momento. Puedes consultar su avance en tiempo real en la sección de Validación de correos. Te enviaremos un correo electrónico cuando el proceso concluya.</p>
            {progresoSubidaArchivo === 100 
                ? <p>¡Archivo subido!</p>
                : <p>Subiendo archivo...</p>
            }
            <ProgressBar value={progresoSubidaArchivo} color={colorProgressBar} />
        </NeoDialog>
    </>)

    const pieProcesoFallido = (<>
        <NeoButtonMain label="Regresar" onClick={() => { setModalActual(MODAL_CARGA_ARCHIVO); }} />
        <NeoButtonMain label="Entendido" onClick={() => { cancelarCargaArchivo() }} />
    </>)

    const modalProcesoFallido = (<>
        <NeoDialog
            visible={modalActual === MODAL_PROCESO_FALLIDO}
            onHide={() => cancelarCargaArchivo()}
            header="Ocurrio un problema en su proceso"
            footer={pieProcesoFallido}
            extra="dialog-s"
        >
            <NeoInnerSubtitle>{razonProcesoFallido}</NeoInnerSubtitle>
            <p>{detalleProcesoFallido}</p>
        </NeoDialog>
    </>)


    return (<>

        {modalEnConstruccion}
        {modalSeleccionTipo}
        {modalConfiguracion}
        {modalCargarArchivo}
        {modalVistaPrevia}
        {modalValidacionIniciada}
        {modalActual === MODAL_CARGANDO_ARCHIVO && <NeoSpinner />}
        {modalProcesoFallido}

    </>);
}

export default ModalValidacionArchivo