import React, { useEffect, useContext } from 'react';
import { functions } from "config/firebase";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoCard from "../../common/neo/panel/NeoCard.base";
import NeoColumn from "../../common/neo/layout/NeoColumn.base";
import NeoTitleSecond from '../../common/neo/title/NeoTitleSecond.base';
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import DetalleOrden from "components/common/DetalleOrden";
import TablaDetalleOrden from 'components/common/TablaDetalleOrden';
import BotonPagarOrden from 'components/common/BotonPagarOrden';
import { useComponent } from 'hooks';


const obtenerDetallesOrden = functions.httpsCallable('ordenes-listarDetalles');
const obtieneCuentaClabeSTP = functions.httpsCallable('pago-obtieneCuentaClabeSTP');
const checkoutTDC = functions.httpsCallable('pagos-checkoutStripe');


const OrdenDetalle = (props) => {
    const componente = useComponent();
    const [estadoGlobal] = useContext(EstadoGlobal);
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [ordenId, setOrdenId] = componente.useCustomState();
    const [orden, setOrden] = componente.useCustomState();
    const [error, setError] = componente.useCustomState("");
    const [irAtras, setIrAtras] = componente.useCustomState(props.irAtras);

    useEffect(() => {
        if (props?.location?.state?.id) {
            if(props?.location?.state?.irAtras !== undefined) {
                setIrAtras(props?.location?.state?.irAtras);
            }

            setOrdenId(props.location.state.id);
        } else {
            const urlPath = window.location.href;
            var url = new URL(urlPath);
            const id = Number(url.searchParams.get('id'));
            if (id) {
                setOrdenId(id);
                setIrAtras(false);
            }

            const status = url.searchParams.get('status');
            if(status === 'cancel') {
                despliegaMensaje({
                    severity: "warn",
                    summary: "Tu pago no pudo ser procesado:",
                    detail: 'Inténtalo nuevamente'
                });
            }

            window.history.replaceState(null, null, window.location.pathname);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ordenId && obtieneDatos()

    }, [ordenId]); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = () => {
        obtenerDetallesOrden({ ordenId })
            .then((respuesta) => {
                setOrden(respuesta.data);
            }).catch((error) => {
                console.log("Error: ", error);
                setError('No tienes permisos para mostrar los detalles de la orden');
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond icon={Icono} title="Detalle de orden" goback={irAtras} />
        </NeoGridContainer>

        {
            orden
            && <>
                <DetalleOrden
                    orden={orden}
                />
                <TablaDetalleOrden orden={orden} />




                <BotonPagarOrden
                    orden={orden}
                    obtieneCuentaClabeSTP={obtieneCuentaClabeSTP}
                    checkoutTDC={checkoutTDC}
                />
            </>
        }

        {
            error &&
            <NeoCard>
                <NeoColumn col="auto" extra="p-text-center" >
                    <b>{error}</b>
                </NeoColumn>
            </NeoCard>
        }
    </>);

};

OrdenDetalle.defaultProps = {
    irAtras: true
}

export default OrdenDetalle;
