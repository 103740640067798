/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useComponent, useInput } from 'hooks';
import NeoInputText from './neo/form/NeoInputText.base';
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import NeoButtonSecondary from './neo/button/NeoButtonSecondary.base';
import NeoDialog from "./neo/overlay/NeoDialog.base";


const FormularioNombreApiModal = (props) => {
    const componente = useComponent();
    const [nombreApi, setNombreApi] = useInput("");
    const [errores, setErrores] = componente.useCustomState({})
    const [evalTiempoRealForm, setEvalTiempoRealForm] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    const piePaginaModal = (<>
        <NeoButtonSecondary label="Cancelar" className="p-button-text"
            onClick={() => props.setMuestraModal(false)} />
        <NeoButtonMain label="Enviar"
            onClick={() => manejaEnvio()} autoFocus />
    </>);

    useEffect(() => {
        props && props.datosIniciales && props.datosIniciales.nombre
            ? setNombreApi(props.datosIniciales.nombre)
            : setNombreApi()
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps


    // Ejecuta evaluación del formulario en tiempo real
    useEffect(() => {
        if (evalTiempoRealForm)
            evaluaFormulario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombreApi]);


    const evaluaFormulario = () => {
        let esValido = false;
        let mensajeError = "";

        nombreApi && nombreApi.length
            ? esValido = true
            : mensajeError = "Debes escribir el nombre de la API"

        setErrores({ nombreapi: mensajeError });
        return esValido;
    }

    const manejaEnvio = (event) => {
        setEvalTiempoRealForm(true);
        if (!evaluaFormulario())
            return false;

        props
            && props.onSubmit
            && props.onSubmit({ nombre: nombreApi });
        props.setMuestraModal(false);
        setEvalTiempoRealForm(false);
        setNombreApi("")
    };


    return (<>
        <NeoDialog header={props.mensaje}
            visible={props.muestraModal} style={{ width: '50vw' }}
            footer={piePaginaModal}
            onHide={() => props.setMuestraModal(false)} >

            <NeoInputText id="nombreapi" value={nombreApi}
                onChange={setNombreApi} label="Nombre de la API"
                error={errores.nombreapi} />
        </NeoDialog>

    </>)
}


export default FormularioNombreApiModal;