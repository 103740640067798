import { createContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import { functions } from "config/firebase";
import { useComponent } from "hooks";

const UsuarioContext = createContext({});

export const UsuarioProvider = (props) => {
    const componente = useComponent({
        data: {
            loading: true,
            loggins: 0,
            logouts: 0
        }
    });
    
    const auth = firebase.auth();
    const [persona, setPersona] = useState();
    const [usuarioAuth, setUsuarioAuth] = useState(() => {
        return auth.currentUser;
    });

    useEffect(() => {
        auth.onAuthStateChanged(usuarioAuth => {
            if(!usuarioAuth) {
                componente.data.loading = false;
            }

            setUsuarioAuth(usuarioAuth);
        });
    }, [])

    useEffect(() => {
        if(usuarioAuth) {
            componente.data.loggins++;
            cargarDatosPersona();
        } else {
            if(componente.data.loggins > 0) {
                componente.data.logouts++;
                setPersona();
            }
        }
    }, [usuarioAuth])

    const cargarDatosPersona = async () => {
        if(!usuarioAuth) {
            return null;
        }

        const idTokenResult = await usuarioAuth.getIdTokenResult();

        const persona = {};
        persona.uid = usuarioAuth.uid;
        persona.email = usuarioAuth.email;
        persona.emailVerified = usuarioAuth.emailVerified;
        persona.rol = idTokenResult?.claims?.rol || '';

        // Persona es cliente
        if(persona.rol === '' || persona.rol === 'sub cuenta') {
            try {
                const resp = await functions.httpsCallable("getDatosCliente")();
                persona.cliente = resp.data;
                persona.cliente.id = persona.cliente.clienteId;
            } catch(error) {
                console.error("Error al obtener los datos como ciente");
            }

            try {
                const resp = await functions.httpsCallable("obtieneValidacionesPorCliente")();
                persona.cliente.saldo = resp.data;
            } catch(error) {
                console.error("Error al obtener los saldos como ciente");
            }
        }

        setPersona(persona);

        componente.data.loading = false;

        return persona;
    }

    return (
        <UsuarioContext.Provider value={{
            data: componente.data,
            auth,
            usuarioAuth,
            persona,
            cargarDatosPersona
        }}>
            {props.children}
        </UsuarioContext.Provider>
    )
}

export default UsuarioContext;