import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solid from "@fortawesome/free-regular-svg-icons";

const BaseModal = (props) => {

    const onClickBtnCerrar = () => {
        if (typeof props.onClickBtnCerrar === 'function') {
            props.onClickBtnCerrar();
        }
    }

    return (
        <div className={`BaseModal ${props.extra ? props.extra : ""} ${props.visible ? 'show' : ''}`}>
            <div className="body">
                <div className="header">
                    <div className="title">
                        <h2>{props.title}</h2>
                    </div>
                    <div className="btn-cerrar">
                        <FontAwesomeIcon
                            icon={solid.faWindowClose}
                            onClick={onClickBtnCerrar} />
                    </div>
                </div>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

BaseModal.defaultProps = {
    visible: false
}

export default BaseModal;