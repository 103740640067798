import React from "react";
import BaseButtonSection from "../../sisu/layout/BaseButtonSection.sisu";

const NeoButtonSection = (props) => {
  return (<BaseButtonSection custom={`seccion-botones ${props.flex ? 'p-d-flex' : ''} p-col`} {...props}/>);
};

NeoButtonSection.defaultProps = {
    flex: true
}

export default NeoButtonSection;
