import { useEffect, useContext } from 'react';
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from '../../common/neo/title/NeoTitleSecond.base';
import Icono from 'design/assets/img/mnv/icons/icon-user.svg';
import FormularioContrato from "components/common/FormularioContrato";
import { useComponent } from 'hooks';
import { DateTime } from 'luxon';
import useCliente from 'hooks/useCliente';

const ClientesContratoNuevo = () => {
    const componente = useComponent();
    const cliente = useCliente();
    const [, setEstadoGlobal] = useContext(EstadoGlobal);
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState();
    const [tipoValidaciones, setTipoValidaciones] = componente.useCustomState([])
    let historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneTipoValidaciones();
        obtieneDatos();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tipoValidaciones.length && obtieneDatos()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoValidaciones]);

    const obtieneDatos = () => {
        setDatos({
            inicio: "",
            mesesVigencia: 12,
            productos: tipoValidaciones
        });
    }

    const obtieneTipoValidaciones = () => {
        const getTipoValidaciones = functions.httpsCallable("getTipoValidaciones");

        getTipoValidaciones().then((resp) => {
            setTipoValidaciones(resp.data);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const guardaDatos = async (datos) => {
        const guardaContrato = functions.httpsCallable("contratos-creaContrato");

        guardaContrato({ ...datos, clienteId: Number(cliente.id) })
            .then(() => {
                despliegaMensaje({
                    severity: "success", summary: "",
                    detail: "Los datos han sido guardados",
                });

                const ahora = DateTime.now();
                if (datos.fechaInicio === ahora.toFormat('yyyy-MM')) {
                    cliente.recargar();
                }

                historial.goBack();
            })
            .catch((error) => {
                console.log(`${error}, (${error.code})`);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond icon={Icono}
                title="Nuevo contrato" />
            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
            </NeoButtonSection>
        </NeoGridContainer>

        <FormularioContrato datosIniciales={datos} onSubmit={guardaDatos}
            listaProductos={tipoValidaciones} />
    </>);

};


export default ClientesContratoNuevo;