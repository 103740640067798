import { useEffect, useState } from "react";
import Logo from '../../../../design/assets/img/common/logo-bw.svg';
import { useLocation, useHistory, Link } from "react-router-dom";
import BaseNavBar from "../../sisu/menu/BaseNavBar.sisu";

const NeoNavBar = (props) => {
    const history = useHistory();
    const location = useLocation()
    const logoImg = <Link to="/"><img alt="logo" src={Logo} height="62" className="p-mr-2" /></Link>;
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (Array.isArray(props.items)) {
            const items = mapItems(props.items);
            setItems(items);
        }
    }, [props.items, location])

    const mapItems = (items, parentItem) => {
        if (Array.isArray(items)) {
            const pathname = location.pathname;

            return items.map((item, index) => {
                const newItem = { ...item };

                // Añade la clase active si la url es la establecida
                if (typeof item.redirect === 'string') {
                    if (item.redirect === pathname) {
                        if (typeof item.className === 'string') {
                            newItem.className.concat(' p-menuitem-active');
                        } else {
                            newItem.className = 'p-menuitem-active';
                        }

                        if (parentItem) {
                            if (typeof parentItem.className === 'string') {
                                parentItem.className.concat('p-parent-active');
                            } else {
                                parentItem.className = 'p-parent-active';
                            }
                        }
                    }

                    // Mapea el atributo redirect a la función command
                    if (typeof item.command === 'function') {
                        newItem.command = () => {
                            item.command();
                            history.push(item.redirect);
                        }
                    } else {
                        newItem.command = () => {
                            history.push(item.redirect);
                        }
                    }
                }

                // Ejecuta recursivamente la función para los submenus
                if (Array.isArray(item.items)) {
                    newItem.items = mapItems(item.items, newItem);
                }

                return newItem;
            });
        }
    }

    return (<>
        <BaseNavBar {...props} start={props.displayLogo ? logoImg : null} model={items} />
        {props.children}
    </>);
};

NeoNavBar.defaultProps = {
    displayLogo: true
}

export default NeoNavBar;
