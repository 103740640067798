/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useComponent, useInput } from 'hooks';
import NeoInnerTitle from './neo/title/NeoInnerTitle.base';
import NeoInputText from './neo/form/NeoInputText.base';
import NeoButtonMain from './neo/button/NeoButtonMain.base';
import NeoNote from './neo/data/NeoNote.base';
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoButtonSection from "./neo/layout/NeoButtonSection.base";


const FormularioContrasenaOlvidada = (props) => {
  const componente = useComponent();
  const [correo, setCorreo] = useInput("");
  const [errores, setErrores] = componente.useCustomState({})
  const [validar, setValidar] = componente.useCustomState(false);

  useEffect(() => {
    return () => { componente.desmontar(); };
  }, []);

  useEffect(() => {
    if (validar)
      validaFormulario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correo]);

  const validaCorreo = () => {
    const formato = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let esValido = true;
    let mensaje = null;

    if (!formato.test(correo)) {
      mensaje = "El correo no es válido";
      esValido = false;
    }

    setErrores(prevState => ({ ...prevState, correo: mensaje }));
    return esValido;
  };

  const validaFormulario = () => {
    let esValido = true;
    setValidar(true);

    if (validaCorreo() === false)
      esValido = false;

    return esValido;
  }

  const manejaEnvio = (e) => {
    if (!validaFormulario()) {
      return false;
    }

    props && props.onSubmit && props.onSubmit({ "correo": correo });
  };


  return (<>
    <NeoColumn>
      <NeoInnerTitle extra="primero" >Recuperación de cuenta</NeoInnerTitle>


      <NeoInputText type="email" id="correo" value={correo}
        onChange={setCorreo} error={errores.correo}
        label="Correo electrónico" />
      <NeoColumn>
        <NeoNote text="Se te enviará un enlace por correo electrónico para poder cambiar tu contraseña." />
      </NeoColumn>
      <NeoButtonSection>
        <NeoButtonMain label="Enviar" onClick={manejaEnvio} />


      </NeoButtonSection>
    </NeoColumn>
  </>);
}

export default FormularioContrasenaOlvidada;