import Icono from 'design/assets/img/mnv/icons/icon-help.svg';
import NeoColumn from 'components/common/neo/layout/NeoColumn.base';
import NeoCard from 'components/common/neo/panel/NeoCard.base';
import NeoInnerTitle from 'components/common/neo/title/NeoInnerTitle.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../common/neo/title/NeoTitleMain.base";
import NeoInnerSubtitle from "../../common/neo/title/NeoInnerSubtitle.base";
import NeoButtonSecondary from "../../common/neo/button/NeoButtonSecondary.base";
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import NeoDataSecond from "../../common/neo/data/NeoDataSecond.base";
import NeoList from "../../common/neo/data/NeoList.base"
import NeoListItem from "../../common/neo/data/NeoListItem.base"
import NeoButtonSection from "../../common/neo/layout/NeoButtonSection.base";
import SeccionPreguntasFrecuentes from '../../common/SeccionPreguntasFrecuentes';
import { useHistory } from "react-router-dom";
import RUTAS from 'components/router/Rutas.json';
import { useUsuario } from 'hooks';
import { abrirCorreo, abrirWhatsApp, abrirTelefono, abrirUrl } from 'consts/generales';

const Ayuda = (props) => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    const history = useHistory();

    return (<>
        <NeoGridContainer>
            <NeoTitleMain col="12"
                title="Ayuda" icon={Icono} />
        </NeoGridContainer>

        <NeoGridContainer>
            <NeoColumn md="4">
                <NeoCard>
                    <NeoInnerTitle>Contacta a tu Desarrollador de Negocios</NeoInnerTitle>
                    {/* {persona?.cliente?.dn?.nombre} */}
                    <NeoColumn col="12 p-text-center">
                        <NeoDataSecond id="planPago"
                            fact={persona?.cliente?.dn?.nombre}
                        />
                    </NeoColumn>
                    <NeoColumn col="12 p-text-center">
                        <NeoButtonSecondary
                            label={persona?.cliente?.dn?.correo}
                            icon="pi pi-envelope"
                            className="p-button-rounded"
                            onClick={(event) => {
                                abrirCorreo(persona?.cliente?.dn?.correo);
                                event.preventDefault();
                            }}
                        />
                    </NeoColumn>
                    <NeoColumn col="12 p-text-center">
                        <NeoButtonSecondary
                            label={persona?.cliente?.dn?.telefono}
                            icon="pi pi-whatsapp"
                            className="p-button-rounded"
                            onClick={(event) => {
                                abrirWhatsApp(persona?.cliente?.dn?.telefono, `¡Hola ${persona?.cliente?.dn?.nombre}!`);
                                event.preventDefault();
                            }}
                        />
                    </NeoColumn>
                    <NeoColumn col="12 p-mt-3">
                        <NeoInnerSubtitle>Te apoyará en temas de:</NeoInnerSubtitle>
                        <NeoList>
                            <NeoListItem>Estrategia</NeoListItem>
                            <NeoListItem>Integraciones</NeoListItem>
                            <NeoListItem>Contrato</NeoListItem>
                            <NeoListItem>Cotizaciones</NeoListItem>
                            <NeoListItem>Renovaciones</NeoListItem>
                            <NeoListItem>Facturación</NeoListItem>
                        </NeoList>
                    </NeoColumn>
                </NeoCard>
            </NeoColumn>
            <NeoColumn md="4">
                <NeoCard>
                    <NeoInnerTitle>Crear Ticket para Soluciones</NeoInnerTitle>
                    <NeoColumn col="12 p-text-center">
                        <NeoButtonSecondary
                            label="https://soporte.neored.com"
                            icon="pi pi-globe"
                            className="p-button-rounded"
                            onClick={(event) => {
                                abrirUrl("https://soporte.neored.com");
                                event.preventDefault();
                            }}
                        />
                    </NeoColumn>
                    <NeoColumn col="12 p-text-center">
                        <NeoButtonSecondary
                            label="soluciones@neored.com.mx"
                            icon="pi pi-envelope"
                            className="p-button-rounded"
                            onClick={(event) => {
                                abrirCorreo("soluciones@neored.com.mx");
                                event.preventDefault();
                            }}
                        />
                    </NeoColumn>
                    <NeoColumn col="12 p-text-center">
                        <NeoButtonSection flex={false}>
                            <NeoButtonMain
                                label='5533301515'
                                subtitle="Opción 3" 
                                icon="pi pi-phone"
                                className="p-button-rounded"
                                onClick={(event) => {
                                    abrirTelefono('5533301515');
                                    event.preventDefault();
                                }}
                            />
                        </NeoButtonSection>
                    </NeoColumn>
                </NeoCard>
            </NeoColumn>
            <NeoColumn md="4">
                <NeoCard>
                    <NeoInnerTitle extra="cursor-pointer" onClick={() => {
                        history.push(RUTAS.clientes.preguntasFrecuentes);
                    }}>Preguntas frecuentes</NeoInnerTitle>
                    <SeccionPreguntasFrecuentes />
                </NeoCard>
            </NeoColumn>
        </NeoGridContainer>        
    </>)
}

export default Ayuda;