import { TabView } from 'primereact/tabview';

const BaseTabView = (props) => {
    return (
        <TabView {...props}>
            {props.children}
        </TabView>
    );
}

export default BaseTabView;