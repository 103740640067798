import { SelectButton } from 'primereact/selectbutton';


const BaseButtonSelect = (props) => {
    return (
        <SelectButton
            className={`
            ${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}
            ${props.alternative
                    ? `p-button-${props.alternative}`
                    : "p-button-primary"}`}
            {...props} />
    );
};

export default BaseButtonSelect;