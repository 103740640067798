import React, { useEffect, useState } from 'react';
import BaseInputPassword from '../../sisu/form/BaseInputPassword.sisu';

const NeoInputPassword = (props) => {
    const [style, setStyle] = useState("")
    const [mediumRegex, setMediumRegex] = useState();
    const [strongRegex, setStrongRegex] = useState();
    const feedback = props.noFeedback ? false : true;
    const [tooltipMessage, setTooltipMessage] = useState("");

    useEffect(() => {
        props.tooltipMessage
            ? setTooltipMessage(props.tooltipMessage)
            : setTooltipMessage("Tu contraseña debe ser: mayor a 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un caracter especial")
    }, []);

    useEffect(() => {
        let mount = true
        if (mount) {
            if (props.error) {
                setStyle(`${props.className} p-invalid p-mr-2`);
            } else {
                setStyle(props.className)
            }
        }
        return () => {
            mount = false
        }

    }, [props.error, props.className])

    useEffect(() => {
        props.mediumRegex && setMediumRegex(props.mediumRegex)

    }, [props.mediumRegex])

    useEffect(() => {
        props.strongRegex && setStrongRegex(props.strongRegex)

    }, [props.strongRegex])



    return (<>
        <div className={`p-field p-col
                ${props.extra ? props.extra : ""}
                ${props.col ? `p-col-${props.col}` : `p-col-12`}
                ${props.md ? `p-md-${props.md}` : ""}
                ${props.sm ? `p-sm-${props.sm}` : ""}`}>

            <span className="p-float-label">
                <BaseInputPassword
                    onKeyDown={props.onKeyDown}
                    id={props.id}
                    aria-describedby={`${props.id}-help`}
                    value={props.value}
                    onChange={props.onChange}
                    className={style}
                    feedback={feedback}
                    promptLabel={tooltipMessage}
                    weakLabel={`La contraseña es débil. ${tooltipMessage}`}
                    mediumLabel="La complejidad de la contraseña es suficiente"
                    strongLabel="Contraseña fuerte"
                    mediumRegex={mediumRegex}
                    strongRegex={strongRegex}
                    maxLength={props.maxLength}
                />
                <label htmlFor={props.id}>{props.label}</label>
                {
                    props.error
                    && <small className="p-invalid p-d-block">
                        {props.error}
                    </small>
                }
                {props.children}
            </span>
        </div>
    </>);
};

export default NeoInputPassword;
