import { useRef, useEffect } from "react";
import useUsuario from 'hooks/useUsuario';
import { useHistory } from "react-router-dom";
import FormularioInicioSesion from "components/common/FormularioInicioSesion"
import NeoSingleContainer from "../common/neo/layout/NeoSingleContainer.base";
import NeoLink from "../common/neo/button/NeoLink.base";
import NeoColumn from "../common/neo/layout/NeoColumn.base";
import NeoGridContainer from "../common/neo/layout/NeoGridContainer.base";
import NeoMessages from "../common/neo/messages/NeoMessages.base";
import NeoCard from "../common/neo/panel/NeoCard.base";
import LogoWhite from 'design/assets/img/mnv/logo-bw.svg';
import cliengo from 'config/cliengo';
import NeoInnerTitle from "../common/neo/title/NeoInnerTitle.base";
import NeoButtonOutlined from "../common/neo/button/NeoButtonOutlined.base";
import NeoButtonSecondary from "../common/neo/button/NeoButtonSecondary.base";
import RUTAS from 'components/router/Rutas.json';

const Inicio = () => {
    const usuario = useUsuario();
    const historial = useHistory();
    const mensajeForm = useRef(null);

    useEffect(() => {
        cliengo.show();

        return () => {
            cliengo.hide();
        }
    }, []);

    const iniciaSesion = async (params) => {
        mensajeForm.current && mensajeForm.current.clear();

        try {
            // Intenta iniciar sesión
            await usuario.iniciaSesion({
                correo: params.correo,
                contrasena: params.contrasena
            });

            return true;
        } catch (error) {
            console.log(error);
            mensajeForm.current.show({
                severity: "error", summary: "",
                detail: `Error al iniciar sesión, verifica tu usuario y contraseña`, life: 4000
            });

            return false;
        }
    }

    return (<NeoSingleContainer>
        <NeoGridContainer col="12" subExtra="h-100">
            <NeoColumn col="12" extra="px-0">
                <a href="https://neovalidador.com">
                    <img className="logo-intro" src={LogoWhite} alt="NeoValidador" />
                </a>
            </NeoColumn>


            <NeoColumn md="6" extra="p-md-offset-3 ">

                <NeoCard extra="text-align-center">
                    <FormularioInicioSesion onSubmit={iniciaSesion} />

                    <NeoLink label="¿Olvidaste tu contraseña?"
                        onClick={() => historial.push(RUTAS.contrasenaOlvidada)} />
                    <NeoMessages ref={mensajeForm} />

                </NeoCard>

                <NeoCard>
                    <NeoColumn extra="text-align-center">
                        <NeoInnerTitle extra="primero text-align-center">¿No tienes cuenta en NeoValidador?</NeoInnerTitle>


                        <NeoButtonSecondary
                            extra=""
                            label="Regístrate ahora"
                            onClick={() => historial.push(RUTAS.registro)} /></NeoColumn>
                </NeoCard>
                <NeoColumn extra="text-align-center white">

                    <p>¿Aún no te has migrado a la nueva versión de NeoValidador?</p>
                    <a href="https://neovalidador.com/v1/">
                        <NeoButtonOutlined
                            extra="button-inverted"
                            label="Inicio de sesión en NeoValidador clásico"
                        />
                    </a>

                </NeoColumn>
            </NeoColumn>
        </NeoGridContainer>
    </NeoSingleContainer>);
};


export default Inicio;
