import { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { functions } from "config/firebase";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import EstadisticasGraficas from "components/common/EstadisticasGraficas";
import NeoCardPrincipal from "../../common/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "../../common/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "../../common/neo/panel/NeoCardPrincipal.base";
import NeoColumn from "../../common/neo/layout/NeoColumn.base";
import { useComponent } from 'hooks';


const Estadisticas = () => {
    const componente = useComponent();
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const [hayBienvenida, setHayBienvenida] = componente.useCustomState();
    let location = useLocation();
    let historial = useHistory();

    const [mensualPorMetodo, setMensualPorMetodo] = componente.useCustomState();
    const [mensualPorTipo, setMensualPorTipo] = componente.useCustomState();
    const [mensualPorResultado, setMensualPorResultado] = componente.useCustomState();
    const [mensualPorPrevalidacion, setMensualPorPrevalidacion] = componente.useCustomState();
    const [mensualTotal, setMensualTotal] = componente.useCustomState();
    const [mensualAhorro, setMensualAhorro] = componente.useCustomState();

    const [datosAnuales, setDatosAnuales] = componente.useCustomState();
    const [promedioAnual, setPromedioAnual] = componente.useCustomState();
    const [totalAnual, setTotalAnual] = componente.useCustomState();

    const [mesesDisponibles, setMesesDisponibles] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //Inicia la petición de datos necesarios una vez que el componente
    //  está cargado
    useEffect(() => {
        location.state
            && setHayBienvenida(location.state.bienvenida);

        obtieneMesesDisponibles();
        obtieneResumenAnual();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (mesesDisponibles && !mesesDisponibles.length) {
            setSpinnerCargando(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesesDisponibles]);

    useEffect(() => {
        if (hayBienvenida) {
            location.state = { ...location.state, bienvenida: false };
            // const getNombreUsuario = functions.httpsCallable('getNombreUsuario');

            // getNombreUsuario().then((respuesta) => {
            //     despliegaMensaje({
            //         severity: 'success',
            //         summary: 'Bienvenido',
            //         detail: respuesta.data.nombre,
            //     });
            // }).catch((error) => {
            //     console.log("Error: ", error);
            // })
        }

    }, [hayBienvenida]); // eslint-disable-line react-hooks/exhaustive-deps


    const obtieneMesesDisponibles = () => {
        const fechasConDatosValidaciones = functions.httpsCallable('fechasConDatosValidaciones');
        fechasConDatosValidaciones().then((resp) => {
            if (!resp.data.length) {
                historial.push("/clientes/validaciones");
            }

            if (Array.isArray(resp.data) && resp.data.length) {
                resp.data.unshift({
                    valor: 'anual',
                    nombre: 'Últimos 12 meses'
                });
            }
            setMesesDisponibles(resp.data)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        }).finally(() => {
            setSpinnerCargando(false);
        })
    }

    const obtieneResumenPorMes = (fecha) => {
        const resumen = functions.httpsCallable('resumenMensualPorCliente');

        resumen({ mes: fecha }).then((resp) => {
            setMensualPorMetodo(resp.data.listaPorMetodo)
            setMensualPorTipo(resp.data.listaPorTipo)
            setMensualPorResultado(resp.data.listaPorResultado)
            setMensualPorPrevalidacion(resp.data.listaPorPrevalidacion)

            let total = 0;
            resp.data.listaPorTipo.forEach((item) => total += item.cantidad);
            setMensualTotal(total);

            let prevalidadas = 0;
            resp.data.listaPorPrevalidacion.forEach((item) => prevalidadas += item.cantidad);
            setMensualAhorro(prevalidadas);

        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const obtieneResumenAnual = () => {
        const resumenAnual = functions.httpsCallable('resumenAnualPorCliente');

        resumenAnual()
            .then((respuesta) => {
                setDatosAnuales(respuesta.data.resumen)
                setPromedioAnual(respuesta.data.promedio)
                setTotalAnual(respuesta.data.total)
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error:",
                    detail: error.message,
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }


    return (<>
        <NeoGridContainer>


            {
                mesesDisponibles
                    ? mesesDisponibles.length
                        ? <EstadisticasGraficas
                            mesesDisponibles={mesesDisponibles}
                            obtieneResumenPorMes={obtieneResumenPorMes}
                            mensualPorMetodo={mensualPorMetodo}
                            mensualPorTipo={mensualPorTipo}
                            mensualPorResultado={mensualPorResultado}
                            mensualPorPrevalidacion={mensualPorPrevalidacion}
                            mensualTotal={mensualTotal}
                            mensualAhorro={mensualAhorro}
                            datosAnuales={datosAnuales}
                            promedioAnual={promedioAnual}
                            totalAnual={totalAnual}
                            filtroSeleccionInicial={1}
                        />
                        : <NeoColumn md="12">
                            <NeoCardPrincipal>
                                <NeoInnerBlankCardMainText>
                                    Bienvenido a tu espacio de validaciones
                                </NeoInnerBlankCardMainText>
                                <NeoInnerBlankCardSecondText>
                                    Empieza a validar tus listas de correos electrónicos o teléfonos por el método que mejor se ajuste a tus necesidades.
                                </NeoInnerBlankCardSecondText>
                            </NeoCardPrincipal>
                        </NeoColumn>
                    : "cargando..."

            }

        </NeoGridContainer>
    </>);

};

export default Estadisticas;