/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import NeoDropdown from "./NeoDropdown.base";

const NeoDropDownFilter = (props) => {
    useEffect(() => {
        if (!props.options
            || props.initialSelection === undefined
            || props.initialSelection === null
            || !props.onChange) {
            return;
        }

        props.onChange(props.options[props.initialSelection])

    }, [props?.initialSelection])


    return (<>
        <NeoDropdown
            {...props}
            onChange={(e) => props.onChange(e.value)}
        />
    </>);
}

export default NeoDropDownFilter;