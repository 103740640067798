import { useEffect } from "react";
import HistoricoValidaciones from "components/common/HistoricoValidaciones";
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import { useComponent } from "hooks";
import BotonDescargaValidaciones from "components/common/BotonDescargaValidaciones";
import useCliente from "hooks/useCliente";
import { useLocation } from "react-router-dom";

const ClientesValidaciones = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const [lazyParams, setLazyParams] = componente.useCustomState();
    const location = useLocation();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        location.state?.lazyParams
            ? setLazyParams(location.state.lazyParams)
            : setLazyParams({
                first: 0,
                rows: 8,
                page: 1
            })
    }, [])


    return (<>
        <NeoGridContainer>
            <NeoTitleSecond col="12" md="6"
                subtitle=""
                title="Centro de validaciones" />
        </NeoGridContainer>

        <HistoricoValidaciones
            clienteId={cliente.id}
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
        />

        <NeoGridContainer>
            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <BotonDescargaValidaciones 
                    clienteId={cliente.id}
                    lazyParams={lazyParams}
                />
            </NeoButtonSection>
        </NeoGridContainer>
    </>);
};


export default ClientesValidaciones;