import NeoMessageLoading from "components/common/neo/messages/NeoMessageLoading.base";



const PagosCancelado = () => {
    return (<>
        <NeoMessageLoading
            title="Error al procesar pago"
            message="Hubo un error al procesar el pago, favor de verificar los datos e intentar de nuevo o ponerse en contácto con su Desarrollador de Negocio asignado."
            col={12} />
    </>);

};


export default PagosCancelado;