export const validaExistencia = (valor) => {
  let esValido = true
  let mensaje = null

  if (valor === undefined || valor === "" || valor === false) {
    mensaje = "Este campo es necesario";
    esValido = false;
  }

  // return [esValido, mensaje];
  return {esValido: esValido, mensaje: mensaje}
};

export const validaNombre = (valor) => {
    let esValido = true
    let mensaje = null

    if (valor === undefined || valor === "") {
      mensaje = "Este campo es necesario";
      esValido = false;
    }

    // return [esValido, mensaje];
    return {esValido: esValido, mensaje: mensaje}
  };

export const validaCorreo = (valor) => {
    const formato = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let esValido = true;
    let mensaje = null;

    if (!formato.test(valor)) {
      mensaje = "El correo no tiene formato válido";
      esValido = false;
    }

    return {esValido: esValido, mensaje: mensaje}
};

export const validaDominio = (valor) => {
    const formato =
        /^@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    let esValido = true;
    let mensaje = null;

    if (!formato.test(valor)) {
      mensaje = "El dominio no tiene formato válido";
      esValido = false;
    }

    return {esValido: esValido, mensaje: mensaje}
};

export const validaTelefono = (valor) => {
  const formato1 = /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/;
  const formato2 = /^[(]?[0-9]{2}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/;

  let esValido = false;
  let mensaje = null;

  formato1.test(valor) || formato2.test(valor)
    ? esValido = true
    : mensaje = "El formato no es válido"

  return {esValido: esValido, mensaje: mensaje}
}

export const validaRFC = (valor) => {
  const formato = /^[A-Za-z]{3,4}\d{6}(?:[A-Za-z\d]{3})?$/;
  let esValido = false;
  let mensaje = null;

  // Permite campo vacío
  valor
    ? formato.test(valor)
        ? esValido = true
        : mensaje = "El formato no es válido"
    : esValido = true

  return {esValido: esValido, mensaje: mensaje}
}

export const regexContrasena = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,})$/;
export const validaContrasena = (valor) => {
  let esValido = false;
  let mensaje = null;

  // Permite campo vacío
  if (valor.length < 8)
    mensaje = "Tu contraseña debe ser mayor a 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un caracter especial";
  else if (regexContrasena.test(valor))
    esValido = true
  else
    mensaje="Tu contraseña debe ser: mayor a 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un caracter especial"

    ? regexContrasena.test(valor)
        ? esValido = true
        : mensaje = "El formato no es válido"
    : esValido = true

  return {esValido: esValido, mensaje: mensaje}
}


// export default validaNombre;