import { useEffect, useContext } from "react";
import { functions } from "config/firebase";
import { Redirect } from "react-router-dom";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import RUTAS from "components/router/Rutas.json";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
// import NeoButtonSection from '../../common/neo/layout/NeoButtonSection';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../common/neo/title/NeoTitleMain.base";
import NeoCard from "../../common/neo/panel/NeoCard.base";
import NeoInnerTitle from "../../common/neo/title/NeoInnerTitle.base";
import NeoDataSecond from "../../common/neo/data/NeoDataSecond.base";
import Icono from 'components/../design/assets/img/mnv/icons/icon-validacion.svg';
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../common/neo/button/NeoButtonOutlined.base";
import NeoFlexContainer from "../../common/neo/layout/NeoFlexContainer.base";
import FormularioAgregarProductoModal from "components/common/FormularioAgregarProductoModal";
import NeoTable from "../../common/neo/table/NeoTable.base";
import NeoButtonSection from "../../common/neo/layout/NeoButtonSection.base";
import { Column } from 'primereact/column';
import { convierteAMoneda, capitalizar, formateaANumeroLocal } from "consts/generales";
import { useComponent } from "hooks";

const obtienePrecios = functions.httpsCallable("precios-obtienePrecioPorCliente");

let nuevaValidacion = {
    tipoValidacion: '',
    cantidad: 1,
    precioUnitario: 0,
    precioTotal: 0,
};


const ComprarValidaciones = (props) => {
    const componente = useComponent();
    const crearOrdenCompra = componente.useAsync(functions.httpsCallable('ordenes-crear'));
    const [estadoGlobal, setEstadoGlobal] = useContext(EstadoGlobal);
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const [muestraModal, setMuestraModal] = componente.useCustomState(false);

    const [preciosProductos, setPreciosProductos] = componente.useCustomState();
    const [producto, setProducto] = componente.useCustomState();
    const [carrito, setCarrito] = componente.useCustomState([]);

    const [precioTotal, setPrecioTotal] = componente.useCustomState(0);
    const [paqueteCreado, setPaqueteCreado] = componente.useCustomState(false);

    const [redirecciona, setRedirecciona] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtienePrecios()
            .then((resp) => {
                setPreciosProductos(resp.data);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            })

        if (estadoGlobal?.carritoCompra) {
            setCarrito(estadoGlobal.carritoCompra);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //Actualiza precio total
    useEffect(() => {
        let total = "-";
        if (carrito) {
            total = carrito.reduce((acumulado, nodo) => {
                return acumulado + nodo.cantidad * nodo.precioUnitario;
            }, 0)
            setEstadoGlobal(e => ({ ...e, carritoCompra: carrito }))
        }

        setPrecioTotal(total);
    }, [carrito]) // eslint-disable-line react-hooks/exhaustive-deps


    //Envía a pasarela de pago
    useEffect(() => {
        //PONER CÓDIGO DE ENVÍO A PASARELA DE PAGO AQUI:
        if (!paqueteCreado) {
            return;
        }

        // setPaqueteCreado();
        setCarrito([]);
        setRedirecciona(RUTAS.clientes.ordenes.detalle);
    }, [paqueteCreado]) // eslint-disable-line react-hooks/exhaustive-deps

    const nuevoProducto = () => {
        setProducto(() => nuevaValidacion);
        setMuestraModal(true);
    };

    const agregaProducto = (datos) => {
        let productos = [...carrito]
        let producto = { ...datos }

        let indice = productos.findIndex(e => e.tipoValidacion === producto.tipoValidacion);

        if (indice >= 0) {
            productos[indice] = producto;
        } else {
            productos.push(producto);
        }

        setCarrito(productos);
    };

    const editaProducto = (producto) => {
        setProducto(producto);
        setMuestraModal(true);
    }

    const borraProducto = (producto) => {
        let productos = carrito.filter(e => e.tipoValidacion !== producto.tipoValidacion);
        setCarrito(productos);
    }

    const manejaGuardar = () => {
        if (!Array.isArray(carrito) || carrito.length < 1) {
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: "No hay productos para generar la órden de compra"
            });

            return;
        }

        crearOrdenCompra({
            productos: carrito
        }).then((resp) => {
            console.log("Respuesta al crear orden:", resp.data.ordenId)
            setPaqueteCreado(() => resp.data.ordenId);
        }).catch((error) => {
            console.log("Error al crear paquete normal: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error: ",
                life: 5000,
                detail: error.message
            });
        });
    };

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {rowData.cantidad.toLocaleString("es-MX")}
        </>);
    }
    const tipoProductoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Tipo de validación</span>
            Validaciones de {capitalizar(rowData.tipoValidacion)}
        </>);
    }
    const precioUnitarioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            {convierteAMoneda(rowData.precioUnitario)}
        </>);
    }
    const totalBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            ${formateaANumeroLocal(Number(rowData.precioUnitario) * Number(rowData.cantidad)) + " MXN"}
        </>);
    }
    const accionesBodyTemplate = (rowData) => {
        return (<>
            <NeoFlexContainer jc="end">
                <NeoButtonOutlined
                    icon="pi pi-pencil"
                    extra="p-button-rounded  p-mr-2"
                    alternative="secondary"

                    onClick={() => editaProducto(rowData)} />
                <NeoButtonOutlined
                    icon="pi pi-trash"
                    extra="p-button-rounded "
                    alternative="secondary"
                    onClick={() => borraProducto(rowData)} />

            </NeoFlexContainer>
        </>);
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} col="12" md="6"
                title="Adquirir validaciones">

                <NeoButtonSection align="right" col="12" md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column">
                    <NeoButtonMain
                        label="Agregar producto"
                        onClick={() => nuevoProducto()}
                    />

                </NeoButtonSection>

            </NeoTitleMain>
        </NeoGridContainer>



        <NeoTable
            value={carrito}
            rows={10}
            emptyMessage="No hay productos en el carrito"
            emptyMessageSecondary="Agrega un producto para adquirirlo"
            extra="no-filters">
            <Column
                field="cantidad"
                header="Cantidad"
                body={cantidadBodyTemplate} />
            <Column
                field="tipo"
                header="Producto"
                body={tipoProductoBodyTemplate} />
            <Column
                field="precioUnitario"
                header="Precio unitario"
                body={precioUnitarioBodyTemplate} />
            <Column
                header="Total"
                body={totalBodyTemplate} />
            <Column
                body={accionesBodyTemplate} />
        </NeoTable>

        <NeoFlexContainer extra="p-pb-3  p-pt-5 " jc="end" >
            <NeoDataSecond extra="text-align-right"
                fact={precioTotal
                    ? `$${formateaANumeroLocal(precioTotal)} MXN`
                    : "-"
                }
                label="Precio total" />
        </NeoFlexContainer>


        <NeoButtonSection align="right" >
            <NeoButtonMain label="Crear orden" onClick={manejaGuardar} loading={componente.isLoading} />
        </NeoButtonSection>

        <FormularioAgregarProductoModal
            mensaje="Agrega productos al carrito"
            onSubmit={agregaProducto}
            muestraModal={muestraModal}
            setMuestraModal={setMuestraModal}
            preciosProductos={preciosProductos}
            datosIniciales={producto}
            autoPrecio={true}
            carrito={carrito}

        />
        {
            redirecciona
            && <Redirect
                push
                to={{
                    pathname: redirecciona,
                    state: { id: paqueteCreado, irAtras: false }

                }}
            />
        }
    </>);
};


export default ComprarValidaciones;
