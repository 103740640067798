import { useEffect } from 'react';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleMain from '../../common/neo/title/NeoTitleMain.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import Icono from 'design/assets/img/mnv/icons/icon-dashboard.svg';
import CardResumenResultados from "components/common/CardResumenResultados";
import GraficaResumenAnual from "components/common/GraficaResumenAnual";
import { COLORES } from "consts/generales";
import { useComponent } from 'hooks';
import NeoColumn from '../../common/neo/layout/NeoColumn.base';


const COLORES_METODO = {
    porNombre: COLORES.porNombre,
    porCodigo: COLORES.porCodigo
};
const COLOR_TIPO = {
    porNombre: COLORES.porNombre.slice(2, 4),
    porCodigo: COLORES.porCodigo.slice(2, 4)
};


const Tablero = () => {
    const componente = useComponent();
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const [mesesDisponibles, setMesesDisponibles] = componente.useCustomState();

    const [mensualPorMetodo, setMensualPorMetodo] = componente.useCustomState();
    const [mensualPorTipo, setMensualPorTipo] = componente.useCustomState();
    const [mensualTotal, setMensualTotal] = componente.useCustomState();

    const [datosAnuales, setDatosAnuales] = componente.useCustomState();
    const [promedioAnual, setPromedioAnual] = componente.useCustomState();
    const [totalAnual, setTotalAnual] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Obtiene los meses con datos disponibles de validaciones
    useEffect(() => {
        obtieneMesesDisponibles();
        obtieneResumenAnual();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneMesesDisponibles = () => {
        const getMesesConDatos = functions.httpsCallable('fechasParaResumenMensualGlobal');

        getMesesConDatos().then((resp) => {
            if (Array.isArray(resp.data) && resp.data.length) {
                resp.data.unshift({
                    valor: 'anual',
                    nombre: 'Últimos 12 meses'
                });
            }
            setMesesDisponibles(resp.data)
            if (!resp.data || !resp.data.length) {
                setSpinnerCargando(false);
            }
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        })
    }

    const obtieneResumenPorMes = (fecha) => {
        const getResumen = functions.httpsCallable('resumenMensualGlobal');
        getResumen({ mes: fecha }).then((resp) => {
            setMensualPorMetodo(resp.data.listaPorMetodo)
            setMensualPorTipo(resp.data.listaPorTipo)

            let total = 0;
            resp.data.listaPorTipo.forEach((item) => total += item.cantidad)
            setMensualTotal(total)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const obtieneResumenAnual = () => {
        const getResumenAnualGlobal = functions.httpsCallable('obtieneResumenAnualGlobal');
        getResumenAnualGlobal().then((resp) => {
            setDatosAnuales(() => resp.data.resumen)
            setPromedioAnual(() => resp.data.promedio)
            setTotalAnual(() => resp.data.total)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        }).finally(() => {
            // setCargando(false);
        });
    }

    return (
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} title="Dashboard" />
            <NeoColumn md="12">

                <CardResumenResultados
                    titulo="Gráficas de validaciones"
                    filtro={{
                        opciones: mesesDisponibles,
                        onChange: obtieneResumenPorMes,
                        seleccionIncial: 1
                    }}
                    graficas={[
                        {
                            datos: mensualPorMetodo,
                            titulo: "Medio usado para validaciones",
                            colores: COLORES_METODO
                        },
                        {
                            datos: mensualPorTipo,
                            titulo: "Tipo de validaciones realizadas",
                            colores: COLOR_TIPO
                        }
                    ]}

                    detallePrincipal={[
                        {
                            datos: mensualTotal,
                            titulo: "Validaciones del mes",
                            cols: 12
                        }
                    ]}
                />
            </NeoColumn>

            <NeoColumn md="12">
                <GraficaResumenAnual
                    datosAnuales={datosAnuales}
                    promedio={promedioAnual}
                    total={totalAnual} />
            </NeoColumn>
        </NeoGridContainer>
    );
};


export default Tablero;