import { useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import { useLocation, useHistory } from "react-router-dom";
import FormularioRestablecerContrasena from "components/common/FormularioRestablecerContrasena";
import NeoColumn from '../common/neo/layout/NeoColumn.base';
import NeoLink from '../common/neo/button/NeoLink.base';
import NeoNote from '../common/neo/data/NeoNote.base';
import NeoInnerTitle from '../common/neo/title/NeoInnerTitle.base';
import NeoInnerSubtitle from '../common/neo/title/NeoInnerSubtitle.base';
import { Toast } from 'primereact/toast';
import { useComponent } from 'hooks';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RestablecerContrasena = () => {
  const componente = useComponent();
  const [codigoVerificado, setCodigoVerificado] = componente.useCustomState()
  const [contrasenaCambiada, setContrasenaCambiada] = componente.useCustomState(false)
  let query = useQuery();
  const codigo = query.get("oobCode")
  const [correo, setCorreo] = componente.useCustomState("");
  let historial = useHistory();
  const toast = useRef(null);

  useEffect(() => {
    return () => { componente.desmontar(); };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (codigo) {
      firebase.auth().verifyPasswordResetCode(codigo)
        .then((email) => {
          // Display a "new password" form with the user's email address
          if (email) {
            setCorreo(email)
            setCodigoVerificado(true)
          }
        }).catch(function (error) {
          // Invalid code
          setCodigoVerificado(false)
        })
    } else
      setCodigoVerificado(false)
  }, [codigo]) // eslint-disable-line react-hooks/exhaustive-deps

  const pideRestablecer = (datos) => {
    if (codigoVerificado) {
      firebase.auth().confirmPasswordReset(codigo, datos.contrasena)
        .then(function (msg) {
          // Success
          setContrasenaCambiada(true)
        }).catch(function (error) {
          // Invalid code
          console.log("Error al cambiar el password", error)
          muestraError("No se pudo cambiar la contraseña, intente nuevamente")
        })
    }
  }

  const muestraError = (mensaje) => {
    toast.current.show({
      severity: 'error', summary: "Ha sucedido un error",
      detail: mensaje, life: 3000
    });
  }


  return (<>
    <Toast ref={toast} />
    <NeoColumn>
      {
        codigoVerificado
          ? contrasenaCambiada
            ? <NeoNote text="Contraseña cambiada con éxito, puedes iniciar sesión." />
            : <>
              <NeoInnerTitle text="Reestablece tu contraseña." />
              <NeoInnerSubtitle text={`para: ${correo}`} />
              <FormularioRestablecerContrasena onSubmit={pideRestablecer} />


            </>
          : <NeoNote text="Error en el enlace." />
      }

      <NeoLink label="Regresar al inicio."
        onClick={() => historial.push("/")} />
    </NeoColumn>
  </>);
};

export default RestablecerContrasena;