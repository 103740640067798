import React from 'react';
import { FileUpload } from 'primereact/fileupload';


const BaseInputFile = (props) => {

  return (<>
    <FileUpload {...props} >
      {props.children}
    </FileUpload>
  </>);
};

export default BaseInputFile;
