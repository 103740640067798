/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import RUTAS from "components/router/Rutas.json";
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import { aCadenaFechaConFormatoLocal } from "consts/generales";
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoDataSecond from "../../common/neo/data/NeoDataSecond.base";
import NeoInputChip from "../../common/neo/form/NeoInputChip.base";
import NeoCard from "../../common/neo/panel/NeoCard.base";
import { capitalizar, obtieneTipoIp } from "components/../consts/generales";
import NeoColumn from "../../common/neo/layout/NeoColumn.base";
import NeoInnerTitle from "../../common/neo/title/NeoInnerTitle.base";
import FormularioNombreApiModal from 'components/common/FormularioNombreApiModal';
import NeoDialogConfirm from "../../common/neo/overlay/NeoDialogConfirm.base";
import NeoTitleMain from "../../common/neo/title/NeoTitleMain.base";
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import CardResumenResultados from "components/common/CardResumenResultados";
import NeoCardPrincipal from "../../common/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "../../common/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "../../common/neo/table/NeoInnerBlankCardSecondText.base";
import NeoDropdown from "../../common/neo/form/NeoDropdown.base";
import Icono from 'design/assets/img/mnv/icons/icon-apis.svg';
import IconoEstadisticas from 'design/assets/img/mnv/icons/icon-dashboard.svg';
import { useComponent, useInput, useUsuario } from 'hooks';
import NeoTooltip_icon from "../../common/neo/overlay/NeoTooltip_icon.base";
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import NeoDropDownFilter from "components/common/neo/form/NeoDropDownFilter.base";
import NeoCardMessage from "components/common/neo/panel/NeoCardMessage.base";

const opcionesHabilitado = [
    { opcion: "Modo privado: No guardar datos de validaciones", valor: true },
    { opcion: "Modo normal", valor: false }
];

const ApiKeysDetalle = (props) => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    const componente = useComponent();
    const historial = useHistory();

    // Funciones 
    const getEstadisticasUso = componente.useAsync(functions.httpsCallable("obtieneEstadisticasUsoPorApi"));
    const getDetalleApiKey = componente.useAsync(functions.httpsCallable("getDetalleApiKey"));
    const getEstadisticas = componente.useAsync(functions.httpsCallable("obtieneEstadisticasUsoPorApiYMetodo"));
    const cambiaActivacionApiKey = componente.useAsync(functions.httpsCallable("cambiaActivacionApiKey"));
    const renombraApiKey = componente.useAsync(functions.httpsCallable("renombraApiKey"));
    const obtieneMeses = componente.useAsync(functions.httpsCallable('obtieneMesesConEstadisticasApi'));
    const actualizarPrivacidadApiKey = componente.useAsync(functions.httpsCallable('actualizarPrivacidadApiKey'));
    const actualizarFiltroIPApiKey = componente.useAsync(functions.httpsCallable('actualizarFiltroIPApiKey'));

    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const [apiKeyId, setApiKeyId] = componente.useCustomState();
    const [datos, setDatos] = componente.useCustomState("")
    const [muestraModal, setMuestraModal] = componente.useCustomState();
    const [muestraDialogoActivacion, setMuestraDialogoActivacion] = componente.useCustomState();
    const [modoPrivado, setModoPrivado] = componente.useCustomState();
    const [filtroIP, setFiltroIP] = useInput([]);
    const mensajeDesactiva = "Se desactivará la llave API, esto significa que no podrás realizar validaciones usando esta llave. ¿Deseas proceder?"
    const mensajeReactiva = "Se activará la llave API. ¿Deseas proceder?"
    const activo = true;

    const [totalMensual, setTotalMensual] = componente.useCustomState();
    const [resumenCorreos, setResumenCorreos] = componente.useCustomState();
    const [resumenTelefonos, setResumenTelefonos] = componente.useCustomState();
    const [estadisticasUso, setEstadisticasUso] = componente.useCustomState();

    const [mesSeleccionadoFiltro, setMesSeleccionadoFiltro] = componente.useCustomState();
    const [mesesDisponiblesFiltro, setMesesDisponiblesFiltro] = componente.useCustomState();
    const [errores, setErrores] = componente.useCustomState({});

    useEffect(() => {
        setSpinnerCargando(componente.isLoading);
    }, [componente.isLoading])

    useEffect(() => {
        props.location.state && setApiKeyId(props.location.state.id);

        return () => { componente.desmontar(); };
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (datos) {
            if (datos?.modoPrivado !== undefined) {
                const modoPrivadoIncial = opcionesHabilitado.find(o => o.valor === Boolean(datos.modoPrivado));
                setModoPrivado(modoPrivadoIncial)
            }
        }
    }, [datos]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!apiKeyId) {
            return
        }
        obtieneDatosGenerales();
        obtieneMesesDisponibles();


    }, [apiKeyId]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!mesSeleccionadoFiltro) {
            return;
        }

        obtieneEstadisticasUso();
        obtieneEstadisticasUsoPorMetodo();

    }, [mesSeleccionadoFiltro]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (datos) {
            if (datos?.modoPrivado !== undefined) {
                const modoPrivadoIncial = opcionesHabilitado.find(o => o.valor === Boolean(datos.modoPrivado));
                setModoPrivado(modoPrivadoIncial)
            }
        }
    }, [datos]); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatosGenerales = () => {
        getDetalleApiKey({ apiKeyId: apiKeyId })
            .then((resp) => {
                setDatos(resp.data)
                if (resp.data.filtroIP) {
                    let listaIpsValidas = resp.data.filtroIP.split(/\r?\n|,/);
                    if (Array.isArray(listaIpsValidas)) {
                        setFiltroIP(listaIpsValidas);
                    }
                }
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    };

    const obtieneEstadisticasUso = () => {
        let fecha;
        mesSeleccionadoFiltro
            ? fecha = mesSeleccionadoFiltro.valor
            : fecha = null
        getEstadisticasUso({
            apiKeyId: apiKeyId,
            fecha: fecha
        }).then((resp) => {
            setEstadisticasUso(resp.data)
            setTotalMensual(resp.data.totalValidaciones)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        });
    };

    const obtieneEstadisticasUsoPorMetodo = () => {
        getEstadisticas({
            apiKeyId: apiKeyId,
            fecha: mesSeleccionadoFiltro.valor
        }).then((resp) => {
            setResumenCorreos(resp.data.estadisticasCorreo)
            setResumenTelefonos(resp.data.estadisticasTelefono)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        });
    };

    const cambiaEstadoApiKey = () => {
        cambiaActivacionApiKey({ activa: !datos.activa, apiKeyId: apiKeyId })
            .then(() => {
                obtieneDatosGenerales();
                despliegaMensaje({
                    severity: "success",
                    summary: "",
                    detail: "Se ha cambiado el estado de la llave API correctamente."
                });
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error:",
                    detail: error.message
                });
            });
    };

    const pideRenombrarApiKey = (nuevosDatos) => {
        //Sólo llama a Functions si el dato ha cambiado
        if (datos.nombre === nuevosDatos.nombre)
            return

        renombraApiKey({ nombre: nuevosDatos.nombre, apiKeyId: apiKeyId })
            .then((resp) => {
                obtieneDatosGenerales();
                despliegaMensaje({
                    severity: "success",
                    summary: "",
                    detail: "Se ha renombrado la llave API correctamente."
                });
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    };

    const obtieneMesesDisponibles = () => {
        obtieneMeses({ apiKeyId: apiKeyId }).then((resp) => {
            setMesesDisponiblesFiltro(resp.data);
            //Para cargar los datos del primer més
            resp.data.length && setMesSeleccionadoFiltro(resp.data[0]);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        })
    }

    const actualizarPrivacidad = () => {
        actualizarPrivacidadApiKey({
            apiKeyId: apiKeyId,
            modoPrivado: modoPrivado.valor
        })
        .then(() => {
            despliegaMensaje({
                severity: "success",
                summary: "",
                detail: "Se ha cambiado la privacidad de la llave API correctamente."
            });
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message
            });
        });
    }

    const actualizarFiltroIP = () => {
        let errores = false;

        if (Array.isArray(filtroIP)) {
            for (const ip of filtroIP) {
                if (!obtieneTipoIp(ip)) {
                    errores = true;
                    break;
                }
            }

            if (errores) {
                setErrores((error) => {
                    return {
                        ...error,
                        filtroIP: 'Existe una dirección IP no válida, favor de verificar'
                    }
                })
            } else {
                setErrores((error) => {
                    return {
                        ...error,
                        filtroIP: ''
                    }
                })
            }
        }

        if (!errores) {
            actualizarFiltroIPApiKey({
                apiKeyId: apiKeyId,
                filtroIP: filtroIP
            })
                .then(() => {
                    despliegaMensaje({
                        severity: "success",
                        summary: "",
                        detail: "Se ha cambiado la privacidad de la llave API correctamente."
                    });
                }).catch((error) => {
                    console.log("Error: ", error);
                    despliegaMensaje({
                        severity: "error",
                        summary: "Error:",
                        detail: error.message
                    });
                });
        }
    }

    const verDocumentacion = () => {
        historial.push({
            pathname: RUTAS.clientes.apikeysDocumentacion,
            state: {
                id: apiKeyId
            }
        })
    }

    const irAAdminCuenta = () => {
        historial.push({
            pathname: RUTAS.clientes.cuenta,
            state: {
                id: apiKeyId
            }
        })
    }


    return (<>
        <NeoGridContainer>
            <NeoTitleSecond col="12" md="6"
                title={datos && datos.nombre
                    ? `API ${capitalizar(datos.nombre)}`
                    : "API "} icon={Icono} subtitle="Detalle de API" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column" >

                <NeoButtonMain label="Renombrar"
                    onClick={() => setMuestraModal(activo)} />

                <NeoButtonMain
                    label={datos && datos.activa ? "Desactivar" : "Reactivar"}
                    onClick={() => setMuestraDialogoActivacion(true)} />

                <NeoButtonMain label="Ver documentación"
                    onClick={verDocumentacion} />

            </NeoButtonSection>
        </NeoGridContainer>

        <NeoCard>
            <NeoColumn md="7" extra="apidev-container">
                <NeoDataSecond
                    fact={datos && datos.llave}
                    label="Llave API"
                // onClick={blurIt}
                />
            </NeoColumn>
            <NeoColumn md="3">
                <NeoDataSecond
                    label="Fecha de creación"
                    fact={datos && aCadenaFechaConFormatoLocal(datos.fecha_creacion)} />
            </NeoColumn>
            <NeoColumn md="1">
                <NeoDataSecond label="Estatus"
                    fact={datos && datos.activa ? "Activa" : "Inactiva"} />
            </NeoColumn>
        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">
                Privacidad de los datos para esta API
            </NeoInnerTitle>

            {
                persona.cliente?.modoPrivado
                && <NeoInnerBlankCardMainText>
                    La política de almacenamiento de datos general
                    esta habilitada, para establecer la política por
                    API por favor deshabilita el modo privado general
                    desde <a href="javascript:;" onClick={irAAdminCuenta}>Administrar cuenta</a>.
                </NeoInnerBlankCardMainText>
            }

            <NeoColumn col="9">
                <NeoDropdown
                    value={modoPrivado}
                    options={opcionesHabilitado}
                    onChange={(e) => setModoPrivado(e.value)}
                    placeholder=""
                    optionLabel="opcion"
                    optionValue=""
                    label="Política de almacenamiento de datos"
                    disabled={persona.cliente?.modoPrivado} />
            </NeoColumn>
            <NeoColumn col="1">
                <NeoTooltip_icon
                    text="Al habilitarlo, no se guardará ningún dato sensible sobre la validación (ej. correo o teléfono), pero aún podrás consultar las estadísticas"
                />
            </NeoColumn>
            <NeoColumn col="2">
                <NeoButtonSection>
                    <NeoButtonMain
                        label="Guardar"
                        onClick={actualizarPrivacidad}
                        disabled={persona.cliente?.modoPrivado}
                    />
                </NeoButtonSection>
            </NeoColumn>
        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">Lista blanca de direcciones IP</NeoInnerTitle>
            <NeoColumn col="12">
                Si la lista está vacía todas 
                las direcciones IP serán permitidas, si se agrega una 
                dirección ip o un rango de direcciones solamente estas podrán 
                validar con esta API
            </NeoColumn>
            <NeoColumn col="9">
                <NeoInputChip
                    placeholder="Direcciones IP permitidas"
                    value={filtroIP}
                    onChange={setFiltroIP}
                    separator="," allowDuplicate={false}
                    error={errores.filtroIP} md="12"
                />
            </NeoColumn>
            <NeoColumn col="1">
                <NeoTooltip_icon
                    text="Escribe una dirección IP y agregala a la lista usando «Enter» ó «,» (coma)"
                />
            </NeoColumn>
            <NeoColumn col="2">
                <NeoButtonSection>
                    <NeoButtonMain
                        label="Guardar"
                        onClick={actualizarFiltroIP}
                    />
                </NeoButtonSection>
            </NeoColumn>
        </NeoCard>

        <NeoGridContainer>
            <NeoColumn md="9">
                <NeoTitleMain icon={IconoEstadisticas} title="Estadísticas" col="12" md="6" />
            </NeoColumn>

            <NeoColumn md="3">
                <NeoButtonSection align="right" col="12" md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column">
                    <NeoDropDownFilter
                        value={mesSeleccionadoFiltro}
                        options={mesesDisponiblesFiltro}
                        onChange={setMesSeleccionadoFiltro}
                        initialSelection={0}
                        placeholder=""
                        optionLabel="nombre"
                        label="Filtrar estadísticas por mes"
                    />
                </NeoButtonSection>
            </NeoColumn>

            {
                estadisticasUso
                    && estadisticasUso.tipoValidacion
                    && estadisticasUso.tipoValidacion.length
                    ? <CardResumenResultados
                        titulo="Total de validaciones:"
                        graficas={[
                            {
                                cols: 4,
                                datos: estadisticasUso.volumenValidacion,
                                titulo: "Por tipo de solicitud"
                            },
                            {
                                cols: 4,
                                datos: estadisticasUso.tipoValidacion,
                                titulo: "Por tipo de validaciones"
                            },
                        ]}
                        detallePrincipal={[
                            {
                                cols: 4,
                                datos: totalMensual,
                                titulo: `Validaciones solicitadas ${mesSeleccionadoFiltro?.nombre.substring(0, 3)}.`
                            }
                        ]}

                    />
                    : <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            Aún no se han generado estadísticas para esta API key
                        </NeoInnerBlankCardMainText>
                        <NeoInnerBlankCardSecondText>
                            Empieza a validar tus listas de correos electrónicos o teléfonos y monitorea su uso en esta sección.
                        </NeoInnerBlankCardSecondText>
                    </NeoCardPrincipal>
            }

            {
                resumenCorreos
                    && resumenCorreos.tipoResultado
                    && resumenCorreos.tipoResultado.length
                    ? <CardResumenResultados
                        titulo="Resumen de validaciones de correos electrónicos"
                        graficas={[
                            {
                                datos: resumenCorreos.tipoResultado,
                                titulo: "Tipo de resultado"
                            },
                            {
                                datos: resumenCorreos.historico, titulo: "Histórico"
                            }
                        ]}
                        detallesSecundarios={[
                            {
                                datos: resumenCorreos.tipoDescripcion,
                                titulo: "Detalle de validaciones"
                            }
                        ]} />
                    : null
            }

            {
                resumenTelefonos
                    && resumenTelefonos.tipoResultado
                    && resumenTelefonos.tipoResultado.length
                    ? <CardResumenResultados
                        titulo="Resumen de validaciones de teléfonos celulares"
                        graficas={[
                            {
                                datos: resumenTelefonos.tipoResultado,
                                titulo: "Tipo de resultado"
                            },
                            {
                                datos: resumenTelefonos.historico,
                                titulo: "Histórico"
                            }
                        ]}
                        detallesSecundarios={[
                            {
                                datos: resumenTelefonos.tipoDescripcion,
                                titulo: "Detalle de validaciones"
                            }
                        ]} />
                    : null
            }

            <FormularioNombreApiModal mensaje="Renombrar llave API"
                datosIniciales={{ nombre: datos.nombre }}
                onSubmit={pideRenombrarApiKey}
                muestraModal={muestraModal}
                setMuestraModal={setMuestraModal} />

            <NeoDialogConfirm visible={muestraDialogoActivacion}
                onHide={() => setMuestraDialogoActivacion(false)}
                message={datos && datos.activa
                    ? mensajeDesactiva
                    : mensajeReactiva}
                header="Confirmación" icon="pi pi-exclamation-triangle"
                accept={cambiaEstadoApiKey} />
        </NeoGridContainer>
    </>);
};


export default ApiKeysDetalle;