import React, { useState, useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleMain from "../../common/neo/title/NeoTitleMain.base";
import firebase from "firebase/app";
import FormularioCuentaUsuario from "components/common/FormularioCuentaUsuario";
import Icono from "design/assets/img/mnv/icons/icon-user.svg";
import NeoButtonSection from "../../common/neo/layout/NeoButtonSection.base";
import NeoButtonSecondary from "../../common/neo/button/NeoButtonSecondary.base";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoDialogConfirm from "../../common/neo/overlay/NeoDialogConfirm.base";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import { useComponent, useUsuario } from "hooks";
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";


const obtieneDatosCliente = functions.httpsCallable("getDatosCliente");
const guardaDatosCliente = functions.httpsCallable("setDatosCliente");


const Cuenta = () => {
    const usuario = useUsuario();
    const componente = useComponent();
    const obtieneListaPrecios = componente.useAsync(functions.httpsCallable("precios-obtienePrecioPorCliente"));
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const [datosCliente, setDatosCliente] = useState({})
    const setMensajeGlobal = useMensajeGlobal();
    let historial = useHistory();
    const [muestraDialogoContrasena, setMuestraDialogoContrasena] = componente.useCustomState(false);
    const [preciosGenerales, setPreciosGenerales] = componente.useCustomState();

    useEffect(() => {
        const obtieneDatosGenerales = () => {
            obtieneDatosCliente().then((resp) => {
                setDatosCliente(resp.data)
            }).catch((error) => {
                console.log("Error: ", error);
                setMensajeGlobal({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
        }

        obtieneDatosGenerales();
        obtienePrecios();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const obtienePrecios = () => {
        obtieneListaPrecios().then((respuesta) => {
            setPreciosGenerales(respuesta.data);
        }).catch((error) => {
            console.log("Error: ", error);
            setMensajeGlobal({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        })
    };

    const pideRestablecerContrasena = () => {
        firebase.auth().sendPasswordResetEmail(firebase.auth().currentUser.email)
            .then(() => {
                setMensajeGlobal({
                    severity: "success",
                    summary: "",
                    detail: "Se ha enviado el correo para restablecer la contraseña"
                });
            }).catch((error) => {
                setMensajeGlobal({
                    severity: "error",
                    summary: "Error",
                    detail: "Hubo un error al enviar el correo.",
                });
            });
    }

    const manejaConsultarBitacora = () => {
        return historial.push("/clientes/bitacora");
    };



    const guardaDatos = (datos) => {
        guardaDatosCliente(datos)
            .then(() => {
                setMensajeGlobal({
                    severity: "success",
                    summary: "",
                    detail: "Se han guardado los cambios en los datos de usuario"
                });
                // historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                setMensajeGlobal({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }

    const obtieneCorreo = () => {
        if(usuario.esSubCuenta()) {
            return usuario.persona.email;
        }
        return usuario.persona.cliente.correo;
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain
                icon={Icono}
                col="12"
                md="6"
                title="Mi cuenta" />

            <NeoButtonSection
                align="right"
                col="12"
                md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">

                <NeoButtonMain
                    label="Cambiar contraseña"
                    onClick={() => setMuestraDialogoContrasena(true)} />
                <NeoButtonMain
                    label="Consultar bitácora"
                    onClick={manejaConsultarBitacora} />

            </NeoButtonSection>
        </NeoGridContainer>


        <FormularioCuentaUsuario
            datos={datosCliente}
            onSubmit={guardaDatos}
            onCambiarContrasena={pideRestablecerContrasena}
            precios={preciosGenerales}
            vistaCliente={true} />

        <NeoDialogConfirm
            visible={muestraDialogoContrasena}
            onHide={() => setMuestraDialogoContrasena(false)}
            message={`Se te enviará un correo con instrucciones${usuario.esSubCuenta() ? ` a "${obtieneCorreo()}" `: ' '}para cambiar tu contraseña. ¿Deseas proceder?`}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideRestablecerContrasena} />
    </>);
};


export default Cuenta;