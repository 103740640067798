import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner"
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleMain from "../../common/neo/title/NeoTitleMain.base";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoButtonSection from "components/common/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "components/common/neo/button/NeoButtonMain.base";
import FormularioPaqueteEspecial from "components/common/FormularioPaqueteEspecial";
import NeoTable from "components/common/neo/table/NeoTable.base";
import NeoColumn from "components/common/neo/layout/NeoColumn.base";
import NeoFlexContainer from "components/common/neo/layout/NeoFlexContainer.base";
import NeoButtonOutlined from "components/common/neo/button/NeoButtonOutlined.base";
import { useComponent } from "hooks";
import FormularioAgregarProductoModal from "components/common/FormularioAgregarProductoModal";
import useCliente from 'hooks/useCliente';
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';
import { capitalizar, convierteAMoneda } from "consts/generales";

const getTipoValidaciones = functions.httpsCallable('getTipoValidaciones');
const crearPaqueteValidaciones = functions.httpsCallable('paquetes-crear');

let nuevaValidacion = {
    tipoValidacion: '',
    cantidad: 1,
    precioUnitario: 0,
    precioTotal: 0,
};

const ClientesPaquetesEspeciales = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [tipoValidacion, setTiposValidacion] = componente.useCustomState([]);
    const [carrito, setCarrito] = componente.useCustomState([]);
    const [producto, setProducto] = componente.useCustomState();
    const [muestraModal, setMuestraModal] = componente.useCustomState(false);
    let historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneTiposValidaciones();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneTiposValidaciones = async () => {
        try {
            const tipos = {};
            const tipoValidaciones = await getTipoValidaciones();
            tipoValidaciones.data.forEach((elemento, key) => {
                tipos[elemento.nombre] = {
                    id: elemento.id
                }
            })

            setTiposValidacion(tipos);
        } catch (error) {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        } finally {
            setSpinnerCargando(false);
        }
    }

    const enviarFormulario = async () => {
        try {
            setSpinnerCargando(true);
            await crearPaqueteValidaciones({
                clienteId: cliente.id,
                productos: carrito
            });
            despliegaMensaje({
                severity: 'success',
                summary: 'Orden creada'
            });
            historial.goBack();
        } catch (error) {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        } finally {
            setSpinnerCargando(false);
        }
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {rowData.cantidad.toLocaleString("es-MX")}
        </>);
    }

    const tipoProductoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Tipo de validación</span>
            Validaciones de {capitalizar(rowData.tipoValidacion)}
        </>);
    }

    const precioUnitarioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            {convierteAMoneda(rowData.precioUnitario)}
        </>);
    }

    const totalBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            {convierteAMoneda(Number(rowData.precioUnitario) * Number(rowData.cantidad)) + " MXN"}
        </>);
    }

    const editaProducto = (producto) => {
        setProducto(producto);
        setMuestraModal(true);
    }

    const borraProducto = (producto) => {
        let productos = carrito.filter(e => e.tipoValidacion !== producto.tipoValidacion);
        setCarrito(productos);
    }

    const nuevoProducto = () => {
        setProducto(() => nuevaValidacion);
        setMuestraModal(true);
    };

    const agregaProducto = (datos) => {
        let productos = [...carrito]
        let producto = { ...datos }

        let indice = productos.findIndex(e => e.tipoValidacion === producto.tipoValidacion);

        if (indice >= 0) {
            productos[indice] = producto;
        } else {
            productos.push(producto);
        }

        setCarrito(productos);
    };

    const accionesBodyTemplate = (rowData) => {
        return (<>
            <NeoFlexContainer jc="end">
                <NeoButtonOutlined
                    icon="pi pi-pencil"
                    extra="p-button-rounded  p-mr-2"
                    alternative="secondary"

                    onClick={() => editaProducto(rowData)} />
                <NeoButtonOutlined
                    icon="pi pi-trash"
                    extra="p-button-rounded "
                    alternative="secondary"
                    onClick={() => borraProducto(rowData)} />

            </NeoFlexContainer>
        </>);
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} col="12" md="6"
                title="Paquete especial" />

                <NeoButtonSection align="right" col="12" md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column">
                    <NeoButtonMain
                        label="Agregar producto"
                        onClick={nuevoProducto}
                    />

                </NeoButtonSection>
        </NeoGridContainer>

        <NeoTable
            value={carrito}
            rows={10}
            emptyMessage="No hay productos en el paquete especial"
            emptyMessageSecondary="Agrega un producto al paquete especial"
            extra="no-filters">
            <NeoColumn
                field="cantidad"
                header="Cantidad"
                body={cantidadBodyTemplate} />
            <NeoColumn
                field="tipo"
                header="Producto"
                body={tipoProductoBodyTemplate} />
            <NeoColumn
                field="precioUnitario"
                header="Precio unitario"
                body={precioUnitarioBodyTemplate} />
            <NeoColumn
                header="Total"
                body={totalBodyTemplate} />
            <NeoColumn
                body={accionesBodyTemplate} />
        </NeoTable>

        <FormularioAgregarProductoModal
            mensaje="Agrega productos al paquete especial"
            onSubmit={agregaProducto}
            muestraModal={muestraModal}
            setMuestraModal={setMuestraModal}
            preciosProductos={tipoValidacion}
            autoPrecio={false}
            datosIniciales={producto}
            carrito={carrito}

        />

        {carrito.length > 0 &&    
            <NeoButtonSection align="right" >
                <NeoButtonMain label="Guardar" onClick={enviarFormulario} />
            </NeoButtonSection>
        }
    </>);
};


export default ClientesPaquetesEspeciales;