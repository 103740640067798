import React from "react";

const BaseButtonSection = (props) => {
  return (<div className={`
    ${props.custom ? props.custom : ""}
    ${props.align ? props.align : ""} 
    ${props.extra ? props.extra : ""} `}>
    {props.children}
  </div>);
};

export default BaseButtonSection;

