/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import NeoTable from "./neo/table/NeoTable.base";
import NeoMultiSelect from "./neo/form/NeoMultiSelect.base";
import { aCadenaFechaConFormatoLocalYHora } from "../../consts/generales";
import { Column } from 'primereact/column';
import { DateTime } from "luxon";
import NeoTooltip from './neo/overlay/NeoTooltip.base';
import NeoCalendar from './neo/form/NeoCalendar.base';
import { obtieneTipoIp, capitalizar } from 'consts/generales';

const TablaBitacora = (props) => {
    const componente = useComponent();
    const dt = useRef(null);
    const [tiposEventosBitacora, setTiposEventosBitacora] = componente.useCustomState([]);
    const [tipoEventoSeleccionado, setTipoEventoSeleccionado] = componente.useCustomState([]);
    const [usuariosBitacora, setUsuariosBitacora] = componente.useCustomState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = componente.useCustomState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props?.eventosBitacora) {
            setTiposEventosBitacora(props.eventosBitacora);
        }

        if (props?.datosIniciales) {
            if (Array.isArray(props.datosIniciales)) {
                const usuariosUnicos = [...new Set(props.datosIniciales.map(data => data.persona))];
                const objetoUsuariosUnicos = usuariosUnicos.map((usuario) => {
                    return {
                        nombre: usuario
                    }
                });
                setUsuariosBitacora(objetoUsuariosUnicos);
            }
        }
    }, [props])

    const fechaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha</span>
            {aCadenaFechaConFormatoLocalYHora(rowData.fecha)}
        </>);
    }

    const personaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Usuario</span>
            {rowData.persona}
        </>);
    }

    const eventoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Evento</span>
            {rowData.evento}
        </>);
    }

    const ipBodyTemplate = (rowData) => {
        const body = (ip) => {
            const tipo = obtieneTipoIp(ip);
            if (tipo) {
                const texto = `${tipo.nombre} : ${ip}`;
                return (
                    <span className="ipv-cell tooltip-ip" data-pr-tooltip={texto}>
                        <NeoTooltip
                            target=".tooltip-ip"
                            mouseTrack
                            mouseTrackLeft={10}
                        />
                        <span className="ipv-data" title={ip}>{ip}</span>
                        <span className="ipv-label">{tipo.nombre}</span>
                    </span>
                );
            }
            return '';
        }

        return (<>
            <span className="p-column-title">IP</span>
            {body(rowData.ip)}
        </>);
    }

    const ubicacionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Ubicación</span>
            {rowData.ciudad}
        </>);
    }

    const eventoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {capitalizar(nombre)}
                </span>
            </div>
        );
    };

    const onCambiaEvento = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'evento', 'in');
        setTipoEventoSeleccionado(e.value);
    }

    const filtroEvento = <NeoMultiSelect
        value={tipoEventoSeleccionado}
        options={tiposEventosBitacora}
        itemTemplate={eventoFilterTemplate}
        selectedItemTemplate={eventoFilterTemplate}
        onChange={onCambiaEvento}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const usuarioFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {capitalizar(nombre)}
                </span>
            </div>
        );
    };

    const onCambiaUsuario = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'persona', 'in');
        setUsuarioSeleccionado(e.value);
    }

    const filtroUsuario = <NeoMultiSelect
        value={usuarioSeleccionado}
        options={usuariosBitacora}
        itemTemplate={usuarioFilterTemplate}
        selectedItemTemplate={usuarioFilterTemplate}
        onChange={onCambiaUsuario}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaFecha = (e) => {
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL-dd');
            console.log(fecha);
        }

        dt.current.filter(fecha, 'fecha', 'contains');
        setFechaSeleccionada(e.value);
    }

    const filtroFecha = <NeoCalendar
        value={fechaSeleccionada}
        onChange={onCambiaFecha}
        dateFormat="dd MM yy"
        placeholder="Fecha"
        mask="99/99/9999"
    />

    return (<>

        <NeoTable
            // selectionMode="single"
            value={props.datosIniciales || []}
            paginator
            rows={10}
            removableSort
            ref={dt}
            loading={props.cargando}
            emptyMessage="No hay datos de registros" >


            <Column
                field="fecha"
                header="Fecha"
                filter
                sortable
                filterElement={filtroFecha}
                body={fechaBodyTemplate}
            />

            <Column
                field="persona"
                header="Usuario"
                filter
                sortable
                filterElement={filtroUsuario}
                body={personaBodyTemplate}
            />

            <Column
                field="evento"
                header="Evento"
                filter
                sortable
                filterElement={filtroEvento}
                body={eventoBodyTemplate}
            />

            <Column field="ip" header="IP" filter sortable
                body={ipBodyTemplate}
                filterMatchMode="contains"
                className='text-overflow' />

            <Column field="ciudad" header="Ubicación" filter sortable
                body={ubicacionBodyTemplate}
                filterMatchMode="contains" />

            {/* <Column field="nota" header="Notas" filter sortable
                body={notaBodyTemplate}
                filterMatchMode="contains" /> */}

        </NeoTable>
    </>)
}


export default TablaBitacora;