import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import FormularioAdministracionGeneral from "components/common/FormularioAdministracionGeneral";
import NeoTitleMain from '../../common/neo/title/NeoTitleMain.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import Icono from 'design/assets/img/mnv/icons/icon-config.svg';
import { useComponent } from 'hooks';


const obtieneValidacionesNuevosClientes = functions.httpsCallable("validacionesNuevoUsuario-listar");
const guardaValidacionesNuevosClientes = functions.httpsCallable("validacionesNuevoUsuario-guardaPorLista");

const obtienePreciosPorOmision = functions.httpsCallable("precios-obtienePreciosPorOmision");
const guardaPreciosPorOmision = functions.httpsCallable("precios-establecePreciosPorOmision");

const obtieneDnOmision = functions.httpsCallable("obtieneDnOmision");
const estableceDnOmision = functions.httpsCallable("estableceDnOmision");


const AdmininstracionGeneral = () => {
    const componente = useComponent();
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [validacionesGratis, setValidacionesGratis] = componente.useCustomState([]);
    const [preciosPorOmision, setPreciosPorOmision] = componente.useCustomState([]);
    const [listaDns, setListaDns] = componente.useCustomState([]);
    const [dnOmision, setDnOmision] = componente.useCustomState();
    let historial = useHistory();

    const [seCargoPrecios, setSeCargoPrecios] = componente.useCustomState(false);
    const [seCargoValidaciones, setSeCargoValidaciones] = componente.useCustomState(false);
    const [seGuardoPrecios, setSeGuardoPrecios] = componente.useCustomState(false);
    const [seGuardoValidaciones, setSeGuardoValidaciones] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtienePreciosPorOmision()
            .then((resp) => {
                setPreciosPorOmision(resp.data)
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSeCargoPrecios(true);
            });

        obtieneValidacionesNuevosClientes()
            .then((resp) => {
                setValidacionesGratis(resp.data)
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSeCargoValidaciones(true);
            })

        obtieneListaDns();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneListaDns = () => {
        const fnListaDns = functions.httpsCallable('listaDns');
        fnListaDns()
            .then((resp) => {
                setListaDns(resp.data);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });

        obtieneDnOmision()
            .then((resp) => {
                setDnOmision(resp.data.personalId);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    const guardaDatos = (datos) => {
        guardaValidacionesNuevosClientes(validacionesGratis)
            .then((respuesta) => {
                setSeGuardoValidaciones(true);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });

        guardaPreciosPorOmision(preciosPorOmision)
            .then((respuesta) => {
                setSeGuardoPrecios(true);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });

        estableceDnOmision(datos)
            .then((respuesta) => {
                setSeGuardoPrecios(true);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }


    useEffect(() => {
        if (seCargoPrecios && seCargoValidaciones) {
            setSpinnerCargando(false);
        }
    }, [seCargoPrecios, seCargoValidaciones]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (seGuardoPrecios && seGuardoValidaciones) {
            despliegaMensaje({
                severity: "success",
                summary: "Configuraciones generales",
                detail: "Han sido guardadas correctamente",
            });
            historial.goBack();
            setSeGuardoPrecios(false);
            setSeGuardoValidaciones(false);
        }
    }, [seGuardoPrecios, seGuardoValidaciones]); // eslint-disable-line react-hooks/exhaustive-deps


    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono}
                title="Administracion general" />
        </NeoGridContainer>

        <FormularioAdministracionGeneral
            validacionesGratis={validacionesGratis}
            preciosGenerales={preciosPorOmision}
            dnOmision={dnOmision}
            listaDns={listaDns}
            onSubmit={guardaDatos}
        />
    </>);
};


export default AdmininstracionGeneral;