import React, { useEffect, useState } from 'react';
import BaseCheckbox from '../../sisu/form/BaseCheckbox.sisu';

const NeoCheckbox = (props) => {
  const id = props.id;
  const value = props.value;
  const onChange = props.onChange;
  const label = props.label;
  const [style, setStyle] = useState()

  useEffect(() => {
    let mount = true
    if (mount) {
      if (props.error)
        setStyle(`${props.className} p-invalid p-mr-2`);
      else
        setStyle(props.className)
    }
    return () => {
      mount = false
    };
  }, [props.error, props.className])

  return (<>
    <div className={`p-field p-col ${props.extra ? props.extra : ""} ${props.col ? `p-col-${props.col}` : ""} ${props.md ? `p-md-${props.md}` : ""} ${props.sm ? `p-sm-${props.sm}` : ""}`}>
      <span className="p-field-checkbox">
        <BaseCheckbox id={id} checked={value} onChange={onChange} className={style} disabled={props.disabled} />
        <label htmlFor={id}>{label}</label>

      </span>
      {
        props.error
          ? <small className="p-invalid p-d-block">
            {props.error}
          </small>
          : null
      }
    </div>
  </>);
};

export default NeoCheckbox;
