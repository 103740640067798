import NeoModal from './neo/overlay/NeoModal.base';
import NeoGridContainer from './neo/layout/NeoGridContainer.base';
import NeoButtonSection from './neo/layout/NeoButtonSection.base';
import NeoButtonMain from './neo/button/NeoButtonMain.base';
import NeoInputText from './neo/form/NeoInputText.base';
import NeoInputTextarea from './neo/form/NeoInputTextarea.base';
import { confirmDialog } from 'primereact/confirmdialog';
import { useComponent } from 'hooks';

const ModalPagoManual = (props) => {
    const componente = useComponent();
    const [referencia, setReferencia] = componente.useCustomState('');
    const [nota, setNota] = componente.useCustomState('');
    const [errores, setErrores] = componente.useCustomState({});

    const validaFormulario = () => {
        const errores = {
            referencia: "",
            nota: ""
        };

        let hayErrores = false;

        if (!referencia) {
            errores.referencia = 'Campo requerido';
            hayErrores = true;
        }
        if (!nota) {
            errores.nota = 'Campo requerido';
            hayErrores = true;
        }

        setErrores(errores);
        return !hayErrores;
    }

    const onEnviarFormulario = () => {
        if (validaFormulario()) {
            if (props.confirmDialog) {
                confirmDialog({
                    message: '¿Deseas marcar esta orden como pagada?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Si',
                    rejectLabel: 'No',
                    accept: () => enviarFormulario()
                });
            } else {
                enviarFormulario();
            }
        }
    }

    const enviarFormulario = () => {
        if (typeof props.onEnviarFormulario === 'function') {
            props.onEnviarFormulario({
                referencia,
                nota
            });
        }
    }

    return (
        <NeoModal
            title="Marcar como pagada"
            onClickBtnCerrar={props.onClickBtnCerrar}
            visible={props.visible} >

            <NeoGridContainer>
                <NeoInputText
                    value={referencia}
                    onChange={(e) => setReferencia(e.target.value)}
                    label="Referencia"
                    col="6"
                    error={errores.referencia}
                />
                <NeoInputTextarea
                    rows={4}
                    label="Nota"
                    md="12"
                    value={nota}
                    onChange={(e) => setNota(e.target.value)}
                    error={errores.nota}
                />
            </NeoGridContainer>
            <NeoButtonSection align="right">
                <NeoButtonMain label="Guardar" onClick={onEnviarFormulario} />
            </NeoButtonSection>
        </NeoModal>
    );
}

ModalPagoManual.defaultProps = {
    confirmDialog: true
}

export default ModalPagoManual;