import { useEffect } from 'react';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleMain from '../../common/neo/title/NeoTitleMain.base';
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import TablaContratos from "components/common/TablaContratos";
import { useComponent } from 'hooks';

const getListaContratos = functions.httpsCallable("contratos-listaContratosConResumen");
const obtenerTipoValidaciones = functions.httpsCallable('validacion-listarTipos');
const obtenerEstados = functions.httpsCallable('contratos-listarEstados');

const Contratos = () => {
    const componente = useComponent();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [contratos, setContratos] = componente.useCustomState();
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [estados, setEstados] = componente.useCustomState([]);
    // const [cargando, setCargando] = useState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneDatos();
        obtieneTiposValidacion();
        obtieneEstados();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = async () => {
        getListaContratos().then((resp) => {
            setContratos(resp.data);
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const obtieneTiposValidacion = () => {
        obtenerTipoValidaciones()
            .then((respuesta) => {
                setTiposValidaciones(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneEstados = () => {
        obtenerEstados()
            .then((respuesta) => {
                setEstados(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }


    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} title="Contratos" />
        </NeoGridContainer>

        <TablaContratos
            datos={contratos}
            tiposValidaciones={tiposValidaciones}
            estados={estados}
            cargando={cargando} />
    </>);
};


export default Contratos;