import React from "react";
import { Button } from 'primereact/button';


const BaseButtonSecondary = (props) => {
  return (<>
    <Button className={`
      ${props.custom ? props.custom : ""} 
      ${props.extra ? props.extra : ""}`}
      {...props} >

    </Button>
  </>);
};

export default BaseButtonSecondary;