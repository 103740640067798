import React from "react";
import BaseSingleContainer from "../../sisu/layout/BaseSingleContainer.sisu";

const NeoSingleContainer = (props) => {
  return (
  <BaseSingleContainer 
  custom="single-background" subCustom="single-container" {...props}>
    {props.children}
  </BaseSingleContainer>
  );
};

export default NeoSingleContainer;