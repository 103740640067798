import { useEffect } from 'react';
import { functions } from "config/firebase";
import { useLocation, Redirect } from "react-router-dom";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleMain from '../../../common/neo/title/NeoTitleMain.base';
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import NeoMessageLoading from "components/common/neo/messages/NeoMessageLoading.base";
import RUTAS from "components/router/Rutas.json";
import { useComponent, useUsuario } from 'hooks';
import NeoGridContainer from '../../../common/neo/layout/NeoGridContainer.base';

const procesaPagoBackend = functions.httpsCallable('pagos-procesa');


const PagosProcesando = () => {
    const componente = useComponent();
    const usuario = useUsuario();
    const despliegaMensaje = useMensajeGlobal();
    const ubicacion = useLocation();
    const [irADetalles, setIrADetalles] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        verificaPago();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const verificaPago = async () => {
        console.log(ubicacion);
        let session_id = new URLSearchParams(ubicacion.search).get("session_id")
        if (!session_id) {
            despliegaMensaje({
                severity: "error",
                summary: `Sin referencia de pago`,
                detail: "No se ha podido realizar el cargo"
            });
            return;
        }
        procesaPagoBackend({
            sessionId: session_id
        }).then((resp) => {
            usuario.recargar();
            
            if (resp.data.ordenId) {
                setIrADetalles(resp.data.ordenId)
            }

            despliegaMensaje({
                severity: "success",
                summary: ``,
                detail: "El pago se ha efectuado correctamente"
            });
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }


    return (<>
        <NeoGridContainer>
            <NeoTitleMain
                icon={Icono}
                title="Procesando pago" />
        </NeoGridContainer>
        <NeoMessageLoading col={12} />

        {
            irADetalles
                ? <Redirect push to={{
                    pathname: RUTAS.clientes.ordenes.detalle,
                    state: { ordenId: irADetalles, irAtras: false }
                }} />
                : null
        }
    </>);

};


export default PagosProcesando;