import React from "react";
import { ConfirmDialog } from 'primereact/confirmdialog';

const BaseDialogConfirm = (props) => {

  return (
    <ConfirmDialog {...props}>
      {props.children}
    </ConfirmDialog>
  )
};


export default BaseDialogConfirm;