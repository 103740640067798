/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import NeoButtonSection from "./neo/layout/NeoButtonSection.base";
import NeoButtonMain from "./neo/button/NeoButtonMain.base";
import FormularioSeleccionarTipoPagoModal from "components/common/FormularioSeleccionarTipoPagoModal";

const BotonPagarOrden = (props) => {
    const [mostrarTiposPagos, setMostrarTiposPagos] = useState(false);

    return (props?.orden?.orden_pago_estado_nombre === 'Pendiente de pago' &&
    <>
            <FormularioSeleccionarTipoPagoModal
                muestraModal={mostrarTiposPagos}
                setMuestraModal={(mostrar) => setMostrarTiposPagos(mostrar)}
                checkoutTDC={props.checkoutTDC}
                obtieneCuentaClabeSTP={props.obtieneCuentaClabeSTP}
                orden={props.orden}
            />
            <NeoButtonSection align="right">
                <NeoButtonMain
                    label="Pagar orden"
                    onClick={() => setMostrarTiposPagos(true)}
                />
            </NeoButtonSection>
    </>
    )
}

export default BotonPagarOrden;