import React from 'react';

export const contractNumber = (number, precision = 1) => {
    const abbrev = ['K', 'M', 'B'];
    let base = Math.floor(Math.log(Math.abs(number)) / Math.log(1000));
    const suffix = abbrev[Math.min(2, base - 1)];
    base = abbrev.indexOf(suffix) + 1;

    const round = (numero, precision) => {
        const prec = Math.pow(10, precision);
        return Math.round(numero * prec) / prec;
    }

    return suffix ? round(number / Math.pow(1000, base), precision) + suffix : '' + number;
}

const NeoChartDataLegend = (props) => {
	const fact = props.fact ? props.fact : "0";
	const textFact = props.contractNumber ? contractNumber(fact) : fact;

	return (<>
		<div className={`${props.extra ? props.extra : ""} ${props.color ? `border-${props.color}` : ""} leyenda-grafica-unidad`} >
			<span className="leyenda-dato" >
				{textFact}
			</span>
			<span className="leyenda-etiqueta">
				{props.label}
			</span>
		</div>

	</>);
};

export default NeoChartDataLegend;