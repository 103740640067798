import React from "react";
import { formateaANumeroLocal } from "consts/generales";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoTable from "./neo/table/NeoTable.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';


const TablaPreciosGenerales = (props) => {

    const limInferiorBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Desde</span>
            {formateaANumeroLocal(rowData.limite_inferior)}
        </>);
    }

    const limSuperiorBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Hasta</span>
            {formateaANumeroLocal(rowData.limite_superior)}
        </>);
    }

    const precioPrepagoBodyTemplate = (rowData) => {
        const onPrecioCambia = (nuevo_precio) => {
            rowData.precio_prepago = Number(nuevo_precio);
        }

        return (<>
            <span className="p-column-title">Prepago</span>
            <InputNumber
                value={Number(rowData['precio_prepago'])}
                onValueChange={(e) => onPrecioCambia(e.target.value)}
                mode="currency"
                currency="MXN"
                locale="es-MX"
                minFractionDigits={1}
                maxFractionDigits={4}
                min={0} max={1000}
                size={7}
            />
        </>);
    }

    const precioPostpagoBodyTemplate = (rowData) => {
        const onPrecioCambia = (nuevo_precio) => {
            rowData.precio_postpago = Number(nuevo_precio);
        }

        return (<>
            <span className="p-column-title">Postpago</span>
            <InputNumber
                value={Number(rowData['precio_postpago'])}
                onValueChange={(e) => onPrecioCambia(e.target.value)}
                mode="currency"
                currency="MXN"
                locale="es-MX"
                minFractionDigits={1}
                maxFractionDigits={4}
                min={0} max={1000}
                size={7}
            />
        </>);
    }


    return (
        <NeoColumn md="6">
            {props.titulo &&
                <NeoInnerTitle extra="primero">
                    {props.titulo}
                </NeoInnerTitle>
            }
            <NeoTable value={props.datos} editMode="cell" >
                <Column header="Desde" body={limInferiorBodyTemplate} />
                <Column header="Hasta" body={limSuperiorBodyTemplate} />
                <Column header="Prepago" body={precioPrepagoBodyTemplate} />
                <Column header="Postpago" body={precioPostpagoBodyTemplate} />

            </NeoTable>
        </NeoColumn>
    );
}

export default TablaPreciosGenerales;