import { useEffect } from "react";
import NeoCard from "./neo/panel/NeoCard.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoInnerSubtitle from "./neo/title/NeoInnerSubtitle.base";
import Notificaciones from "consts/notificaciones";
import { 
    ESTADO as NotificacionESTADO, 
    TIPO as NotificacionTIPO,
    NIVEL as NotificacionNIVEL
} from 'consts/notificaciones/constantes';
import { useComponent } from "hooks";

const NotificacionesDashboard = () => {
    const componente = useComponent({
        data: {
            notificaciones: []
        }
    });
    const notificaciones = Notificaciones();

    useEffect(() => {
        componente.data.notificaciones = notificaciones.listar({
            tipo: NotificacionTIPO.DASHBOARD
        });
    }, [notificaciones.lista])

    const obtieneIcono = (nivel) => {
        switch (nivel) {
            case NotificacionNIVEL.EMERGENCIA:
                return 'pi pi-ban';
            case NotificacionNIVEL.MUY_URGENTE:
                return 'pi pi-exclamation-triangle';
            case NotificacionNIVEL.URGENTE:
                return 'pi pi-exclamation-circle';
            case NotificacionNIVEL.MENOR_URGENCIA:
                return 'pi pi-globe';
            case NotificacionNIVEL.NO_URGENTE:
                return 'pi pi-circle';
            default:
                return '';
        }
    }

    const obtieneClaseEstado = (estado) => {
        switch (estado) {
            case NotificacionESTADO.PENDIENTE:
                return 'pendiente';
            case NotificacionESTADO.ABIERTA:
                return 'abierta';
            default:
                return '';
        }
    }

    const obtieneClaseNivel = (nivel) => {
        switch (nivel) {
            case NotificacionNIVEL.EMERGENCIA:
                return 'emergencia';
            case NotificacionNIVEL.MUY_URGENTE:
                return 'muy_urgente';
            case NotificacionNIVEL.URGENTE:
                return 'urgente';
            case NotificacionNIVEL.MENOR_URGENCIA:
                return 'menor_urgencia';
            case NotificacionNIVEL.NO_URGENTE:
                return 'no_urgente';
            default:
                return '';
        }
    }

    return (
    <NeoCard>
        <NeoInnerTitle extra="primero">Últimas notificaciones</NeoInnerTitle>

        {componente.data.notificaciones.map((notificacion) => (
            <div 
                className={`cursor-pointer
                    ${obtieneClaseEstado(notificacion.estado)}
                    ${obtieneClaseNivel(notificacion.nivel)}
                `}
                key={notificacion.codigo}
                onClick={notificacion.onClick}
            >
                <NeoCard>
                    <NeoColumn col="1" extra="p-pb-0">
                        <i className={obtieneIcono(notificacion.nivel)}/>
                    </NeoColumn>
                    <NeoColumn col="11">
                        <NeoInnerSubtitle extra="p-text-bold p-pb-0">{notificacion.titulo}</NeoInnerSubtitle>
                    </NeoColumn>
                    {notificacion.descripcion &&
                        <NeoColumn extra="p-pt-0">
                            {notificacion.descripcion}
                        </NeoColumn>
                    }
                </NeoCard>
            </div>
        ))

        }
    </NeoCard>
    );
}

export default NotificacionesDashboard;