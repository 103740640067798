import React from 'react';
import BaseDialog from '../../sisu/overlay/BaseDialog.sisu';

const SDDialogo = (props) => {

  return (
    <BaseDialog {...props} draggable={false} />
  )
};


export default SDDialogo;