import React, { useState, useEffect } from "react";
import { functions } from "config/firebase";
import useProgressSpinner from "hooks/useProgressSpinner"
import { aCadenaFechaConFormatoLocal } from "consts/generales";
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoDataSecond from "../../common/neo/data/NeoDataSecond.base";
import NeoCard from "../../common/neo/panel/NeoCard.base";
import NeoColumn from "../../common/neo/layout/NeoColumn.base";
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import CardResumenResultados from 'components/common/CardResumenResultados';
import TablaResultadoValidacion from "components/common/TablaResultadoValidacion"
import NeoButtonToggle from "../../common/neo/button/NeoButtonToggle.base";
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';
import { useComponent } from "hooks";
import { VALIDACION_ESTADO } from "consts/db.const";
import NeoCardMessage from "components/common/neo/panel/NeoCardMessage.base";
import customStorage from "consts/customStorage";

const ValidacionesDetalle = (props) => {
    const componente = useComponent();
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const [validacionId, setValidacionId] = componente.useCustomState();
    const [resumenGeneral, setResumenGeneral] = componente.useCustomState();
    const [resumenCorreos, setResumenCorreos] = componente.useCustomState();
    const [resumenTelefonos, setResumenTelefonos] = componente.useCustomState();
    const [resultadoValidacion, setResultadoValidacion] = componente.useCustomState();
    const [archivoResultados, setArchivoResultados] = componente.useCustomState();
    const [toggleDetalleResumen, setToggleDetalleResumen] = componente.useCustomState(true);
    const [error, setError] = useState("");
    const [irAtras, setIrAtras] = componente.useCustomState(props.irAtras);
    const [urlArchivoResultados, setUrlArchivoResultados] = componente.useCustomState();
    const [nombreArchivoResultados, setNombreArchivoResultados] = componente.useCustomState();

    useEffect(() => {
        if (props?.location?.state?.id) {
            if(props?.location?.state?.irAtras !== undefined) {
                setIrAtras(props?.location?.state?.irAtras);
            }
            
            setValidacionId(props.location.state.id);
        } else {
            const urlPath = window.location.href;
            var url = new URL(urlPath);
            const id = Number(url.searchParams.get('id'));
            if (id) {
                window.history.replaceState(null, null, window.location.pathname);
                setValidacionId(id);
                setIrAtras(false);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (!validacionId) {
            return;
        }

        obtieneResumenValidacion();
        obtieneResultadoValidacion();

    }, [validacionId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(resumenGeneral) {
            obtenerArchivoDeDescarga();
        }
    }, [resumenGeneral])

    const obtieneResumenValidacion = () => {
        const getResumenValidacion = functions.httpsCallable("getResumenValidacion");

        getResumenValidacion({ validacionId: validacionId })
            .then((resp) => {
                setResumenGeneral(resp.data.general);
                setResumenCorreos(resp.data.correos);
                setResumenTelefonos(resp.data.telefonos);
            }).catch((error) => {
                console.log("Error: ", error);
                setError('No tienes permisos para mostrar los detalles de la validación');
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    const obtieneResultadoValidacion = () => {
        const getResultadoValidacionUnitaria = functions.httpsCallable('getResultadoValidacionUnitaria');

        getResultadoValidacionUnitaria({ validacionId: validacionId })
            .then((resp) => {
                resp.data.length && setResultadoValidacion(resp.data)
            }).catch((error) => {
                console.log("Error: ", error);
                setError('No tienes permisos para mostrar los detalles de la validación');
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    const obtenerArchivoDeDescarga = async () => {
        let archivoResultados;
        const path =
            "clientes/"
            + resumenGeneral?.clienteId
            + "/validaciones/"
            + resumenGeneral.id;
        
        const storageInstance = customStorage(path);
        archivoResultados = await storageInstance.buscar("/Resultado.csv");
        
        if(!archivoResultados) {
            archivoResultados = await storageInstance.buscar("/Resultado.xlsx");
        }

        if(archivoResultados) {
            const infoArchivo = await archivoResultados.obtenerDetalles();
            const nombre = resumenGeneral.referencia.split('.').slice(0, -1).join('.');
            const renombrado = `${nombre}_resultados.${infoArchivo.extension}`;
            const urlDescarga = await archivoResultados.obtenerUrlDescarga();

            setUrlArchivoResultados(urlDescarga);
            setNombreArchivoResultados(renombrado);
            setArchivoResultados(archivoResultados);
            setToggleDetalleResumen(true);
        }
    }

    const procesaMensajeEstado = () => {
        let titulo;
        let mensaje;

        switch (resumenGeneral?.estadoId) {
            case VALIDACION_ESTADO.CANCELADO:
                titulo = "Proceso cancelado"
                mensaje = "No fue posible procesar el archivo, verifica que el formato sea el correcto o contácta con tu DN para más información."
                break;
            case VALIDACION_ESTADO.PROCESANDO:
                titulo = "Archivo en proceso"
                mensaje = "Te enviaremos un correo cuando se finalicen las validaciones."
                break;
            default:
                break;
        }

        if (titulo && mensaje) {
            return <NeoCardMessage title={titulo} message={mensaje} />;
        } else {
            return;
        }
    };

    return (<>
        <NeoGridContainer >
            <NeoTitleSecond col="12" md="6"
                title={resumenGeneral && resumenGeneral.id
                    ? `Validación: #${resumenGeneral.id}`
                    : "Validación: "} icon={Icono}
                    goback={irAtras} />

            {resumenGeneral?.estadoId === VALIDACION_ESTADO.FINALIZADO &&
                <NeoButtonSection align="right" col="12" md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column">

                    {archivoResultados
                        ?
                            <NeoButtonMain
                                label="Descargar resultados"
                                // loading={descargandoArchivoResultados}
                                onClick={() => {
                                    const win = window.open(urlArchivoResultados, '_blank');
                                    win.focus();
                                }} />
                        : error ? "Detalles no disponibles" :
                            <NeoButtonToggle extra="no-toggle"
                                onLabel="Ver detalles"
                                offLabel="Ver resumen"
                                checked={toggleDetalleResumen}
                                onChange={(e) => setToggleDetalleResumen(e.value)} />

                    }
                </NeoButtonSection>
            }
        </NeoGridContainer>

        {
            toggleDetalleResumen && resumenGeneral
                ? <NeoCard>
                    <NeoColumn col="auto" >
                        <NeoDataSecond
                            label="Validaciones realizadas"
                            fact={resumenGeneral.cantidad
                                ? resumenGeneral.cantidad.toLocaleString('es-MX')
                                : "‒"
                            } />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Origen"
                            fact={resumenGeneral.referencia || ""} />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Fecha de creación"
                            fact={aCadenaFechaConFormatoLocal(resumenGeneral.fecha_creacion)} />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Fecha de expiración"
                            fact={aCadenaFechaConFormatoLocal(resumenGeneral.fecha_expiracion)} />
                    </NeoColumn>
                </NeoCard>
                : null
        }

        {
            procesaMensajeEstado()
        }

        {
            resumenGeneral?.estado === "finalizado"
                && toggleDetalleResumen
                && resumenCorreos?.tipoResultado?.length
                ? <CardResumenResultados extra="mail-card"
                    titulo="Resultado correos electrónicos"
                    graficas={[
                        {
                            datos: resumenCorreos.tipoResultado,
                            titulo: "Tipo de resultados"
                        },
                    ]}
                    detallesSecundarios={[
                        {
                            datos: resumenCorreos.tipoDescripcion,
                            titulo: "Detalle de validaciones"
                        }
                    ]}
                />
                : null
        }


        {
            toggleDetalleResumen
                && resumenTelefonos?.tipoResultado?.length
                ? <CardResumenResultados extra="phone-card"
                    titulo="Resultado teléfonos celulares"
                    graficas={[
                        {
                            datos: resumenTelefonos.tipoResultado,
                            titulo: "Tipo de resultados"
                        },
                    ]}
                    detallesSecundarios={[
                        {
                            datos: resumenTelefonos.tipoDescripcion,
                            titulo: "Detalle de validaciones"
                        }
                    ]}
                />
                : null
        }


        {
            !toggleDetalleResumen && resultadoValidacion
                ? <TablaResultadoValidacion datos={resultadoValidacion} />
                : null
        }

        {
            error &&
            <NeoCard>
                <NeoColumn col="auto" extra="p-text-center" >
                    <b>{error}</b>
                </NeoColumn>
            </NeoCard>
        }

    </>);
};

ValidacionesDetalle.defaultProps = {
    irAtras: true
}

export default ValidacionesDetalle;
