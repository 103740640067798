/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import { useRouteMatch, Redirect } from "react-router-dom";
import { DateTime } from "luxon";
import NeoTable from "./neo/table/NeoTable.base";
import NeoMultiSelect from "./neo/form/NeoMultiSelect.base";
import NeoCalendar from "./neo/form/NeoCalendar.base";
import { capitalizar, aCadenaFechaConFormatoLocal, aDivisaLocal } from "../../consts/generales";
import { Column } from 'primereact/column';


const TablaContratos = (props) => {
    const componente = useComponent();
    const { url } = useRouteMatch();
    const [datosIniciales, setDatosIniciales] = componente.useCustomState();
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [tipoValidacionSeleccionada, setTipoValidacionSeleccionada] = componente.useCustomState([]);
    const [estados, setEstados] = componente.useCustomState([]);
    const [estadoSeleccionado, setEstadoSeleccionado] = componente.useCustomState([]);
    const [autoRenovacionSeleccionado, setAutoRenovacionSeleccionado] = componente.useCustomState([]);
    const [fechaInicioSeleccionada, setFechaInicioSeleccionada] = componente.useCustomState();
    const [fechaFinSeleccionada, setFechaFinSeleccionada] = componente.useCustomState();
    const dt = useRef(null);
    const [irADetalles, setIrADetalles] = componente.useCustomState(false);

    const listaAutoRenovacion = [{
        nombre: "Desactivada",
        valor: "false"
    }, {
        nombre: "Activada",
        valor: "true"
    }];

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        setDatosIniciales(props.datos);

        if (Array.isArray(props.tiposValidaciones)) {
            setTiposValidaciones(props.tiposValidaciones);
        }

        if (Array.isArray(props.estados)) {
            setEstados(props.estados);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    const iniciaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Inicia</span>
            {aCadenaFechaConFormatoLocal(rowData.fecha_inicio, "MMMM yyyy")}
        </>);
    }

    const finalizaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Finaliza</span>
            {aCadenaFechaConFormatoLocal(rowData.vigente_hasta, "MMMM yyyy")}
        </>);
    }

    const productoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Productos</span>
            Validaciones de {capitalizar(rowData.productos)}
        </>);
    }

    const estatusBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Estado</span>
            {capitalizar(rowData.estado)}
        </>);
    }

    const precioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio mensual</span>
            ${aDivisaLocal(rowData.precio_mensual)}
        </>);
    }

    const autoRenovacionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Renovación automatica</span>
            {rowData.renovacion_automatica === 1 ? "Activada": "Desactivada"}
        </>);
    }

    const onRowSelect = (event) => {
        setIrADetalles(event.data.id)
    }

    const productoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {capitalizar(nombre)}
                </span>
            </div>
        );
    };

    const onCambiaProducto = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'productos', 'in');
        setTipoValidacionSeleccionada(e.value);
    }

    const filtroProducto = <NeoMultiSelect
        value={tipoValidacionSeleccionada}
        options={tiposValidaciones}
        itemTemplate={productoFilterTemplate}
        selectedItemTemplate={productoFilterTemplate}
        onChange={onCambiaProducto}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const estadoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {capitalizar(nombre)}
                </span>
            </div>
        );
    };

    const onCambiaEstado = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }

        dt.current.filter(e.value, 'estado', 'in');
        setEstadoSeleccionado(e.value);
    }

    const filtroEstado = <NeoMultiSelect
        value={estadoSeleccionado}
        options={estados}
        itemTemplate={estadoFilterTemplate}
        selectedItemTemplate={estadoFilterTemplate}
        onChange={onCambiaEstado}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const autoRenovacionFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre}
                </span>
            </div>
        );
    };

    const onCambiaAutoRenovacion = (e) => {
        let buscar = null;
        
        if (e.value.length === 0) {
            e.value = null;
        }

        if(Array.isArray(e.value)) {
            buscar = e.value.map((valor) => {
                if(valor === 'Activada') {
                    return 1;
                } else {
                    return 0;
                }
            })
        }

        dt.current.filter(buscar, 'renovacion_automatica', 'in');
        setAutoRenovacionSeleccionado(e.value);
    }

    const filtroAutoRenovacion = <NeoMultiSelect
        value={autoRenovacionSeleccionado}
        options={listaAutoRenovacion}
        itemTemplate={autoRenovacionFilterTemplate}
        selectedItemTemplate={autoRenovacionFilterTemplate}
        onChange={onCambiaAutoRenovacion}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaFechaInicio = (e) => {
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL');
        }

        dt.current.filter(fecha, 'fecha_inicio', 'contains');
        setFechaInicioSeleccionada(e.value);
    }

    const filtroFechaInicio = <NeoCalendar
        value={fechaInicioSeleccionada}
        onChange={onCambiaFechaInicio}
        view="month"
        dateFormat="MM yy"
        yearNavigator
        placeholder="Fecha"
        yearRange="2010:2050"
    />

    const onCambiaFechaFin = (e) => {
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL');
        }

        dt.current.filter(fecha, 'vigente_hasta', 'contains');
        setFechaFinSeleccionada(e.value);
    }

    const filtroFechaFin = <NeoCalendar
        value={fechaFinSeleccionada}
        onChange={onCambiaFechaFin}
        view="month"
        dateFormat="MM yy"
        yearNavigator
        placeholder="Fecha"
        yearRange="2010:2050"
    />

    return (<>
        <NeoTable
            loading={props.cargando}
            selectionMode="single"
            value={datosIniciales}
            paginator
            rows={10}
            removableSort
            refe={dt}
            emptyMessage="Actualmente no cuentas con ningún contrato"
            emptyMessageSecondary="Comunícate con tu desarrollador de negocio y consigue el mejor precio para tus validaciones"
            onRowSelect={onRowSelect} >

            <Column
                field="fecha_inicio"
                header="Inicia"
                filter
                sortable
                filterElement={filtroFechaInicio}
                body={iniciaBodyTemplate}
            />

            <Column
                field="vigente_hasta"
                header="Termina"
                filter
                sortable
                filterElement={filtroFechaFin}
                body={finalizaBodyTemplate}
            />

            <Column
                field="productos"
                header="Productos"
                filter
                sortable
                filterElement={filtroProducto}
                body={productoBodyTemplate}
            />

            <Column
                field="estado"
                header="Estado"
                filter
                sortable
                filterElement={filtroEstado}
                body={estatusBodyTemplate}
            />

            <Column field="precio_mensual" header="Precio mensual" filter sortable
                body={precioBodyTemplate}
                filterMatchMode="contains" />
            
            {props.mostrarAutoRenovacion &&
                <Column
                    field="renovacion_automatica"
                    header="Renovación automatica"
                    filter
                    sortable
                    filterElement={filtroAutoRenovacion}
                    body={autoRenovacionBodyTemplate}
                />
            }

        </NeoTable>
        {
            irADetalles
                ? <Redirect push to={{
                    pathname: `${url}/detalle`,
                    state: { id: irADetalles }
                }} />
                : null
        }
    </>)
}

TablaContratos.defaultProps = {
    mostrarAutoRenovacion: true
}

export default TablaContratos;