import NeoCard from "./neo/panel/NeoCard.base";
import NeoAccordion from './neo/panel/NeoAccordion.base';
import NeoAccordionTab from './neo/panel/NeoAccordionTab.base';

import { useComponent } from "hooks";

const SeccionPreguntasFrecuentes = () => {
    const componente = useComponent();
    const [preguntaActiva, setPreguntaActiva] = componente.useCustomState();

    return (<>
        <NeoCard>
            <NeoAccordion activeIndex={preguntaActiva} onTabChange={(e) => setPreguntaActiva(e.index)}>
                <NeoAccordionTab header="¿Que tipos de resultados existen?">
                    <ul>
                        <li><strong>Válido</strong>
                            <ul>
                                <li><strong>válido</strong> - El correo electrónico ha sido aceptado por el servidor SMTP.</li>
                            </ul>
                        </li>
                        <li><strong>Inválido</strong>
                            <ul>
                                <li><strong>Rechazado</strong> - La dirección de correo fue rechazada por el servidor SMTP.</li>
                                <li><strong>Dominio inválido</strong> - El dominio para el correo no existe.</li>
                                <li><strong>Correo inválido</strong> - No es un correo válido.</li>
                                <li><strong>SMTP inválido</strong> - El servidor SMTP regresa una respuesta inválida o inesperada.</li>
                                <li><strong>Error en escritura</strong> - El correo no tiene la sintaxis apropiada.</li>
                                <li><strong>En lista de bloqueo</strong> - El correo está marcado como bloqueado y no fué validado.</li>
                                <li><strong>No acepta correos</strong> - Estos dominios solo envían correo y no lo aceptan.</li>
                                <li><strong>Sintaxis inválida</strong> - Correos electrónicos que fallan en los protocolos de sintaxis RFC.</li>
                                <li><strong>Ha superado la cuota de buzón</strong> - Estos correos electrónicos excedieron su cuota de espacio y no aceptan correos electrónicos.</li>
                                <li><strong>Dirección de correo no encontrada</strong> - Estas direcciones de correo electrónico son válidas en sintaxis, pero no existen.</li>
                                <li><strong>Sin entrada DNS</strong> - Estos correos electrónicos son válidos en sintaxis, pero el dominio no tiene ningún registro en DNS o tiene registros DNS incompletos.</li>
                                <li><strong>Posible error de escritura</strong> - Estos son correos electrónicos de dominios populares comúnmente mal escritos.</li>
                                <li><strong>Dirección IP no enrutable</strong> - Estos dominios de correo electrónico apuntan a una dirección IP no enrutable.</li>
                            </ul>
                        </li>
                        <li><strong>Riesgoso</strong>
                            <ul>
                                <li><strong>Baja calidad</strong> - El correo tiene problemas de calidad, puede ser una dirección de bajo valor.</li>
                                <li><strong>Baja entregabilidad</strong> - El correo parece ser valido, pero la entregabilidad no está garantizada.</li>
                                <li><strong>En lista de bloqueo global</strong> - Estos correos electrónicos se encuentran en muchas listas populares de supresión global (GSL), consisten en denunciantes de ISP conocidos, denunciantes directos, direcciones compradas, dominios que no envían correo y litigantes conocidos.</li>
                                <li><strong>Posible trampa</strong> - El correo electrónico contienen palabras clave que pueden correlacionarse con posibles trampas de spam.</li>
                                <li><strong>Dirección de rol</strong> - Estos correos electrónicos pertenecen a un puesto o grupo de personas como ventas@, info@ y contacto@.</li>
                                <li><strong>Dirección de rol y acepta todo</strong> - Estos correos electrónicos se basan en roles y también pertenecen a un dominio "Atrapa todo".</li>
                                <li><strong>Dirección desechable</strong> - Correo electrónico temporal.</li>
                                <li><strong>Dirección tóxica</strong> - Correo electrónico de spam, abuso o creado por un bot.</li>
                            </ul>
                        </li>
                        <li><strong>Desconocido</strong>
                            <ul>
                                <li><strong>Sin conexión</strong> - No se ha podido conectar con el servidor SMTP.</li>
                                <li><strong>No responde</strong> - La sesión con el servidor SMTP ha caducado.</li>
                                <li><strong>SMTP no disponible</strong> - El servidor SMTP no estaba disponible para procesar la petición.</li>
                                <li><strong>Error inesperado</strong> - Ha ocurrido un error inesperado.</li>
                                <li><strong>Temporalmente fuera de servicio</strong> - Ha existido un error en el servicio y el correo no ha sido validado.</li>
                                <li><strong>Filtro anti-spam activado</strong> - Estos correos electrónicos tienen implementados sistemas anti-spam que impide validarlos.</li>
                                <li><strong>Ocurrió una excepcion</strong> - Estos correos electrónicos provocaron una excepción al validar.</li>
                                <li><strong>Falló la conexión SMTP</strong> - Estos correos electrónicos pertenecen a un servidor de correo que no permite una conexión SMTP.</li>
                                <li><strong>Desconexión del servidor</strong> - Estos correos electrónicos pertenecen a un servidor de correo que se desconecta inmediatamente al conectarse.</li>
                                <li><strong>Temporalmente no validable</strong> - Correos electrónicos en los que temporalmente no se pueden validar.</li>
                                <li><strong>El servidor SMTP no responde</strong> - Estos correos electrónicos pertenecen a un servidor de correo que no responde a los comandos de correo.</li>
                                <li><strong>El servidor SMTP no está disponible</strong> - Estos correos electrónicos pertenecen a un servidor de correo que devuelve un error temporal.</li>
                                <li><strong>Tiempo de espera excedido</strong> - Estos correos electrónicos pertenecen a un servidor de correo que responde extremadamente lento.</li>
                            </ul>
                        </li>
                    </ul>
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Qué formatos de archivo puedo subir para validar?">
                    <ul>
                        <li><strong>csv</strong></li>
                        <li>
                            <strong>xlsx</strong>
                            <ul>
                                <li>
                                    Detectamos automáticamente lo que hay en la mayoría de las columnas,
                                    en caso de algún error se recomienda que su archivo xlsx no contenga texto
                                    enriquecido para obtener más rápido este dato y mejorar la velocidad de su validación
                                </li>
                            </ul>
                        </li>
                    </ul>
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Qué métodos de pagos se aceptan?">
                    <ul>
                        <li><strong>Pagos con tarjeta de crédito y débito</strong></li>
                        <li><strong>Transferencias bancarias</strong></li>
                    </ul>
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Por qué validar mis bases de datos?">
                    Es normal tener rebotes en una campaña de mailing. El riesgo está cuando se
                    rebasa un 3% de rebote ya que, además de
                    perder contacto con tu prospecto o cliente, te expones a afectar la reputación
                    de la IP de envíos y de tu dominio,
                    teniendo como consecuencia que los correos no lleguen a bandeja de entrada sino
                    a SPAM.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Cada cuanto debo validar?">
                    Es recomendable hacerlo la primera vez que la dirección de correo electrónico
                    forma parte de tu lista.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿En qué momento conviene validar?">
                    Antes de enviar mailing a la dirección de correo electrónico. Lo ideal es
                    integrarlo en el formulario que utilices para
                    la captación ya que ahí es donde se puede alertar al usuario a corregirlo al
                    momento. Si validas un conjunto de
                    direcciones posteriormente a su captación podrás distinguir entre las existentes
                    y las inexistentes, pero ya no habrá
                    oportunidad que el usuario la corrija.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Puedo validar una cantidad grande de bases de datos?">
                    Sí. A través de un archivo en formato CSV o XLS puedes validar una gran cantidad
                    de emails (Por ejemplo 100,000
                    direcciones de email o más).
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Qué respuestas voy a obtener al validar mis bases?">
                    <ul>
                        <li>Válido: El correo electrónico existe y está disponible.</li>
                        <li>Inválido: Se tiene la certeza de que el correo ya no existe en ese
                            servidor de correo.</li>
                        <li>Riesgoso: El correo existe pero son genéricas tal como
                            ayuda@dominio.com.</li>
                        <li>Desconocido: No se pudo confirmar si existe o no.</li>

                    </ul>
                </NeoAccordionTab>
                <NeoAccordionTab header="Si valido mi base ¿ya no caen mis correos a Spam?">
                    La validación no sustituye a la voluntad de una persona a recibir tus mailings
                    por lo que además de validar siempre será
                    requerida la aceptación del usuario para llegar a bandeja de entrada.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Cuánto tiempo tarda la validación?">
                    La validación por API es en tiempo real (alrededor de 1 segundo).
                    La validación por conjunto de emails en archivo .csv o .xls depende de qué tan
                    limpia se encuentre. El NeoValidador hace
                    una segunda revisión internamente a las direcciones que resultaron como
                    desconocidas tomando un poco más de tiempo, pero
                    esto ayuda a elevar la precisión del resultado final. La estadística nos indica
                    que validamos en promedio 600 correos
                    por minuto.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Cómo se realiza la validación?">
                    Primeramente se hace una revisión por sintaxis y contra una lista de errores
                    conocidos.
                    Luego el NeoValidador realiza una consulta a los servidores MX mediante un ping
                    incluyendo el nombre de usuario para
                    corroborar la existencia.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿El usuario recibirá un mensaje de correo electrónico de verificación?">
                    No. La validación se realiza silenciosamente al usuario quien no requiere
                    recibir ningún mensaje.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Puedo ponerlo en mi sitio web y conectarlo a otros sistemas?">
                    Sí, al dar de alta tu cuenta podrás tener acceso a la documentación para
                    integrar el validador vía API REST por php,
                    javascript, node o curl.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Se garantiza el resultado del total de la base de datos?">
                    Toda la base de datos será procesada en su totalidad y el resultado del análisis
                    individual de cada dirección dependerá
                    de la disponibilidad del dominio del buzón de correo electrónico. Puedes contar
                    con una precisión del 98% en adelante.
                </NeoAccordionTab>
                <NeoAccordionTab header="¿Cómo resguardan mi información?">
                    Creamos NeoValidador teniendo en mente la seguridad como prioridad. El acceso
                    vía web va encriptado con SSL de 256 bits.
                    En nuestro data center cumplimos el estándar SOC 2. El intercambio de archivos
                    se hace mediante protocolo seguro SFTP.
                    Nuestro servicio está respaldado con un convenio de confidencialidad al cual
                    también se apega nuestro personal. Nos
                    regimos bajo las leyes de protección de datos personales. Los registros se
                    resguardan solo durante un tiempo necesario y
                    posteriormente se depuran. Aplicamos actualizaciones, parches y realizamos
                    pruebas de penetración periódicamente para
                    mantenernos seguros.
                </NeoAccordionTab>
            </NeoAccordion>

        </NeoCard>
    </>)
}

export default SeccionPreguntasFrecuentes;