/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import NeoInputFile from './neo/form/NeoInputFile.base';
import { ProgressBar } from 'primereact/progressbar';
import useMensajeGlobal from "hooks/useMensajeGlobal";
import { useComponent } from 'hooks';
import customStorage from 'consts/customStorage';

const StorageFileUpload = (props) => {
    const componente = useComponent();
    const elementoRef = useRef();
    const setMensajeGlobal = useMensajeGlobal();
    const [subiendo, _setSubiendo] = componente.useCustomState(false);
    const [porcentaje, setPorcentaje] = componente.useCustomState(0);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    const setSubiendo = (subiendo) => {
        if (typeof props.setSubiendo === 'function') {
            props.setSubiendo({
                subiendo,
                storageRef: props.storageRef
            });
        }
        _setSubiendo(subiendo);
    }

    const subir = async (evento) => {
        try {
            const storageInstance = customStorage(props.storageRef);
            await storageInstance.subir(evento.files, {
                maximoArchivos: props.maximoArchivos,
                extensionesPermitidas: props.extensionesPermitidas,
                capacidadMaximaArchivo: props.capacidadMaximaArchivo,
                porArchivo: (archivo) => {
                    setSubiendo(true);
                },
                porEjecucion: (datos) => {
                    setPorcentaje(datos.progreso);
                },
                alFinalizar: (resultados) => {
                    setSubiendo(false);

                    if (typeof props.alFinalizar === 'function') {
                        props.alFinalizar({
                            resultados,
                            storageRef: props.storageRef
                        });
                    }
                }
            });
        } catch (error) {
            elementoRef.current.clear();
            setMensajeGlobal({
                severity: 'warn',
                detail: error.mensaje
            });
        }
    }

    return (<>
        {
            subiendo
                ?
                <ProgressBar
                    value={porcentaje}
                />
                :
                <NeoInputFile
                    referencia={elementoRef}
                    mode="basic"
                    name="factura"
                    customUpload
                    multiple
                    chooseLabel="Cargar archivos"
                    accept="text/xml,application/pdf"
                    uploadHandler={(e) => subir(e)}
                    auto
                />
        }
    </>);
}

export default StorageFileUpload;