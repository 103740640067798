import BaseInputTextarea from "../../sisu/form/BaseInputTextarea.sisu";

const NeoInputTextarea = (props) => {
    return (
        <div className={`p-field p-col ${props.extra ? props.extra : ""} ${props.col ? `p-col-${props.col}` : `p-col-12`} ${props.md && `p-md-${props.md}`} ${props.sm && `p-sm-${props.sm}`}`} >
            <span>{props.label}</span>
            <BaseInputTextarea {...props} className={`p-col-12 ${props.error ? 'p-invalid' : ''}`} autoResize />
            {props.error &&
                <small className="p-invalid p-d-block">
                    {props.error}
                </small>
            }
        </div>);
}

export default NeoInputTextarea;