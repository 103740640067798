import { useEffect } from "react";
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import TablaBitacora from "components/common/TablaBitacora"
import { useComponent } from "hooks";

const Bitacora = (props) => {
    const componente = useComponent({
        data: {
            datosBitacora: [],
            eventosBitacora: []
        }
    });
    const getBitacora = componente.useAsync(functions.httpsCallable("usuario-obtieneBitacora"));
    const getTiposEvento = componente.useAsync(functions.httpsCallable("usuario-bitacoraListarTiposEvento"));
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneEventosBitacora();
        obtieneTiposEventosBitacora();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const obtieneEventosBitacora = () => {
        getBitacora()
            .then((resp) => {
                componente.data.datosBitacora = resp.data;
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error: ",
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    const obtieneTiposEventosBitacora = () => {
        getTiposEvento()
            .then((resp) => {
                componente.data.eventosBitacora = resp.data;
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error: ",
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    return (<>
        <NeoGridContainer >
            <NeoTitleSecond col="12" md="6"
                // icon="{Icono}"
                title="Bitácora del sistema" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
            </NeoButtonSection>
        </NeoGridContainer>

        <TablaBitacora
            datosIniciales={componente.data.datosBitacora}
            eventosBitacora={componente.data.eventosBitacora}
            cargando={componente.isLoading}
        />
    </>);
};


export default Bitacora;
