import { functions } from "config/firebase";
import { Switch } from "react-router-dom";
import RuterRender from './RuterRender';
import PaginaBase from "components/layouts/PaginaBase";
import Cuenta from "components/views/clientes/Cuenta";
import Tablero from "components/views/clientes/Tablero";
import Estadisticas from "components/views/clientes/Estadisticas";
import Contratos from "components/views/clientes/Contratos";
import Ordenes from "components/views/clientes/Ordenes";
import OrdenDetalle from "components/views/clientes/OrdenDetalle";
import Validaciones from "components/views/clientes/Validaciones";
import ValidacionesDetalle from "components/views/clientes/ValidacionesDetalle";
import PagosHistorial from "components/views/clientes/PagosHistorial";
import PagosProcesando from "components/views/clientes/pagos/PagosProcesando";
import PagosCancelado from "components/views/clientes/pagos/PagosCancelado";
import ApiKeys from "components/views/clientes/ApiKeys";
import ApiKeysDetalle from "components/views/clientes/ApiKeysDetalle";
import ApiKeysDocumentacion from "components/views/clientes/ApiKeyDocumentacion";
import ContratosDetalle from "components/views/clientes/ContratosDetalle";
import Bitacora from "components/views/clientes/Bitacora";
import ComprarValidaciones from "components/views/clientes/ComprarValidaciones";
import Ayuda from "components/views/clientes/Ayuda";
import RUTAS from "./Rutas.json";
import PreguntasFrecuentes from "components/views/clientes/PreguntasFrecuentes";
import ModalActualizarDatosCliente from "components/common/ModalActualizarDatosCliente";
import { useComponent, useUsuario } from "hooks";

const RutasCliente = (props) => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    
    const componente = useComponent({
        data: {
            showModalActualizarDatosCliente: Boolean(persona?.cliente?.nombre === 'NeoWifi')
        }
    });

    const actualizarDatosFunctions = 
        componente.useAsync(functions.httpsCallable("clientes-actualizar"));

    const contieneId = () => {
        if(props?.location?.state?.id) {
            return true;
        }

        const urlPath = window.location.href;
        var url = new URL(urlPath);
        const id = Number(url.searchParams.get('id'));
        if (id) {
            return true;
        }

        return false;
    }

    const actualizarDatos = (datos) => {
        actualizarDatosFunctions(datos)
        .then(() => {
            usuario.recargar();
            componente.data.showModalActualizarDatosCliente = false;
        })
        .catch((error) => {
            console.error(error);
        })
    }

    return (
        <PaginaBase>
            <Switch>

                <RuterRender path={RUTAS.clientes.tablero}
                    component={Tablero} />

                <RuterRender path={RUTAS.clientes.estadisticas}
                    component={Estadisticas} />

                <RuterRender path={RUTAS.clientes.validaciones}
                    component={Validaciones} />

                <RuterRender path={RUTAS.clientes.validacionesDetalle}
                    redirect={contieneId} redirectValue={false} toPath={RUTAS.clientes.validaciones}
                    component={ValidacionesDetalle} />

                <RuterRender path={RUTAS.clientes.apikeys}
                    component={ApiKeys} />

                <RuterRender path={RUTAS.clientes.apikeysDetalle}
                    redirect={contieneId} redirectValue={false} toPath={RUTAS.clientes.apikeys}
                    component={ApiKeysDetalle} />

                <RuterRender path={RUTAS.clientes.apikeysDocumentacion}
                    redirect={contieneId} redirectValue={false} toPath={RUTAS.clientes.apikeys}
                    component={ApiKeysDocumentacion} />

                <RuterRender path={RUTAS.clientes.cuenta}
                    component={Cuenta} />

                <RuterRender path={RUTAS.clientes.bitacora}
                    component={Bitacora} />

                <RuterRender path={RUTAS.clientes.contratos}
                    component={Contratos} />

                <RuterRender path={RUTAS.clientes.contratosDetalle}
                    redirect={contieneId} redirectValue={false} toPath={RUTAS.clientes.contratos}
                    component={ContratosDetalle} />

                <RuterRender path={RUTAS.clientes.comprarValidaciones}
                    component={ComprarValidaciones} />

                <RuterRender path={RUTAS.clientes.ordenes.lista}
                    component={Ordenes} />

                <RuterRender path={RUTAS.clientes.ordenes.detalle} 
                    redirect={contieneId} redirectValue={false} toPath={RUTAS.clientes.ordenes.lista}
                    component={OrdenDetalle} />

                <RuterRender path={RUTAS.clientes.pagos.historial}
                    component={PagosHistorial} />

                <RuterRender path={RUTAS.clientes.pagos.cancelado}
                    component={PagosCancelado} />

                <RuterRender path={RUTAS.clientes.pagos.procesando}
                    component={PagosProcesando} />

                <RuterRender path={RUTAS.clientes.ayuda} 
                    component={Ayuda} />

                <RuterRender path={RUTAS.clientes.preguntasFrecuentes} 
                    component={PreguntasFrecuentes} />

            </Switch>

            <ModalActualizarDatosCliente
                header="Ingresa tus datos"
                visible={componente.data.showModalActualizarDatosCliente}
                onActualizar={actualizarDatos}
                loading={componente.isLoading}
            />
        </PaginaBase>
    );
};


export default RutasCliente;