import React from "react";
import NeoResponsiveContainer from "./NeoResponsiveContainer.base";
import { Cell, Pie, PieChart, Tooltip } from "recharts";

const formatNumber = (value, locale = 'es-MX', currency = 'MXN') => {
    try {
        value = String(value).replace(/[^0-9.]/g, "");
        return value === ""
            ? value
            : Number(value).toLocaleString(locale, {currency});
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const RADIAN = Math.PI / 180;
// { cx, cy, midAngle, innerRadius, outerRadius, percent, index}: any)
const renderCustomizedLabel = (props) => {
    const radius = props.innerRadius +
        (props.outerRadius - props.innerRadius) * 0.5;
    const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
    const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

    return (<>
        <text className="dato-ingrafica"
            x={x > props.cx ? x + (x / 10) : x - (x / 10)}
            y={y > props.cy ? y + 14 : y - 14}
            textAnchor={x > props.cx ? "start" : "end"}
            dominantBaseline="middle" >
            {`${(props.percent * 100).toFixed(0)}`}
            <tspan className="dato-ingrafica-porcentaje">%</tspan>
        </text>
    </>);
};

const customTooltip = (props) => {
    if (props?.active && props?.payload && props?.payload.length) {
        const name = props?.payload[0]?.payload[props.dataName];
        
        let value = props?.payload[0]?.value;
        value = props.formatNumber ? formatNumber(
            value, 
            props.formatNumber?.locale,
            props.formatNumber?.currency
        ) : value;

        const color = props?.payload[0]?.payload?.fill;

        return (
            <div className="custom-tooltip leyenda-gráfica-pastel">
                <div className="leyenda-grafica-unidad">
                    <span className="codigo-color" style={{ backgroundColor: color }} ></span>
                    <div className="leyenda-container" >
                        <span className="leyenda-dato" >
                            {value}
                        </span>
                        <span className="leyenda-etiqueta">
                            {name}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return null;
};

const NeoChart = (props) => {
    return (<div style={{ width: '100%', height: 230 }}>
        <NeoResponsiveContainer>
            <PieChart>
                <Pie dataKey={props.dataKey} dataName={props.dataName} label={renderCustomizedLabel}
                    data={props.data ? props.data : []}
                    fill="#8884d8" labelLine={false}
                    cx="50%" cy="50%" innerRadius="65%" outerRadius="80%"
                    paddingAngle={5}>
                    {
                        props.colors && props.data
                        && props.data.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                                fill={props.colors[index % props.colors.length]} />
                        ))
                    }
                </Pie>
                <Tooltip content={customTooltip} dataName={props.dataName} formatNumber={props.formatNumber}/>
            </PieChart>
        </NeoResponsiveContainer>
    </div>);
};

export default NeoChart;