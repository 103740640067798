/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NeoDataMain from "./neo/data/NeoDataMain.base";
import NeoDataSecond from "./neo/data/NeoDataSecond.base";
import NeoCard from "./neo/panel/NeoCard.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import { capitalizar, aCadenaFechaConFormatoLocal, aDivisaLocal } from "../../consts/generales";
import NeoTable from "./neo/table/NeoTable.base";
import { Column } from 'primereact/column';
import { useComponent } from 'hooks';

const DetalleContrato = (props) => {
    const componente = useComponent();
    const [contrato, setContrato] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (!props.contrato) {
            return;
        }

        setContrato(props.contrato);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contrato]);


    const tipoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Productos</span>
            Validaciones de {capitalizar(rowData.tipoValidacion)}
        </>);
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">
                Consumo mínimo validaciones
            </span>
            {aDivisaLocal(rowData.cantidad)}
        </>);
    }

    const precioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio por validación</span>
            ${aDivisaLocal(rowData.precio)}
        </>);
    }


    return (<>

        <NeoCard>
            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato
                        && contrato.fecha_inicio
                        && aCadenaFechaConFormatoLocal(contrato.fecha_inicio, "LLLL yyyy")}
                    label="Fecha de inicio" />
            </NeoColumn>

            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato
                        && aCadenaFechaConFormatoLocal(contrato.vigente_hasta, "LLLL yyyy")}
                    label="Vigente hasta" />
            </NeoColumn>

            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato
                        && contrato.estado
                        && capitalizar(contrato.estado)}
                    label="Estado" />
            </NeoColumn>

            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato && contrato.meses_vigencia}
                    label="Meses de duración" />
            </NeoColumn>
            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato
                        && `$${aDivisaLocal(contrato.precio_mensual)}`}
                    label="Precio mensual" />
            </NeoColumn>
            <NeoColumn md="4">
                <NeoDataSecond
                    fact={contrato?.renovacion_automatica === 1 
                        ? 'Activada'
                        : 'Desactivada'}
                    label="Renovación automatica" />
            </NeoColumn>
        </NeoCard>



        <NeoTable
            value={contrato && contrato.productos}
            rows={10}
            loading={props.cargando}
            emptyMessage="Error al cargar la información">

            <Column
                field="tipo"
                header="Validaciones"
                body={tipoBodyTemplate} />

            <Column
                field="cantidad"
                header="Cantidad"
                body={cantidadBodyTemplate} />

            <Column
                field="precio"
                header="Precio unitario"
                body={precioBodyTemplate} />
        </NeoTable>

    </>);
};


export default DetalleContrato;