import { useContext } from 'react';
import UsuarioContext from 'components/context/UsuarioContext';
import { functions } from "config/firebase";
import RUTAS from 'components/router/Rutas.json';

const useUsuario = () => {
    const {
        data,
        auth,
        usuarioAuth,
        persona,
        cargarDatosPersona
    } = useContext(UsuarioContext);

    const estaLogueado = () => {
        return Boolean(usuarioAuth !== null && persona);
    }

    const esAdmin = (tipoAComparar) => {
        if(estaLogueado()) {
            const rol = persona?.rol;

            if(tipoAComparar && typeof tipoAComparar === 'string') {
                return tipoAComparar === rol;
            } else {
                switch (rol) {
                    case 'admin':
                        return true;
                    default:
                        return false;
                }
            }
        }
        return false;
    }

    const esDn = (tipoAComparar) => {
        if(estaLogueado()) {
            const rol = persona?.rol;

            if(tipoAComparar && typeof tipoAComparar === 'string') {
                return tipoAComparar === rol;
            } else {
                switch (rol) {
                    case 'dn':
                    case 'dn ventas':
                        return true;
                    default:
                        return false;
                }
            }
        }
        return false;
    }

    const esEmpleado = () => {
        if(estaLogueado()) {
            const rol = persona?.rol;
            if( esDn() || esAdmin() ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const esCliente = () => {
        if(estaLogueado()) {
            const rol = persona?.rol;
            if(rol === undefined || rol === '' || rol === 'sub cuenta') {
                return true;
            }
        }
        return false;
    }

    const esSubCuenta = () => {
        if(estaLogueado()) {
            const rol = persona?.rol;
            if(rol === 'sub cuenta') {
                return true;
            }
        }
        return false;
    }

    const obtieneRutaDashboard = () => {
        if(estaLogueado()) {
            if(esEmpleado()) {
                return RUTAS.admin.tablero;
            } else if(esCliente()) {
                return RUTAS.clientes.tablero;
            }
        }

        return '/';
    }

    const recargar = async () => {
        return cargarDatosPersona();
    }

    const iniciaSesion = async (params) => {
        try {
            await auth.signInWithEmailAndPassword(
                params.correo, params.contrasena
            );

            await functions.httpsCallable('usuario-firmarBitacoraLogin')();
        } catch(error) {
            throw error;
        }
    }

    const registrar = async (params) => {
        try {
            await functions.httpsCallable('registraUsuario')(params);

            await iniciaSesion({
                correo: params.correo,
                contrasena: params.contrasena
            });

            return true;
        } catch(error) {
            throw error;
        }
    }

    const cerrarSesion = async () => {
        try {
            await auth.signOut();
            return true;
        } catch(error) {
            throw error;
        }
    }

    return {
        estaLogueado,
        esAdmin,
        esDn,
        esEmpleado,
        esCliente,
        esSubCuenta,
        obtieneRutaDashboard,
        recargar,
        iniciaSesion,
        registrar,
        cerrarSesion,
        persona,
        data
    }
}

export default useUsuario;