/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import { useRouteMatch, Redirect } from "react-router-dom";
import NeoTable from "./neo/table/NeoTable.base";
import { capitalizar, aDivisaLocal, aCadenaFechaConFormatoLocal } from "../../consts/generales";
import { DateTime } from 'luxon';
import { Column } from 'primereact/column';
import NeoCalendar from "./neo/form/NeoCalendar.base";
import NeoMultiSelect from "./neo/form/NeoMultiSelect.base";

const TablaOrdenes = (props) => {
    const componente = useComponent();
    const { url } = useRouteMatch();
    const [datosIniciales, setDatosIniciales] = componente.useCustomState();
    const [tiposOrdenes, setTiposOrdenes] = componente.useCustomState([]);
    const [tiposPagoEstado, setTiposPagoEstado] = componente.useCustomState([]);
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [tipoOrdenSeleccionada, setTipoOrdenSeleccionada] = componente.useCustomState([]);
    const [tipoPagoEstadoSeleccionado, setTipoPagoEstadoSeleccionado] = componente.useCustomState([]);
    const [tipoValidacionSeleccionada, setTipoValidacionSeleccionada] = componente.useCustomState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = componente.useCustomState();
    const dt = useRef(null);
    const [irADetalles, setIrADetalles] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (Array.isArray(props.datos)) {
            setDatosIniciales(props.datos);
        }

        if (Array.isArray(props.tiposOrdenes)) {
            setTiposOrdenes(props.tiposOrdenes);
        }

        if (Array.isArray(props.tiposPagoEstado)) {
            setTiposPagoEstado(props.tiposPagoEstado);
        }

        if (Array.isArray(props.tiposValidaciones)) {
            setTiposValidaciones(props.tiposValidaciones);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const idBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title"># Orden</span>
            {rowData.id}
        </>);
    }

    const fechaCreacionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha creación</span>
            {rowData.fecha_creacion !== null &&
                aCadenaFechaConFormatoLocal(rowData.fecha_creacion)
            }
        </>);
    }

    const conceptoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Concepto</span>
            {rowData.orden_tipo_nombre === 'Contrato' ?
                'Pago mensual'
                :
                rowData.orden_tipo_nombre
            }
        </>);
    }

    const productoBodyTemplate = (rowData) => {
        let productos = rowData.productos.split(', ');
        productos = productos.map((producto) => {
            return capitalizar(producto);
        });

        return (<>
            <span className="p-column-title">Producto</span>
            Validaciones de {productos.join(', ')}
        </>);
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {aDivisaLocal(rowData.productos_cantidad)}
        </>);
    }

    const estatusBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Estado</span>
            {capitalizar(rowData.orden_pago_estado_nombre)}
        </>);
    }

    const onRowSelect = (event) => {
        if (event?.originalEvent?.target?.nodeName === 'TD') {
            setIrADetalles(event.data.id)
        }
    }

    const conceptoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre === 'Contrato' ?
                        'Pago mensual'
                        :
                        nombre
                    }
                </span>
            </div>
        );
    };

    const onCambiaConcepto = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'orden_tipo_nombre', 'in');
        setTipoOrdenSeleccionada(e.value);
    }

    const filtroConcepto = <NeoMultiSelect
        value={tipoOrdenSeleccionada}
        options={tiposOrdenes}
        itemTemplate={conceptoFilterTemplate}
        selectedItemTemplate={conceptoFilterTemplate}
        onChange={onCambiaConcepto}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const productoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {option === undefined ?
                        nombre
                        :
                        `Validaciones de ${capitalizar(nombre)}`
                    }
                </span>
            </div>
        );
    };

    const onCambiaProducto = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'productos', 'in');
        setTipoValidacionSeleccionada(e.value);
    }

    const filtroProducto = <NeoMultiSelect
        value={tipoValidacionSeleccionada}
        options={tiposValidaciones}
        itemTemplate={productoFilterTemplate}
        selectedItemTemplate={productoFilterTemplate}
        onChange={onCambiaProducto}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const pagoEstadoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre}
                </span>
            </div>
        );
    };

    const onCambiaPagoEstado = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'orden_pago_estado_nombre', 'in');
        setTipoPagoEstadoSeleccionado(e.value);
    }

    const filtroPagoEstado = <NeoMultiSelect
        value={tipoPagoEstadoSeleccionado}
        options={tiposPagoEstado}
        itemTemplate={pagoEstadoFilterTemplate}
        selectedItemTemplate={pagoEstadoFilterTemplate}
        onChange={onCambiaPagoEstado}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaFecha = (e) => {
        // 2021-10-28
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL-dd');
            console.log(fecha);
        }

        dt.current.filter(fecha, 'fecha_creacion', 'equals');
        setFechaSeleccionada(e.value);
    }

    const filtroFecha = <NeoCalendar
        value={fechaSeleccionada}
        onChange={onCambiaFecha}
        dateFormat="dd MM yy"
        placeholder="Fecha"
        mask="99/99/9999"
    />

    return (<>
        <NeoTable selectionMode="single" value={datosIniciales}
            paginator rows={10} removableSort ref={dt}
            emptyMessage="No hay ordenes registradas"
            onRowSelect={onRowSelect} >

            <Column field="id" header="# Orden" filter sortable
                body={idBodyTemplate}
                filterMatchMode="contains" />

            <Column
                field="fecha_creacion"
                header="Fecha creación"
                filter
                sortable
                filterElement={filtroFecha}
                body={fechaCreacionBodyTemplate}
            />

            <Column
                field="orden_tipo_nombre"
                header="Concepto"
                filter
                sortable
                filterElement={filtroConcepto}
                body={conceptoBodyTemplate}
            />

            <Column
                field="productos"
                header="Producto"
                filter
                sortable
                filterElement={filtroProducto}
                body={productoBodyTemplate}
            />
            <Column
                field="productos_cantidad"
                header="Cantidad"
                filter
                sortable
                body={cantidadBodyTemplate}
                filterMatchMode="contains"
            />

            <Column
                field="orden_pago_estado_nombre"
                header="Estado"
                filter
                sortable
                filterElement={filtroPagoEstado}
                body={estatusBodyTemplate}
            />
        </NeoTable>
        {
            irADetalles
                ? <Redirect push to={{
                    pathname: `${url}/detalle`,
                    state: { id: irADetalles }
                }} />
                : null
        }
    </>)
}


export default TablaOrdenes;