import { useEffect, useContext, useRef } from "react";
import NeoHeader from "../common/neo/layout/NeoHeader.base";
import NavBarSecundarioAdmin from "components/common/NavBarSecundarioAdmin";
import NavBarSecundarioCliente from "components/common/NavBarSecundarioCliente";
import NeoMainContainer from "../common/neo/layout/NeoMainContainer.base";
import NeoNavBar from "../common/neo/menu/NeoNavBar.base";
import NeoSpinner from "../common/neo/overlay/NeoSpinner.base";
import NeoToast from '../common/neo/messages/NeoToast.base';
import { menuCliente, menuDn, menuAdmin } from "./menus.js"
import { useComponent } from "hooks";
import Notificaciones from 'consts/notificaciones';
import { 
    ESTADO as NotificacionESTADO, 
    TIPO as NotificacionTIPO
} from 'consts/notificaciones/constantes';
import { EstadoGlobal } from "components/context/EstadoGlobal";
import useUsuario from "hooks/useUsuario";
import useCliente from "hooks/useCliente";
import useTrigger from "hooks/useTrigger";
import web from 'core/scripts/web';
import cliengo from 'config/cliengo';
import freshworks from 'config/freshworks';

const PaginaBase = (props) => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    const cliente = useCliente();
    const trigger = useTrigger();
    const componente = useComponent();
    const [menu, setMenu] = componente.useCustomState([]);
    const notificaciones = Notificaciones();
    const [estadoGlobal, setEstadoGlobal, resetEstadoGlobal] = useContext(EstadoGlobal);
    const mensaje = useRef(null);

    const [timer, setTimer] = componente.useCustomState(0);
    let timerMount = true;
    const tiempoEntrePeticionesObtenerNotificaciones = (1000 * 60) * 3; // 3 min

    const linkCerrarSesion = {
        label: 
            usuario.esCliente()
            ? (usuario.esSubCuenta() && persona?.cliente?.empresa)
                ? persona.cliente.empresa
                : persona.cliente.correo
            : persona.email,
        className: "signout",
        command: async () => {
            try {
                await usuario.cerrarSesion();
            } catch (error) {
                console.error("Error al cerrar sesión");
            }
        }
    };

    useEffect(() => {
        if(usuario.esCliente()) {
            if(persona?.cliente?.fidelizado) {
                // freshworks.show();
            } else {
                // cliengo.show();
            }
        }

        return () => {
            cliengo.hide();
            freshworks.hide();
        }
    }, [usuario])

    useEffect(() => {
        if(usuario.esCliente()) {
            notificaciones.fetch();

            setTimer(setInterval(
                obtenerNotificaciones, 
                tiempoEntrePeticionesObtenerNotificaciones
            ));
        }

        return () => {
            if(usuario.esEmpleado()) {
                cliente.desmontar();
            }

            detenerTimer();
            notificaciones.clear();

            trigger.limpiar();
            
            resetEstadoGlobal();
        }
    }, []);

    const detenerTimer = () => {
        timerMount = false;
        clearTimeout(timer);
        setTimer(null);
    }

    const obtenerNotificaciones = () => {
        if(timerMount) {
            notificaciones.fetch();
        }
    }

    useEffect(() => {
        if (estadoGlobal?.mensaje) {
            mensaje.current.show(estadoGlobal.mensaje);
            setEstadoGlobal({mensaje: null});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estadoGlobal?.mensaje])

    useEffect(() => {

        const totalArchivosUploading = estadoGlobal.procesosArchivos.reduce(function(n, archivo) {
            return n + (archivo.state === 'uploading');
        }, 0);

        if(totalArchivosUploading > 0) {
            window.onbeforeunload = () => '';
        } else {
            window.onbeforeunload = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estadoGlobal?.procesosArchivos])

    // Establece menu
    useEffect(() => {
        let menu;

        switch (persona.rol) {
            case "admin": {
                menu = web.utils.cloneDeep(menuAdmin);
                break;
            }
            case "dn":
            case "dn ventas": {
                menu = web.utils.cloneDeep(menuDn);
                break;
            }
            default: {
                menu = web.utils.cloneDeep(menuCliente);

                if (persona.cliente?.plan?.nombre === 'prepago') {
                    if (persona.cliente?.contratos?.total === 0) {
                        menu = menu.map((elemento) => {
                            if (elemento?.items) {
                                elemento.items = elemento?.items.filter((subElemento) => {
                                    return subElemento.label !== 'Contratos';
                                });
                            }

                            return elemento;
                        })
                    }
                }
            }
        }

        if(!menu.find(element => element?.className === 'signout')) {
            menu.push(linkCerrarSesion);
        }

        setMenu(menu);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Centro de notificaciones
    useEffect(() => {
        if (usuario.esCliente()) {
            if (menu.length > 0) {
                const listaNotificaciones = notificaciones.listar({
                    tipo: NotificacionTIPO.BELL
                });

                const obtieneIcono = (estado) => {
                    switch (estado) {
                        case NotificacionESTADO.PENDIENTE:
                            return 'pi pi-fw pi-bell';
                        default:
                            return '';
                    }
                }

                const obtieneClase = (estado) => {
                    switch (estado) {
                        case NotificacionESTADO.PENDIENTE:
                            return 'pendiente';
                        case NotificacionESTADO.ABIERTA:
                            return 'abierta';
                        default:
                            return '';
                    }
                }

                // tabs menu
                let tabs = [...menu];

                // Busca el elemento en el menu
                const index = menu.findIndex((elemento) => {
                    return elemento.className === 'notificaciones';
                });

                // menu notificaciones
                const elementoMenu = { ...tabs[index] };
                elementoMenu.items = listaNotificaciones.map((notificacion) => {
                    return {
                        key: notificacion.id,
                        label: notificacion.titulo,
                        command: notificacion.onClick,
                        icon: obtieneIcono(notificacion.estado),
                        className: obtieneClase(notificacion.estado),
                    }
                });

                elementoMenu.label = notificaciones.listar({
                    tipo: NotificacionTIPO.BELL,
                    estado: NotificacionESTADO.PENDIENTE
                }).length || '';

                tabs[index] = elementoMenu;

                setMenu(tabs);
            }
        }

    }, [notificaciones.lista])


    return (<>
        <NeoHeader>
            <NeoNavBar items={menu}
                className='navbar-principal blue-nav p-jc-between'
            />

            {
                usuario.esCliente()
                    && <NavBarSecundarioCliente
                            botonAdquirirValidaciones={
                                persona?.cliente?.plan?.nombre === "prepago"
                            }
                        />
            }

            {
                usuario.esEmpleado()
                    && cliente.hayCliente()
                    && <NavBarSecundarioAdmin />
            }
        </NeoHeader>

        <NeoMainContainer>
            {props.children}
        </NeoMainContainer>

        <NeoToast ref={mensaje} />
        
        {estadoGlobal?.cargando &&
            <NeoSpinner />
        }
    </>);
};

export default PaginaBase;