import { useRef, useEffect } from 'react';
import firebase from 'firebase/app';
import { Link, useHistory } from "react-router-dom";
import FormularioContrasenaOlvidada from "components/common/FormularioContrasenaOlvidada";
import NeoColumn from '../common/neo/layout/NeoColumn.base';
import NeoLink from '../common/neo/button/NeoLink.base';
import NeoNote from '../common/neo/data/NeoNote.base';
import { Toast } from 'primereact/toast';
import NeoSingleContainer from "../common/neo/layout/NeoSingleContainer.base";
import NeoGridContainer from "../common/neo/layout/NeoGridContainer.base";
import NeoCard from "../common/neo/panel/NeoCard.base";
import LogoWhite from 'design/assets/img/mnv/logo-bw.svg';
import { useComponent } from 'hooks';

const ContrasenaOlvidada = (props) => {
  const componente = useComponent();
  const [peticionEnviada, setPeticionEnviada] = componente.useCustomState(false)
  let historial = useHistory();
  const toast = useRef(null);

  useEffect(() => {
    return () => { componente.desmontar(); };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pideRestablecer = (datos) => {
    firebase.auth().sendPasswordResetEmail(datos.correo)
      .then((result) => {
        setPeticionEnviada(true)
      }).catch((error) => {
        // Handle Errors here.
        muestraError("Verifica tu correo o intenta de nuevo")
      });
  }

  const muestraError = (mensaje) => {
    toast.current.show({
      severity: 'error', summary: "Ha sucedido un error",
      detail: mensaje, life: 3000
    });
  }

  const regresarAlInicio = () => {
    historial.push("/")
  }

  return (<>
    <Toast ref={toast} />

    <NeoSingleContainer >
      <NeoGridContainer col="12" >
        <NeoColumn col="12" extra="px-0">
          <Link to="/">
            <img className="logo-intro" src={LogoWhite} alt="NeoValidador" height="60" />
          </Link>
        </NeoColumn>

        <NeoColumn col="12" md="6" extra="p-md-offset-3">
          <NeoCard extra="text-align-center">
            {peticionEnviada
              ? <NeoNote text="Se ha enviado el enlace para restablecer la contraseña a tu correo." />
              : <FormularioContrasenaOlvidada titulo="Reestablecer" onSubmit={pideRestablecer} />
            }
            <NeoLink label="Regresar al inicio de sesión."
              onClick={regresarAlInicio} />
          </NeoCard>
        </NeoColumn>

      </NeoGridContainer>
    </NeoSingleContainer>
  </>);
}

export default ContrasenaOlvidada;