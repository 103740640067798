import { useEffect } from 'react';
import NeoGridContainer from "components/common/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "components/common/neo/title/NeoTitleMain.base";
import NeoInnerTitle from "components/common/neo/title/NeoInnerTitle.base";
import NeoCardMessage from "components/common/neo/panel/NeoCardMessage.base";
import NeoColumn from "components/common/neo/layout/NeoColumn.base";
import NeoCard from "components/common/neo/panel/NeoCard.base";
import NeoButtonSection from "components/common/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "components/common/neo/button/NeoButtonMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-dashboard.svg';
import FormularioValidacionUnitaria from "components/common/FormularioValidacionUnitaria";
import NotificacionesDashboard from 'components/common/NotificacionesDashboard';
import ModalValidacionArchivo from 'components/common/ModalValidacionArchivo';
import { useComponent, useTrigger, useUsuario } from "hooks";
import { useHistory } from "react-router-dom";
import RUTAS from 'components/router/Rutas.json';

const Tablero = () => {
    const componente = useComponent();
    const [iniciarValidacionArchivo, setIniciarValidacionArchivo] = componente.useCustomState(false);
    const usuario = useUsuario();
    const trigger = useTrigger();
    const historial = useHistory();

    useEffect(() => {
        if(trigger.evento.nombre === 'nueva validacion unitaria'
        || trigger.evento.nombre === 'nueva validacion archivo') {
            usuario.recargar();
        }
    }, [trigger.evento])

    return(
    <NeoGridContainer>
        <NeoColumn col="12">
            <NeoTitleMain icon={Icono} title="Bienvenido"/>
        </NeoColumn>

        <NeoColumn md="6">
            <NeoCard>
                <NeoInnerTitle extra="primero">Validación unitaria</NeoInnerTitle>
                <NeoColumn col="12">
                    <FormularioValidacionUnitaria />
                </NeoColumn>
            </NeoCard>

            <NeoCard>
                <NeoInnerTitle extra="primero">Validación por archivo</NeoInnerTitle>
                <NeoColumn col="12">
                    <NeoCardMessage message="
                        Válida tus archivos de Excel (xlsx) y csv
                    " />
                    <NeoButtonSection align="center" col="12"
                        extra="p-col-align-center">
                        <NeoButtonMain label="Subir archivo"
                            onClick={() => {
                                setIniciarValidacionArchivo(true)
                            }} />
                    </NeoButtonSection>
                </NeoColumn>
            </NeoCard>

            <NeoCard>
                <NeoInnerTitle extra="primero">Validación por API</NeoInnerTitle>
                <NeoColumn col="12">
                    <NeoCardMessage message="
                        Integra las validaciones con tu software a través de nuestro servicio de API rest
                    " />
                    <NeoButtonSection align="center" col="12"
                        extra="p-col-align-center">
                        <NeoButtonMain label="Ir a APIs"
                            onClick={() => {
                                historial.push(RUTAS.clientes.apikeys);
                            }} />
                    </NeoButtonSection>
                </NeoColumn>
            </NeoCard>
        </NeoColumn>

        <NeoColumn md="6">
            <NotificacionesDashboard />
        </NeoColumn>


        {
            iniciarValidacionArchivo
            && <ModalValidacionArchivo
                mostrarModalProceso={setIniciarValidacionArchivo}
            />
        }
    </NeoGridContainer>)
}

export default Tablero;