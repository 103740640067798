/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useComponent } from 'hooks';
import NeoCard from "./neo/panel/NeoCard.base";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import NeoGridContainer from "./neo/layout/NeoGridContainer.base";
import NeoDataSecond from "./neo/data/NeoDataSecond.base";
import { capitalizar } from "consts/generales";


const StatusActualCliente = (props) => {
    const componente = useComponent();
    const [validaciones, setValidaciones] = componente.useCustomState([]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.validaciones) {
            setValidaciones(props.validaciones)
        }
    }, [props.validaciones])

    return (<NeoCard>
        <NeoColumn >
            <NeoInnerTitle extra="primero">
                Validaciones disponibles del cliente
            </NeoInnerTitle>

            <NeoGridContainer>
                {
                    validaciones
                    && Array.isArray(validaciones)
                    && validaciones.map((elemento, indice) => {
                        return (<>
                            <NeoColumn >
                                <NeoDataSecond
                                    fact={elemento.cantidad || "0"}
                                    label={capitalizar(elemento.tipoValidacion)}
                                    key={indice} />
                            </NeoColumn>
                        </>);
                    })
                }
            </NeoGridContainer>
        </NeoColumn>
    </NeoCard>)
}

export default StatusActualCliente;