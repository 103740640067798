import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleSecond from "../../common/neo/title/NeoTitleSecond.base";
import FormularioCuentaUsuario from "components/common/FormularioCuentaUsuario";
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoButtonSecondary from "../../common/neo/button/NeoButtonSecondary.base";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoDialogConfirm from "../../common/neo/overlay/NeoDialogConfirm.base";
import { useComponent } from "hooks";
import useCliente from "hooks/useCliente";

const listadoPlanes = functions.httpsCallable("listadoPlanes");
const listadoDns = functions.httpsCallable("listadoDns");

const clienteSobregiroObtener = functions.httpsCallable("clienteSobregiro-obtener");
// const guardaSobregiro = functions.httpsCallable("clienteSobregiro-establecer");

const obtienePreciosCliente = functions.httpsCallable("precios-obtienePrecioPorClienteParaAdmin");
const guardaPreciosCliente = functions.httpsCallable("precios-establecePrecioPorClienteParaAdmin");

const guardaDatosCliente = functions.httpsCallable("setDatosCliente");


const ClientesEditar = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [listaDns, setListaDns] = componente.useCustomState([]);
    const [listaPlanes, setListaPlanes] = componente.useCustomState([]);
    const [sobregiro, setSobregiro] = componente.useCustomState([]);
    let historial = useHistory();
    const [muestraDialogoContrasena, setMuestraDialogoContrasena] = componente.useCustomState(false);
    const [preciosCliente, setPreciosCliente] = componente.useCustomState();
    const [permiteEditarPrecios,] = componente.useCustomState(false);

    const [seGuardoDatos, setSeGuardoDatos] = componente.useCustomState(false);
    const [seGuardoPrecios, setSeGuardoPrecios] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        obtieneDns();
        obtienePlanes();
        obtienePrecios();
        obtieneSobregiroValidaciones();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const obtienePlanes = () => {
        listadoPlanes().then((resp) => {
            setListaPlanes(resp.data);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: error.message
            });
        })
    };

    const obtienePrecios = () => {
        // if (cliente?.plan?.nombre === "postpago") {
        //     setPermiteEditarPrecios(true);
        // }

        obtienePreciosCliente({
            clienteId: cliente.id
        }).then((resp) => {
            setPreciosCliente(resp.data)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        })
    };

    const obtieneDns = () => {
        listadoDns().then((resp) => {
            setListaDns(resp.data);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });;
    };

    const obtieneSobregiroValidaciones = async () => {
        let respuesta = await clienteSobregiroObtener({
            clienteId: cliente.id
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error al obtener sobregiro de validaciones",
                detail: error.message
            });
        });

        if (respuesta) {
            setSobregiro(respuesta.data);
        }
    };

    useEffect(() => {
        if (seGuardoPrecios && seGuardoDatos) {
            despliegaMensaje({
                severity: "success",
                summary: "Configuraciones del usuario",
                detail: "Han sido guardadas correctamente",
            });
            // historial.goBack();
            setSeGuardoDatos(false);
            setSeGuardoPrecios(false);
        }
    }, [seGuardoPrecios, seGuardoDatos]); // eslint-disable-line react-hooks/exhaustive-deps


    const pideRestablecerContrasena = (datos) => {
        firebase.auth().sendPasswordResetEmail(
            cliente.correo
        ).then((result) => {
            despliegaMensaje({
                severity: "success",
                summary: "",
                detail: "Se ha enviado el correo para restablecer la contraseña"
            });
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: "Hubo un error al enviar el correo."
            });
        });
    }

    const manejaConsultarBitacora = () => {
        return historial.push("/administracion/clientes/bitacora");
    };

    const guardaDatos = (datos) => {

        guardaDatosCliente({
            ...datos,
            clienteId: Number(cliente.id)
        }).then(() => {
            setSeGuardoDatos(true);
            //Actualiza el usuario en el componente padre
            cliente.recargar();
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error", summary: "",
                detail: `${error.message} (${error.code})`
            });
        }).finally(() => {
            obtieneSobregiroValidaciones();
        });

        guardaPreciosCliente({
            preciosCliente,
            clienteId: Number(cliente.id)
        }).then(() => {
            setSeGuardoPrecios(true);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error", summary: "",
                detail: `${error.message} (${error.code})`
            });
        });

        // guardaSobregiro(datos.sobregiroValidaciones);
    }



    return (<>
        <NeoGridContainer>
            <NeoTitleSecond col="12" md="6"
                // icono="{Icono}"
                title="Cuenta del usuario" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <NeoButtonSecondary label="Cambiar contraseña"
                    onClick={() => setMuestraDialogoContrasena(true)} />
                <NeoButtonSecondary label="Consultar bitácora"
                    onClick={manejaConsultarBitacora} />
            </NeoButtonSection>
        </NeoGridContainer>


        <FormularioCuentaUsuario
            datos={{
                ...cliente,
                listaDns: listaDns,
                listaPlanes: listaPlanes
            }}
            vistaCliente={false}
            precios={preciosCliente}
            preciosEditables={permiteEditarPrecios}
            onSubmit={guardaDatos}
            asignacionDnEditable={true}
            onCambiarContrasena={pideRestablecerContrasena}
            sobregiroValidaciones={sobregiro}
        />

        <NeoDialogConfirm visible={muestraDialogoContrasena}
            onHide={() => setMuestraDialogoContrasena(false)}
            message="Se te enviará un correo con instrucciones para cambiar tu contraseña. ¿Deseas proceder?"
            header="Confirmación" icon="pi pi-exclamation-triangle"
            accept={pideRestablecerContrasena} />
    </>);
};


export default ClientesEditar;