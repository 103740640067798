import React, { useEffect } from "react";
import NeoTitleMain from '../../common/neo/title/NeoTitleMain.base';
import NeoButtonSection from '../../common/neo/layout/NeoButtonSection.base';
import NeoButtonMain from "../../common/neo/button/NeoButtonMain.base";
import FormularioValidacionUnitaria from "components/common/FormularioValidacionUnitaria";
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoColumn from "../../common/neo/layout/NeoColumn.base";
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';

import ModalValidacionArchivo from 'components/common/ModalValidacionArchivo';
import HistoricoValidaciones from "components/common/HistoricoValidaciones";
import { useComponent, useUsuario, useTrigger } from "hooks";
import { useLocation } from "react-router-dom";
import BotonDescargaValidaciones from "components/common/BotonDescargaValidaciones";

const Validaciones = () => {
    const componente = useComponent();
    const usuario = useUsuario();
    const trigger = useTrigger();
    const [iniciarValidacionArchivo, setIniciarValidacionArchivo] = componente.useCustomState(false);
    const [lazyParams, setLazyParams] = componente.useCustomState();
    const location = useLocation();

    useEffect(() => {
        return () => {
            componente.desmontar();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        location.state?.lazyParams
            ? setLazyParams(location.state.lazyParams)
            : setLazyParams({
                first: 0,
                rows: 8,
                page: 1
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(trigger.evento.nombre === 'nueva validacion unitaria'
        || trigger.evento.nombre === 'nueva validacion archivo'
        || trigger.evento.nombre === 'validacion archivo finalizada') {
            usuario.recargar();
        }
    }, [trigger.evento])

    return (<>
        {
            iniciarValidacionArchivo
            && <ModalValidacionArchivo
                mostrarModalProceso={setIniciarValidacionArchivo}
            />
        }

        <NeoGridContainer>
            <NeoTitleMain icon={Icono} col="12" md="4"
                title="Centro de validaciones" />

            <NeoColumn col="12" md="6">
                <FormularioValidacionUnitaria />
            </NeoColumn>
            <NeoColumn col="12" md="2">
                <NeoButtonSection align="center" col="12"
                    extra="p-col-align-center">
                    <NeoButtonMain label="Valida por archivo"
                        onClick={() => {
                            setIniciarValidacionArchivo(true)
                        }} />
                </NeoButtonSection>
            </NeoColumn>
        </NeoGridContainer>

        <HistoricoValidaciones
            lazyParams={lazyParams}
            setLazyParams={setLazyParams}
        />

        <NeoGridContainer>
            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <BotonDescargaValidaciones
                    lazyParams={lazyParams}
                />
            </NeoButtonSection>
        </NeoGridContainer>
    </>);

};


export default Validaciones;