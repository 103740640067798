import { useEffect } from 'react';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoGridContainer from "../../common/neo/layout/NeoGridContainer.base";
import NeoTitleMain from '../../common/neo/title/NeoTitleMain.base';
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import TablaOrdenes from "components/common/TablaOrdenes";
import NeoCardPrincipal from "../../common/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from '../../common/neo/table/NeoInnerBlankCardMainText.base';
import NeoMessageLoading from "components/common/neo/messages/NeoMessageLoading.base";
import { useComponent } from 'hooks';

const obtenerListaOrdenes = functions.httpsCallable('ordenes-listar');
const obtenerTipoOrdenes = functions.httpsCallable('ordenes-listarTipos');
const obtenerTipoPagoEstados = functions.httpsCallable('pago-listarEstados');
const obtenerTipoValidaciones = functions.httpsCallable('validacion-listarTipos');

const Ordenes = () => {
    const componente = useComponent();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState([]);
    const [tiposOrdenes, setTiposOrdenes] = componente.useCustomState([]);
    const [tiposPagoEstado, setTiposPagoEstado] = componente.useCustomState([]);
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [, setCargando] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCargando(true);
        obtieneDatos();
        obtieneTiposOrden();
        obtieneTiposEstado();
        obtieneTiposValidacion();
        setCargando(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const obtieneDatos = async () => {
        obtenerListaOrdenes()
            .then((respuesta) => {
                setDatos(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    const obtieneTiposOrden = () => {
        obtenerTipoOrdenes()
            .then((respuesta) => {
                setTiposOrdenes(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneTiposEstado = () => {
        obtenerTipoPagoEstados()
            .then((respuesta) => {
                setTiposPagoEstado(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneTiposValidacion = () => {
        obtenerTipoValidaciones()
            .then((respuesta) => {
                setTiposValidaciones(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} title="Órdenes" />
        </NeoGridContainer>

        {
            cargando
                ? <NeoMessageLoading col={12} />
                : datos.length >= 1
                    ? <TablaOrdenes
                        datos={datos}
                        tiposOrdenes={tiposOrdenes}
                        tiposPagoEstado={tiposPagoEstado}
                        tiposValidaciones={tiposValidaciones}
                    />
                    : <NeoCardPrincipal col={12}>
                        <NeoInnerBlankCardMainText>
                            Sin órdenes
                        </NeoInnerBlankCardMainText>
                    </NeoCardPrincipal>
        }
    </>);

};


export default Ordenes;