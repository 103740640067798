/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NeoInnerTitle from "./neo/title/NeoInnerTitle.base";
import { capitalizar, aCadenaFechaConFormatoLocal, aDivisaLocal } from "../../consts/generales";
import NeoTable from "./neo/table/NeoTable.base";
import NeoCard from "./neo/panel/NeoCard.base";
import { Column } from 'primereact/column';
import { useComponent } from 'hooks';

const DetalleOrden = (props) => {
    const componente = useComponent();
    const [orden, setOrden] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.orden !== undefined)
            Object.keys(props.orden).length !== 0 && obtieneDatosGenerales();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.orden]);


    const obtieneDatosGenerales = () => {
        const _orden = { ...props.orden };

        if (_orden.fecha_inicio !== null) {
            _orden.fecha_vigencia = aCadenaFechaConFormatoLocal(_orden.fecha_vigencia);
            _orden.fecha_inicio = aCadenaFechaConFormatoLocal(_orden.fecha_inicio);
        }

        _orden.fecha_creacion = aCadenaFechaConFormatoLocal(_orden.fecha_creacion);

        setOrden(_orden);
    }

    const tipoBodyTemplate = (rowData) => {
        let tipoProducto = '';

        if (rowData.producto_descripcion_nombre === 'Validaciones adquiridas') {
            tipoProducto = 'Validaciones de';
        } else if (rowData.producto_descripcion_nombre === 'Consumo excedente') {
            tipoProducto = 'Validaciones excedentes de';
        }

        return (<>
            <span className="p-column-title">Productos</span>
            {tipoProducto} {capitalizar(rowData.productos)}{capitalizar(rowData.tipo_validacion_nombre)}
        </>);
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">
                Validaciones incluidas
            </span>
            {aDivisaLocal(rowData.cantidad)}
        </>);
    }

    const precioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio por validación</span>
            ${aDivisaLocal(rowData.precio)}
        </>);
    }

    return (<>
        <NeoTable value={orden?.productos || []}
            rows={10} removableSort
            emptyMessage="Error al cargar la información"
            extra="no-filters p-mb-4">

            <Column field="tipo" header="Validaciones"
                body={tipoBodyTemplate} />
            <Column field="cantidad" header="Cantidad"
                body={cantidadBodyTemplate} />
            <Column field="precio" header="Precio unitario"
                body={precioBodyTemplate} />
        </NeoTable>
    </>);
};


export default DetalleOrden;