/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NeoColumn from "./neo/layout/NeoColumn.base";
import NeoGridContainer from "./neo/layout/NeoGridContainer.base";
import NeoButtonSection from "./neo/layout/NeoButtonSection.base";
import NeoTitleMain from "./neo/title/NeoTitleMain.base";
import CardResumenResultados from "components/common/CardResumenResultados";
import GraficaResumenAnual from "components/common/GraficaResumenAnual";
import { COLORES } from "consts/generales";
import { BD_TABLA_TIPO_RESULTADO_VALIDACION, capitalizar } from "consts/generales";
import Icono from 'design/assets/img/mnv/icons/icon-dashboard.svg';
import NeoDropDownFilter from "components/common/neo/form/NeoDropDownFilter.base";
import { useComponent } from "hooks";
import web from 'core/scripts/web';

const COLORES_METODO = {
    porNombre: COLORES.porNombre,
    porCodigo: COLORES.porCodigo
};
const COLOR_TIPO = {
    porNombre: COLORES.porNombre,
    porCodigo: COLORES.porCodigo
};


const EstadisticasGraficas = (props) => {
    const componente = useComponent();
    const desconocido = BD_TABLA_TIPO_RESULTADO_VALIDACION.desconocido;
    const [desconocios, setDesconocidos] = componente.useCustomState(0);
    const [validacionesAhorradas, setValidacionesAhorradas] = componente.useCustomState([]);
    const [valorFiltro, setValorFiltro] = componente.useCustomState();
    const [mensualPorMetodo, setMensualPorMetodo] = componente.useCustomState(props.mensualPorMetodo);
    const [mensualPorTipo, setMensualPorTipo] = componente.useCustomState(props.mensualPorTipo);
    const [mensualPorResultado, setMensualPorResultado] = componente.useCustomState(props.mensualPorResultado);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if(Array.isArray(props.mensualPorMetodo)) {
            setMensualPorMetodo(props.mensualPorMetodo.map((metodo) => {
                metodo.nombre = capitalizar(metodo.nombre);
                return metodo;
            }));
        }

        if(Array.isArray(props.mensualPorTipo)) {
            setMensualPorTipo(props.mensualPorTipo.map((tipo) => {
                tipo.nombre = capitalizar(tipo.nombre);
                return tipo;
            }));
        }

        if(Array.isArray(props.mensualPorResultado)) {
            setMensualPorResultado(props.mensualPorResultado.map((resultado) => {
                resultado.nombre = capitalizar(resultado.nombre);
                return resultado;
            }));
        }

    }, [props.mensualPorMetodo, props.mensualPorTipo, props.mensualPorResultado])

    useEffect(() => {
        if (!props.mensualPorResultado) {
            return;
        }

        let cantDesconocidos = props.mensualPorResultado.filter(x => x.nombre === capitalizar(desconocido.nombre));

        if (cantDesconocidos.length) {
            setDesconocidos(cantDesconocidos[0].cantidad);
        } else {
            setDesconocidos(0);
        }

        const validacionesAhorradas = web.utils.cloneDeep(props.mensualPorPrevalidacion);
        validacionesAhorradas?.push({
            cantidad: cantDesconocidos[0]?.cantidad,
            nombre: capitalizar(desconocido.nombre)
        });
        
        setValidacionesAhorradas(validacionesAhorradas);

    }, [props?.mensualPorResultado, props?.mensualPorPrevalidacion])

    useEffect(() => {
        if (!valorFiltro) {
            return;
        }
        props.obtieneResumenPorMes(valorFiltro.valor)
    }, [valorFiltro])


    return (<>
        <NeoGridContainer>

            <NeoTitleMain icon={Icono} title="Estadísticas" col="12" md="6" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <NeoDropDownFilter col="6"
                    value={valorFiltro}
                    options={props.mesesDisponibles}
                    onChange={setValorFiltro}
                    optionLabel="nombre"
                    initialSelection={1}
                    label="Seleccionar periodo a consultar"
                />

            </NeoButtonSection>






            <NeoColumn md="12">
                <CardResumenResultados
                    titulo="Validaciones realizadas en el periodo"

                    detallePrincipal={[
                        {
                            cols: 12,
                            datos: (
                                props.mensualTotal
                                - props.mensualAhorro
                                - desconocios
                            ),
                            titulo: `Validaciones realizadas ${valorFiltro?.nombre}.`
                        }
                    ]}
                    
                    graficas={[
                        {
                            cols: 4,
                            datos: mensualPorMetodo,
                            titulo: "Medio usado",
                            colores: COLORES_METODO
                        },
                        {
                            cols: 4,
                            datos: mensualPorTipo,
                            titulo: "Tipo de validaciones",
                            colores: COLOR_TIPO
                        },
                        {
                            cols: 4,
                            datos: mensualPorResultado,
                            titulo: "Resultados",
                            colores: COLOR_TIPO
                        }
                    ]}
                />
            </NeoColumn>


            <NeoColumn md="7">
                <GraficaResumenAnual
                    datosAnuales={props.datosAnuales}
                    promedio={props.promedioAnual}
                    total={props.totalAnual} />
            </NeoColumn>
            <NeoColumn md="5">

                <CardResumenResultados
                    // titulo="Ahorros"
                    titulo="Validaciones ahorradas"

                    graficaAhorro={[
                        {
                            datos: validacionesAhorradas,
                            colores: COLOR_TIPO
                        }
                    ]}

                    graficaAhorroAncho="12"

                    detalleAhorro={[
                        {
                            datos: props.mensualAhorro + desconocios,
                            titulo: `Validaciones ahorradas ${valorFiltro?.nombre}.`
                        }
                    ]}
                />
            </NeoColumn>
        </NeoGridContainer>
    </>);
}


export default EstadisticasGraficas;