import React from "react";
import BaseSpinner from "../../sisu/overlay/BaseSpinner.sisu";

const NeoSpinner = (props) => {

  return (<div className="progress-spinner">
    <BaseSpinner />
  </div>);
};

export default NeoSpinner;